import { isNullOrUndefined } from 'util';
import {ChartDataGroup} from './chart-data-group.model';
import {DataXy} from './data-xy.model';

export class ChartDataXY {
    x: Date[];
    y: any[];
    dataGroup: ChartDataGroup;

    constructor(chartObj: any = null, dataGroup: ChartDataGroup = ChartDataGroup.Default) {
        this.y = (chartObj && chartObj['y']) ? chartObj.y : [];
        this.x = (chartObj && chartObj['x']) ? chartObj.x.map(date => new Date(date)) : [];
        this.dataGroup = dataGroup;
    }

    appendXY(x: Date, y: any = null): ChartDataXY {
        if (!isNullOrUndefined(x)) {
            this.x.push(x);
            this.y.push(y);
        }

        return this;
    }

    getXyAsArray(): DataXy[] {
        if (!isNullOrUndefined(this.x)) {
            return this.x.map((x, i) => new DataXy(x, this.y[i], this.dataGroup[i]));
        }
    }

    filterByDate( startDate: Date, endDate: Date ): ChartDataXY{

      const theFilteredPos: number[] = [];

      for (let i = 0; i < this.x.length; i++) {
        if(this.x[i] >= startDate && this.x[i] <= endDate) {
          theFilteredPos.push(i);
        }
      }

      //console.log('startDate', startDate);
      //console.log('endDate', endDate);

      const obj = {
        x: this.x.filter((x, i) => {
          return theFilteredPos.indexOf(i) != -1;
        }),
        y: this.y.filter((y, i) => {
          return theFilteredPos.indexOf(i) != -1;
        })
      };

      return new ChartDataXY( obj );

    }

}
