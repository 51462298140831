import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs/Rx";
import {ChartData, ChartDataHolder, ChartDataSummary, MeasureSummary, User} from "../models";
import {HttpParams} from "@angular/common/http";
import {ApiService} from "./api.service";
import {DatePipe} from "@angular/common";
import {HelperService} from "./helper.service";
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ChartDataService {

    private chartDataSubject: BehaviorSubject<ChartDataHolder> = new BehaviorSubject<ChartDataHolder>( null );
    private chartData: Observable<ChartDataHolder> = this.chartDataSubject.asObservable();

    constructor (
        private _apiService: ApiService,
        private _datePipe: DatePipe,
        private _helperService: HelperService
    ) {}


    listenChartData(): Observable<ChartDataHolder> {
      return this.chartData;
    }

    getUserChartData(user: User, forceUpdate = false, nrMonthBackFromNow: number = 1 ): Promise<ChartDataHolder> {

      return new Promise((resolve) => {
        if ( this.chartDataSubject.getValue() && !forceUpdate ) {
          resolve( this.chartDataSubject.getValue() ) ;
        } else {
          this.refreshChartData( user, nrMonthBackFromNow ).then(x => resolve(x), e => resolve( ((this.chartDataSubject.getValue()) ? this.chartDataSubject.getValue() : new ChartDataHolder() ) ));
        }
      });

    }


    refreshChartData( user: User = null, nrMonthBackFromNow: number = 1 ): Promise<any> {

        return new Promise((resolve, reject) => {

            const nowDate = new Date();
            const httpParams = new HttpParams()
                .set('endDate', moment().format('YYYY-MM-DD'))
                .set('startDate', moment().subtract(nrMonthBackFromNow, 'months').format('YYYY-MM-DD')); // default 1 month prior

            this._apiService.get('/measure', httpParams).subscribe(data => {

                if(data) {

                    const chartDataHolderBody = {ChartData: data, user: user};
                    const chartDataHolder = new ChartDataHolder( chartDataHolderBody );
                    if( JSON.stringify(this.chartDataSubject.getValue()) !== JSON.stringify(chartDataHolder) ) {
                        this.chartDataSubject.next( chartDataHolder );
                        resolve( chartDataHolder );
                    } else {
                        reject( 'No new values' );
                    }
                } else {
                    reject( 'No values' );
                }



            }, error => {
                console.log('chartdata error', error);
                this.chartDataSubject.next( new ChartDataHolder() );
                reject( error );
            });

        });
    }

}
