import { Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import 'rxjs/add/operator/take';
import { User } from "../models";
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { ApiService, StorageService, UserService } from "../services";


@Injectable({
    providedIn: 'root',
})
export class AuthRegistrationGuard implements CanActivate {

    constructor(
        private _userService: UserService,
        private _apiService: ApiService,
        private _router: Router,
        private _storageService: StorageService

    ) {

    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        /*
        console.log('auth reg guard -> canActivate');
        console.log('route', route);
        console.log('state', state);
        */

        // return true; //get to the reg.page directly

        return this._storageService.getToken().then(token => {

          if (token) {

            return this._userService.getUser().then( user => {
              if ( user.isHaveEssentialUserCredential() && user.isMissingUserValues() ){ // &&
                // ( user.isMissingUserValues() || !user.isHaveDeviceId() || !user.isHaveRoll() ) ) {
                return true;
              } else {
                return this.notAuthed();
              }
            });

          } else {
            return this.notAuthed();
          }
        });

    }


  private notAuthed(): boolean {
    this._router.navigateByUrl('/');
    return false;
  }

}
