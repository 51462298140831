export class DateFilterHolder {

    name: string;
    monthOrCustom: string;
    isCurrent: boolean;
    startDate: Date;
    endDate: Date;

    constructor(filterObj: any = null) {
      this.name = (filterObj && filterObj['name']) ? filterObj.name : '';
      this.monthOrCustom = (filterObj && filterObj['monthOrCustom']) ? filterObj.monthOrCustom : '';
      this.isCurrent = (filterObj && filterObj['isCurrent']) ? filterObj.isCurrent : false;
      this.startDate = (filterObj && filterObj['startDate']) ? new Date(filterObj.startDate) : new Date();
      this.endDate = (filterObj && filterObj['endDate']) ? new Date(filterObj.endDate) : new Date();
    }

    init( isCurrent = true ) {
      this.name = '1 month back';
      this.monthOrCustom = '1MonthBack';
      this.isCurrent = isCurrent;

      const tempDate = this.startDate;
      tempDate.setMonth( tempDate.getMonth() -1 );
      this.startDate = tempDate;

      return this;
    }

    compareFixer(): string {
       const json = JSON.stringify({
        name: this.name,
        monthOrCustom: this.monthOrCustom,
        startDate: this.startDate.getFullYear()+'-'+(this.startDate.getMonth()+1)+'-'+this.startDate.getDate(),
        endDate: this.endDate.getFullYear()+'-'+(this.endDate.getMonth()+1)+'-'+this.endDate.getDate()
      });

       console.log('json', json);

      return json;
    }

    //
    //


}
