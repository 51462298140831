import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {NotificationsComponent} from "./notifications.component";
import {AuthGuard} from "../../shared/guards";

const notificationsRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
        NotificationsComponent,
    ],
    imports: [
        SharedModule,
        notificationsRouting,
    ],
})

export class NotificationsModule {}
