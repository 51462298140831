import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import {
  DashboardModule,
  IconsModule,
  MapsModule,
  NotificationsModule,
  TableListModule,
  TypographyModule,
  UserProfileModule,
  LoginModule,
  InfoModule,
  AuthCallbackModule,
  ConsentPatientModule,
  AddManualDataModule,
  DetailedChartModule,
  HandleUsersModule,
  PatientSummariesModule,
  ManualDataModule,
  DashboardSupervisorModule,
  SupportModule,
  RegistrationModule,
  DetailedChartDayModule,
  ScheduleModule,
  HandleScheduleModule,
  NotificationsSingleModule,
  DataExtractModule, ChatbotModule, ChatbotSingleModule
} from './pages';

import { AuthCallbackComponent } from './pages/auth-callback/auth-callback.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent
  }
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [
    DashboardModule,
    IconsModule,
    MapsModule,
    NotificationsModule,
    PatientSummariesModule,
    SupportModule,
    RegistrationModule,
    DashboardSupervisorModule,
    TableListModule,
    TypographyModule,
    UserProfileModule,
    ConsentPatientModule,
    LoginModule,
    AuthCallbackModule,
    AddManualDataModule,
    DetailedChartModule,
    DetailedChartDayModule,
    HandleUsersModule,
    ManualDataModule,
    ScheduleModule,
    HandleScheduleModule,
    NotificationsSingleModule,
    DataExtractModule,
    InfoModule,
    ChatbotModule,
    ChatbotSingleModule
  ],
})

export class AppRoutingModule { }
