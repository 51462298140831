import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent } from '@angular/common';
import 'rxjs/add/operator/filter';
import { NavbarComponent } from '../navbar/navbar.component';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import {HeartNotification, User} from '../../models';
import {ApiService, ChildrenService, HelperService, NotificationService, UserService} from '../../services';
import {HttpParams} from '@angular/common/http';
import $ from 'jquery';

@Component({
  selector: 'app-root-layout',
  templateUrl: './root-layout.component.html',
  styleUrls: ['./root-layout.component.scss']
})
export class RootLayoutComponent implements OnInit {
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  user: User = new User();
  currentUserNotifications: HeartNotification[] = [];
  currentDoctorsNotifications: HeartNotification[] = [];


  loading = false;

  constructor(
      public location: Location,
      private router: Router,
      private _userService: UserService,
      private _helperService: HelperService,
      private _apiService: ApiService,
      private _notificationService: NotificationService,
      private _childrenService: ChildrenService) {}

  ngOnInit() {
      this._helperService.isFullLoading.subscribe( data => this.loading = data);

      this._userService.listenUser().subscribe(theUser => {

        if(theUser) {
          this.user = theUser;
          this._notificationService.getCurrentUserNotifications().then(noti => this.currentUserNotifications = noti);
        }

        // console.log("this.user.root", this.user);
      });

  }


}
