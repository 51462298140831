import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import * as Chartist from 'chartist';
import 'chartist-plugin-tooltips';
//import 'chartist-plugin-zoom';

import {
  ApiService,
  ChartDataService,
  ChildrenService,
  HelperService,
  UserService
} from "../../shared/services";
import { ChartData, ChartDataHolder, ChartDataXY, ChildrenParentsUser, DetailedChartData, User } from '../../shared/models';
import { HttpParams } from "@angular/common/http";
import { DatePipe, Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment';
import { PlotlyModule } from 'angular-plotly.js';
import { rgb } from 'd3';
import {TranslatePipe} from 'heart-app-shared-pipes';

@Component({
  selector: 'app-detailed-chart-day',
  templateUrl: './detailed-chart-day.component.html',
  styleUrls: ['./detailed-chart-day.component.scss']
})
export class DetailedChartDayComponent implements OnInit, AfterViewInit {

  //@ViewChild('lineChart') lineChart: ElementRef;
  //@ViewChild('lineChart2') lineChart2: ElementRef;


  user: User = new User(null);
  userChartData: ChartDataHolder = new ChartDataHolder();
  targetChartData: ChartData = new ChartData();
  targetChartData2: ChartData = new ChartData();

  detailedChartData: DetailedChartData[] = [];

  currentTargetIndex: number = 0;
  currentChartUserId: string = '';
  currentChartDate: string = '';
  currentChartMeasure: string = '';

  isWaitingForChartData: boolean = true;
  doctorsChildren: User[] = [];

  isUserDoctor: boolean = false;
  isWaitingForConnectedChildrenMeasures: boolean = true;

  lineChartDisplayed = null;

  graphs: any[] = [];

  combineChart = false;
  combineChartOptions: any = [
    {
      id: true,
      selection: 'Combine charts',
      description: 'Charts are combined',
    },
    {
      id: false,
      selection: 'Split charts',
      description: 'Charts are split',
    }
  ];

  constructor(
    private _apiService: ApiService,
    private _userService: UserService,
    private _datePipe: DatePipe,
    private _activeRoute: ActivatedRoute,
    private _location: Location,
    private _chartDataService: ChartDataService,
    private _childrenChartDataService: ChildrenService,
    private _helperService: HelperService,
    private _router: Router,
    private _plotlyModule: PlotlyModule,
  ) {
  }

  ngOnInit() {


    this._userService.getUser().then(theUser => {
      this.user = theUser;
      this.currentChartUserId = theUser.userId;

      if (this.user.isDoctor()) {
        this.isUserDoctor = true;

        this._childrenChartDataService.getDoctorsChildren(this.user.userId).then(x => {
          this.doctorsChildren = x.children;
        });

        // this.getAuthedUserChildrenAndParentsData();
        this.handleHttpParams();
      } else {
        this.isWaitingForConnectedChildrenMeasures = false;
      }

    });

  }

  ngAfterViewInit(): void {

  }

  getCurrentChildName() {
    const child = this.doctorsChildren.find(x => x.userId === this.currentChartUserId);
    return (child) ? child.getFullName() : '';
  }


  previousDayClicked() {
    this.changeDayClicked(false);
  }

  nextDayClicked() {
    this.changeDayClicked(true);
  }

  private changeDayClicked( isNextDay = true ) {

    const date = new Date(this.currentChartDate);

    if(isNextDay) {
      date.setDate(date.getDate() + 1);
    } else {
      //is previous date
      date.setDate(date.getDate() - 1);
    }

    const changedDateString = this._helperService.getDateString(date);
    this.isWaitingForChartData = true;
    this.graphs = [];
    this._router.navigateByUrl(`/detailed-chart-day?userId=${this.currentChartUserId}&date=${changedDateString}`,  { replaceUrl: true });

  }

  handleHttpParams() {

    this._activeRoute.queryParams.subscribe(params => {
      if (!isNaN(parseInt(params['index']))) {
        this.currentTargetIndex = parseInt(params['index']);
      }

      if (params['userId']) {
        this.currentChartUserId = params['userId'];
      }

      if (params['date']) {
        this.currentChartDate = params['date'];
      }

      if (params['measure']) {
        this.currentChartMeasure = params['measure'];
      }

      // ['PulseBPM', 'Steps','PulseOx', 'MetabolicEquivalentOfTask', 'ActiveKiloCalories', 'MotionIntensity'];
      let validMeasures = ['PulseBPM', 'Steps'];
      if (params['validMeasures']) {
        validMeasures = params['validMeasures'].split(' ');
      }

      // http://localhost:4200/detailed-chart-day?userId=d64ea9f257c44e25b1eff72366e74b0c&date=2019-02-14&measure=PulseBPM
      if (this.currentChartUserId !== '' && this.currentChartDate !== '' && this.currentChartMeasure !== '') {
        validMeasures = [this.currentChartMeasure];
      }

      this._childrenChartDataService.getChildrenDetailedChartData(this.currentChartUserId, this.currentChartDate, validMeasures)
        .then(childrenDetailedArr => this.handleChildrenDetailedData(childrenDetailedArr));

    });

  }

  handleChildrenDetailedData(childrenDetailedArr) {

    console.log('childrenDetailedArr', childrenDetailedArr);

    if (childrenDetailedArr) {
      this.detailedChartData = childrenDetailedArr;
      const usersDetailedChartData = this.detailedChartData
        .filter(x => x.userId === this.currentChartUserId && x.dateString === this.currentChartDate);


      if (usersDetailedChartData.length > 0) {
        this.userChartData.mappedData = [];
        for (const userDetailedChartData of usersDetailedChartData) {
          this.userChartData.setByDetailedChartData(userDetailedChartData, this.currentChartUserId);
          //console.log('this.currentChatbotArr', this.currentChatbotArr);
        }

        this.generatePlotlys();
      }
    }

    this.isWaitingForChartData = false;

  }

  getCombinedChartOptionDescr(id: any) {
    return this.combineChartOptions.find(x => x.id === id).description;
  }

  changeCombinedChartOption(event: any) {
    this.combineChart = event.currentValue;
    this.generatePlotlys();
  }

  private generatePlotlys() {

    this.graphs = [];
    const translatePipe = new TranslatePipe();

    if (this.combineChart) {

      const chart = {
        data: [],
        layout: {
          autosize: true,
          title: ''
        },
        config: {
          // showLink: true,
          showSendToCloud: true,
          displaylogo: false
        }
      };

      for (const chartData of this.userChartData.mappedData) {
        // build Plotly GRAPH

        if (chart.layout.title.length > 0) {
          chart.layout.title += ', ';
        }

        chart.layout.title += translatePipe.transform(chartData.type);

        const xy = chartData.getChartDataOrderedByDate();

        chart.data.push({
          name: translatePipe.transform(chartData.type),
          x: xy.x,
          y: xy.y,
          type: 'scatter',
          mode: 'lines',
          connectgaps: true,
          line: {
            width: 4,
            shape: 'spline',
            smoothing: '0.05',
            // color: rgb(91, 175, 234),
            simplify: true,
          }
        });
      }

      this.graphs.push(chart);

    } else {

      const traces = [];

      const subPlotLayout = {
        legend: { traceorder: 'reversed' },
        autosize: true,
        title: '',
        xaxis: {
          showspikes: true,
          spikemode: 'across',
          spikethickness: 1,
          spikesnap: 'data'
        },
        spikedistance: 50,
      };

      this.userChartData.mappedData.forEach((chartData, i) => {

        if (subPlotLayout.title.length > 0) {
          subPlotLayout.title += ', ';
        }

        subPlotLayout.title += translatePipe.transform(chartData.type);

        const xy = chartData.getChartDataOrderedByDate();

        let trace = {
          name: translatePipe.transform(chartData.type),
          x: xy.x,
          y: xy.y,
          type: 'scatter',
          mode: 'lines',
          connectgaps: true,
          line: {
            width: 4,
            shape: 'spline',
            smoothing: '0.05',
            // color: rgb(91, 175, 234),
            simplify: true,
          },
          marker: {
            size: 10,
            maxdisplayed: 250,
          }
        };

        if (i > 0) {
          trace['yaxis'] = 'y' + (i + 1);
        }

        if (xy.x.length < 500) {
          trace.mode += '+markers';
        }

        traces.push(trace);

      });

      for (let i = 0; i < traces.length; i++) {

        const slice = (100 / traces.length);

        if (i === 0) { // first trace
          subPlotLayout['yaxis'] = {
            fixedrange: true,
            domain: [0, (slice / 100)]
          };

        } else {
          subPlotLayout['yaxis' + (i + 1)] = {
            fixedrange: true,
            domain: [((i * slice) / 100), (((i + 1) * slice) / 100)]
          };
        }
      }

      subPlotLayout['height'] = (175 * traces.length);

      this.graphs.push({
        data: traces,
        layout: subPlotLayout,
        config: {
          showSendToCloud: true,
          displaylogo: false
        }
      });
    }
  }

  backClicked() {
    this._location.back();
  }


}
