
import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {DataExtractComponent} from "./data-extract.component";
import {SharedModule} from "../../shared";
import { AuthGuard } from '../../shared/guards';

const dataExtractRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'data-extract',
        component: DataExtractComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
        DataExtractComponent,
    ],
    imports: [
        SharedModule,
        dataExtractRouting,
    ],
})

export class DataExtractModule {}
