export class HeartNotification {

  id: string;
  userId: string;
  timestamp: string;
  timestampAsDate: Date;
  sentBy: string;
  state: string;
  message: string;

  constructor(chartObj: any = null) {
    this.id = (chartObj && chartObj['id']) ? chartObj.id : '';
    this.userId = (chartObj && chartObj['userid']) ? chartObj.userid : '';
    this.timestamp = (chartObj && chartObj['timestamp']) ? chartObj.timestamp : '';
    this.timestampAsDate = (chartObj && chartObj['timestamp']) ? new Date(chartObj.timestamp) : new Date();
    this.sentBy = (chartObj && chartObj['sentby']) ? chartObj.sentby : '';
    this.state = (chartObj && chartObj['state']) ? chartObj.state : '';
    this.message = (chartObj && chartObj['id']) ? chartObj.message : '';
  }

}
