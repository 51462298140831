import {NgModule} from '@angular/core';
import {RootLayoutComponent} from './root-layout/root-layout.component';
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SharedModule} from '../shared.module';
import {ngxLoadingAnimationTypes, NgxLoadingModule} from 'ngx-loading';
import {SidebarComponent} from './sidebar/sidebar.component';

@NgModule({
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        RootLayoutComponent,
    ],
    exports: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        RootLayoutComponent,
    ],
    imports: [
        SharedModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.chasingDots,
            backdropBackgroundColour: 'rgba(0,0,0,0.8)',
            backdropBorderRadius: '0px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff',
        })
    ],
})

export class LayoutModule {}
