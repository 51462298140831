import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {RegistrationComponent} from "./registration.component";
import {AuthRegistrationGuard} from "../../shared/guards";


const updateDevicesRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'registration',
        component: RegistrationComponent,
        canActivate: [AuthRegistrationGuard]
    }
]);

@NgModule({
    declarations: [
        RegistrationComponent,
    ],
    imports: [
        SharedModule,
        updateDevicesRouting,
    ]
})

export class RegistrationModule {}
