import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {DetailedChartComponent} from "./detailed-chart.component";
import {AuthGuard} from "../../shared/guards";
import { PlotlyModule } from 'angular-plotly.js';

const detailedChartRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'detailed-chart',
        component: DetailedChartComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
        DetailedChartComponent,
    ],
    imports: [
        SharedModule,
        detailedChartRouting,
        PlotlyModule
    ],
})

export class DetailedChartModule {}
