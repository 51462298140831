import {MeasureSummaryValue} from "./measure-summary-value.model";

export class MeasureSummary {
    type: string;
    values: MeasureSummaryValue[];

    constructor(chartObj: any = null) {
        this.type = (chartObj && chartObj['type']) ? chartObj.type : '';
        this.values = (chartObj && chartObj['values']) ? chartObj.values.map(x => new MeasureSummaryValue(x)) : [];
    }

    isValid() : boolean {
        return this.type !== '' && this.values.length > 0;
    }

}
