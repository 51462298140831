import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {LoginComponent} from "./login.component";
import {SharedModule} from "../../shared";
import {NotShowWhenAuthedGuard} from "../../shared/guards";

const loginRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NotShowWhenAuthedGuard]
    }
]);

@NgModule({
    declarations: [
        LoginComponent,
    ],
    imports: [
        SharedModule,
        loginRouting,
    ],
})

export class LoginModule {}
