import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import * as Chartist from 'chartist';
import 'chartist-plugin-tooltips';


import {
  ApiService,
  ChartDataService, ChatbotService,
  ChildrenService,
  HelperService,
  UserService
} from '../../shared/services';
import { ChartData, ChartDataHolder, ChartDataXY, ChatbotSummary, ChildrenParentsUser, MeasureSummary, User } from '../../shared/models';
import { HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ModalService } from '../../shared/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { TranslatePipe } from 'heart-app-shared-pipes';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Pulse', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];


@Component({
  selector: 'app-dashboard-supervisor',
  templateUrl: './dashboard-supervisor.component.html',
  styleUrls: ['./dashboard-supervisor.component.scss']
})

export class DashboardSupervisorComponent implements OnInit, AfterViewInit {

  /*
  @ViewChildren('statusChildrenChartsPie1') statusChildrenChartsPie1: QueryList<ElementRef>;
  @ViewChildren('statusChildrenChartsPie2') statusChildrenChartsPie2: QueryList<ElementRef>;

  @ViewChildren('statusChildrenCharts') statusChildrenCharts: QueryList<ElementRef>;
  */


  user: User = new User(null);

  isWaitingForConnectedChildren = true;
  isDoneLoadingData = false;
  //isWaitingForConnectedChildrenMeasuresPie = false;
  //isWaitingForConnectedChildrenCharts = true;

  notificationForm: FormGroup;
  notificationResponseMessage: object = { message: '', isError: false };

  childrenChartData: ChartDataHolder[] = [];
  childrenChatbotSummary: ChatbotSummary[] = [];

  graphs: any[] = [];

  siteLanguage = '';
  isListView = true;
  isShowDetailedMaxMin = false;


  displayedColumns: string[] = this.initDisplayedColumns();
  patientStatusMatrix: any[] = [];

  constructor(
    private _apiService: ApiService,
    private _userService: UserService,
    private _datePipe: DatePipe,
    private _router: Router,
    private _chartDataService: ChartDataService,
    private _childrenChartDataService: ChildrenService,
    private _helperService: HelperService,
    private _modalService: ModalService,
    private _chatbotService: ChatbotService,
    private _formBuilder: FormBuilder) { }

  ngOnInit() {

    this._userService.getUser().then(theUser => {
      console.log('theUser', theUser);
      this.user = theUser;
      //this.isWaitingForConnectedChildren = true;

      if (this.user.isSupervisor()) {

        // this._childrenChartDataService.getDoctorsChildren(this.user.userId).then(
        this._childrenChartDataService.getDoctorsChildrenChartData(this.user.userId)
          .then(childrenChartData => {
            //this.childrenChartData = childrenChartData;

            // get children chatbot summaries
            this._chatbotService.getDoctorsChildrenChatBotData(this.user.userId).then(childrenChatbotSummaryData => {

              console.log('childrenChatbotSummary', childrenChatbotSummaryData);
              this.childrenChatbotSummary = childrenChatbotSummaryData;

              console.log('childrenChartData', this.childrenChartData);
              this.isDoneLoadingData = true;
              const matrixStatus = this.getMatrixDataStatus();
              //console.log('statusArr', matrixStatus);
              this.generateMatrixData(matrixStatus);
              this.generatePlotlys();
              //console.log('graphs', this.graphs);

            });

          });
      }
    });


    this._childrenChartDataService.listenChildrenChartData().subscribe(childrenChartData => {

      if(childrenChartData) {
        this.childrenChartData = childrenChartData;
        this.isWaitingForConnectedChildren = false;
        const haveData = this.childrenChartData.filter(x => x.hasData());

        if(haveData.length > 0) {
          const matrixStatus = this.getMatrixDataStatus();
          //console.log('statusArr', matrixStatus);
          this.generateMatrixData(matrixStatus);
          this.generatePlotlys();
        }
      }

    });

    this._helperService.language.subscribe(lang => this.siteLanguage = lang);

    this.notificationForm = this._formBuilder.group({
      userEmail: this._formBuilder.control({ value: '', disabled: true }, [Validators.required]),
      userId: this._formBuilder.control({ value: '', disabled: true }, [Validators.required]),
      message: ['', [Validators.required]]
    });

  }




  /*
  getChildrenChatbotData() {

    this._childrenChartDataService.getChildrenLastWeekData(this.childrenParentsUser.children).then(childrenChartData => {
      if (childrenChartData) {
        this.childrenChartData = childrenChartData;
        this.populateChildrenGraphsWithData(this.statusChildrenCharts.toArray());
        this.isWaitingForConnectedChildrenCharts = false;
      }
    });

  }
  */

  ngAfterViewInit() {

    /*
    this.statusChildrenChartsPie2.changes.subscribe(data => {
      const elemArr = data.toArray();
      this.isWaitingForConnectedChildrenMeasuresPie = false;
      this.populateChildrenChartsWithDataPie(elemArr);
    });
    */

    /*
    if (this.childrenParentsUser.children.length > 0) {
      this.isWaitingForConnectedChildrenMeasuresPie = false;
      this.populateChildrenChartsWithDataPie(this.statusChildrenChartsPie2.toArray());
    }
    */

    /*
    this.statusChildrenChartsPie1.changes.subscribe(data => {
      const elemArr = data.toArray();
      this.isWaitingForConnectedChildrenMeasuresPie = false;
      this.populateChildrenChartsWithDataPie(elemArr);
    });
    */

    /*

    if (this.childrenParentsUser.children.length > 0) {
      this.isWaitingForConnectedChildrenMeasuresPie = false;
      this.populateChildrenChartsWithDataPie(this.statusChildrenChartsPie1.toArray());
    }
    */

    /*
    this.statusChildrenCharts.changes.subscribe(data => {
      const elemArr = data.toArray();
      this.populateChildrenGraphsWithData(elemArr);
    });
    */


  }


  getDatesArrFromDisplayedColumns(): string[] {
    const tempDisplayedColumns = JSON.parse(JSON.stringify(this.displayedColumns));
    tempDisplayedColumns.shift();
    //tempDisplayedColumns.pop();
    return tempDisplayedColumns;
  }


  initDisplayedColumns(): string[] {
    const dateArr = this.getLastDaysString();
    dateArr.unshift('name');
    //dateArr.push('star');
    return dateArr;
  }


  mouseOverPresentMeasure(event: MouseEvent, userId, date = null) {

    // console.log("mouseOverPresentMeasure", event);

    const elem = event.target as Element;

    // console.log("parentelem title", elem.parentElement.title);

    const target = elem.parentElement.title;

    //console.log("targetClasses", target);

    this.overridePlotlyUserGraph(target, userId, date);

  }

  getLastDaysString(nrfOfDays: number = 30): string[] {

    const dateArr = [];
    const today = new Date();

    for (let i = 0; i < nrfOfDays; i++) {
      const targetDate = new Date();
      targetDate.setDate(today.getDate() - i);
      dateArr.push(this._helperService.getDateString(targetDate));
    }

    return dateArr;
  }


  generateMatrixData(statusArr: { userId: string, data: any[] }[]) {

    for (const chd of this.childrenChartData) {

      let matrixArr = [];
      for (const sa of statusArr) {
        if (sa.userId === chd.user.userId) {

          for(const item of sa.data) {
            for(const param in item) {

              const pulseObj = { name: param };
              const foundItem = matrixArr.find(x => x.name === param);

              if(foundItem && param !== 'date') {

                foundItem[item['date']] = item[param];

              } else if(param !== 'date') {

                pulseObj[item['date']] = item[param];
                matrixArr.push(pulseObj);

              }
            }
          }
        }
      }


      this.patientStatusMatrix[chd.user.userId] = matrixArr;

    }


    //console.log('this.patientStatusMatrix', this.patientStatusMatrix);


  }

  getMatrixDataStatus(): { userId: string, data: any[] }[] {

    const statusArr = [];

    for (const chd of this.childrenChartData) {

      const userStatusArr = {
        userId: chd.user.userId,
        data: []
      };


      let params = {};

      for(const cd of chd.chartData) {
        for(const param of cd.avaliableDetails){
          if( /*chd.isValidProperty( param ) &&*/ !params.hasOwnProperty(param)) {
            params[param] = false;
          }
        }
      }


      for (const date of this.getLastDaysString()) {

        const dateObj = {
          date: date,
          chatBot: false
        };

        userStatusArr.data.push({...dateObj, ...params});
      }


      for (const chdCd of chd.chartData) { // Goes through all the chart data
        for (const dateKey of userStatusArr.data) {
          if (chdCd.calendarDate === dateKey['date']) {
            // If chart data have same date as last 10 days
            // then check status for that day and add to status Arr

            for(const dateKeyParam in dateKey){
              for(const chdCdParam in chdCd){

                //checks by property name and its value
                if(((dateKeyParam+'MaxAverage').toLowerCase() === chdCdParam.toLowerCase() || (dateKeyParam+'Average').toLowerCase() === chdCdParam.toLowerCase() || (dateKeyParam+'Total').toLowerCase() === chdCdParam.toLowerCase()) && chdCd[chdCdParam] && +chdCd[chdCdParam] > 0/*|| (dateKeyParam+'hasDetails').toLowerCase() === chdCdParam.toLowerCase()*/){

                  dateKey[dateKeyParam] = true;
                  break;
                }

                /*
                //checks by name and if array is not empty
                if((dateKeyParam+'Measures').toLowerCase() === chdCdParam.toLowerCase() && Array.isArray(chdCd[chdCdParam]) && chdCd[chdCdParam].length > 0) {
                  dateKey[dateKeyParam] = true;
                  break;
                }
                 */
              }
            }

          }
        }
      }


      // status for chatbot data
      for (const chatbot of this.childrenChatbotSummary) {
        if (chatbot.user.userId === chd.user.userId) {

          for (const dateKey of userStatusArr.data) {
            if (this._helperService.getDateString(chatbot.firstTimeOfRegistrationAsDate) === dateKey['date']) {
              dateKey.chatBot = true;
            }
          }

        }
      }

      statusArr.push(userStatusArr);
    }

    //console.log('statusArr', statusArr);

    return statusArr;
  }


  getCardClass(index) {
    const cardClasses = ['card-header-info', 'card-header-danger', 'card-header-success', 'card-header-warning'].reverse();

    return cardClasses[index % cardClasses.length];
  }

  redirectToDetailed(index, userId = null) {

    if (userId) {
      // this._router.navigateByUrl('/detailed-chart?index='+index+'&userId='+userId);
      this._router.navigateByUrl('/patient-summaries?userId=' + userId);
    } else {
      this._router.navigateByUrl('/detailed-chart?index=' + index);
    }

  }

  redirectToDailyDetailed(userId, date, type = '') {

    //console.log('type', type);

    if (type === 'Chatbot') {
      this._router.navigateByUrl(`/chatbot?userId=${userId}&date=${date}`);
    } else {


      let validMeasures = '';
      const childChartData = this.childrenChartData.find(x => x.user.userId === userId);
      if(childChartData) {
        const validMeasureDetails = childChartData.chartData.find(x => x.calendarDate === date);
        if(validMeasureDetails) {
          validMeasures = validMeasureDetails.avaliableDetails.join('+');
        }
      }

      this._router.navigateByUrl(`/detailed-chart-day?userId=${userId}&date=${date}&measure=&validMeasures=${validMeasures}`);

    }

  }


  redirectToDailyDetailedGraph( userId, event ) {

    if( event && event['points']) {
      const date = (event['points'][0]['x']) ? event['points'][0]['x'].split(' ')[0] : '';

      if(date !== '') {
        this.redirectToDailyDetailed(userId, date);
      }

    }

  }



  /*
  getAuthedUserChildren(roleType: string = 'Patient'): User[] {
    return this._helperService.filterUsersByRole(this.childrenParentsUser.children, roleType);
  }
  */


  openNotificationForm(user: User) {
    user.message = '';
    this.notificationForm.patchValue(user);
    this.notificationResponseMessage['message'] = '';
    this._modalService.open('notify-modal-1');
  }

  closeModal(id: string) {
    this._modalService.close(id);
  }

  sendNotification() {

    const form = this.notificationForm;

    if (form.valid && this.user.isDoctor()) {

      this._helperService.showFullScreenLoding();

      const body = {
        'userId': form.controls.userId.value,
        'from': 'HeartApp',
        'subject': `Notification from ${this.user.userEmail}`,
        'message': form.controls.message.value,
        'createdby': this.user.userId,
        'state': 'sending'
      };

      this._apiService.post(`/notify/connected/${form.controls.userId.value}`, body).subscribe(res => {

        this._helperService.hideFullScreenLoading();
        //console.log('res', res);

        this.notificationResponseMessage = {
          message: res, // 'Successfully sent',
          isError: false
        };

      }, err => {

        this._helperService.hideFullScreenLoading();

        this.notificationResponseMessage = {
          message: 'Something went wrong',
          isError: true
        };

        console.log('err', err);


      });

    }

  }

  private clearPlotlyUserGraph(measureName, userId) {

    const translatePipe = new TranslatePipe();
    for (const graphUserId in this.graphs) {

      if (graphUserId === userId) {
        const graphData = this.graphs[userId];
        let xyData = new ChartDataXY();
        graphData.data[0].x = xyData.x;
        graphData.data[0].y = xyData.y;
        graphData.data[0].name = translatePipe.transform(measureName);
        graphData.layout.title.text = translatePipe.transform(measureName);
        break;
      }

    }
  }

  private overridePlotlyUserGraph(measureType, userId, date) {

    const isDayView = false;

    const translatePipe = new TranslatePipe();

    for (const graphUserId in this.graphs) {


      /*
      //checks by property name and its value
                if((dateKeyParam+'hasBlobDetails').toLowerCase() === chdCdParam.toLowerCase() || (dateKeyParam+'hasDetails').toLowerCase() === chdCdParam.toLowerCase()){
                  dateKey[dateKeyParam] = chdCd[chdCdParam];
                  break;
                }

                //checks by name and if array is not empty
                if((dateKeyParam+'Measures').toLowerCase() === chdCdParam.toLowerCase() && Array.isArray(chdCd[chdCdParam]) && chdCd[chdCdParam].length > 0) {
                  dateKey[dateKeyParam] = true;
                  break;
                }
       */


      if (graphUserId === userId) {
        const graphData = this.graphs[userId];
        const childChartData = this.childrenChartData.find(x => x.user.userId === userId);

        const measureData = childChartData.mappedData.find(t => t.type.toLowerCase() === (measureType+'Average').toLowerCase() || t.type.toLowerCase() === (measureType+'MeanAverage').toLowerCase() || t.type.toLowerCase() === (measureType+'Total').toLowerCase());

        //console.log('cmeasureType+\'Measures\').toLowerCase()', (measureType+'Measures').toLowerCase());
        //console.log('childChartData.mappedData', childChartData.mappedData);

        let xyData = new ChartDataXY();
        if(measureType === 'chatBot') {

          const childChatbotData = this.childrenChatbotSummary.filter(x => x.user.userId === userId);
          const xyChatBot: {x: Date[], y: any[]} = {x:[], y:[]};

          for(let childChatbot of childChatbotData) {
            xyChatBot.x.push(childChatbot.firstTimeOfRegistrationAsDate);
            xyChatBot.y.push(+childChatbot.latestResult);
          }

          xyData = new ChartDataXY(xyChatBot);

        } else if (measureData) {
          xyData = (isDayView && date) ? measureData.getChartDataByDay(date) : measureData.getChartDataOrderedByDate();
        }

        // console.log('xyData', xyData);

        graphData.data[0].x = xyData.x;
        graphData.data[0].y = xyData.y;
        graphData.data[0].name = translatePipe.transform(measureType);
        graphData.layout.title.text = translatePipe.transform(measureType);

        let missingFields = new ChartDataXY();
        if(measureData) {
          missingFields = measureData.getChartDataMissingDates();
        }

        if(missingFields.x.length > 0 && xyData.x.length > 0){
          graphData.data[1].x = missingFields.x;
          graphData.data[1].y = missingFields.y;
          graphData.data[1].name = 'Saknar Värde';
        } else {
          graphData.data[1].x = [];
          graphData.data[1].y = [];
          graphData.data[1].name = '';
        }

        break;
      }

    }

  }


  private generatePlotlys() {

    this.graphs = [];
    const translatePipe = new TranslatePipe();

    // build Plotly GRAPH


    for (const chilChartData of this.childrenChartData) {

      const measureData = chilChartData.mappedData.find(t => t.type === 'pulseBpmAverage');

      //console.log("measureData len: ", measureData);

      let xyData = new ChartDataXY();
      if (measureData) {
        xyData = measureData.getChartDataOrderedByDate();
      }

      this.graphs[chilChartData.user.userId] = {
        data: [
          {
            name: translatePipe.transform('Pulse'),
            x: xyData.x,
            y: xyData.y,
            type: 'scatter',
            mode: 'lines+markers',
            connectgaps: true,
            line: {
              color: '#749BB6',
              width: 4,
              shape: 'spline',
              smoothing: '0.5',
              // color: rgb(91, 175, 234),
              simplify: true,
            },
            marker: {
              size: 10
            }
          },
          {
            name: '',
            x: [],
            y: [],
            type: 'scatter',
            mode: 'markers',
            connectgaps: false,
            line: {
              color: '#ff9800',
              width: 4,
              shape: 'spline',
              smoothing: '0.5',
              // color: rgb(91, 175, 234),
              simplify: true,
            },
            marker: {
              size: 10
            }
          }
        ],
        layout: {
          height: 350,
          autosize: true,
          title: {
            text: translatePipe.transform('Pulse'),
            x: 0.01,
            font: {
              size: 20,
              color: 'rgba(0, 0, 0, 0.9)', // override title font color

            }
          },
          font: { // global font settings
            family: '\'Muli\', \'Helvetica\', Open Sans, verdana, arial, sans-serif',
            color: 'rgba(0, 0, 0, 0.6)',
          },
          /*xaxis: {
            tickformat: 'Vecka %-U',
          }*/
        },
        config: {
          // showLink: true,
          showSendToCloud: true,
          displaylogo: false,
          locale: 'sv'
        }
      };
    }
  }



  /*

  shuffleArray(array: any[]) {

    const tempArr = array;
    if (tempArr.length > 0) {
      for (let i = tempArr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [tempArr[i], tempArr[j]] = [tempArr[j], tempArr[i]];
      }
    }
    return tempArr;
  }

  */

  /*
  populateChildrenChartsWithDataPie(elementArr) {

    // tslint:disable-next-line: forin
    for (const elIndex in elementArr) {

      const element = elementArr[elIndex];

      const max1 = 80, min1 = 30;
      const randNr1 = Math.floor(Math.random() * (max1 - min1 + 1) + min1); // between 30 and 90

      const max2 = 100 - randNr1, min2 = 5;
      const randNr2 = Math.floor(Math.random() * (max2 - min2 + 1) + min2); // between 30 and 90

      const randNr3 = 100 - randNr1 - randNr2;

      const randArr = this.shuffleArray([randNr1, randNr2, randNr3]);


      /*
        let className = 'green';
        if( randNr > 85 ) {
            className = 'red';
        } else if (randNr > 65) {
            className = 'orange';
        }
      *

      const dataPreferences = {
        labels: [randArr[0] + '%', randArr[1] + '%', randArr[2] + '%'],
        series: [randArr[0], randArr[1], randArr[2]]
      };

      const optionsPreferences = {
        // donut: true,
        donutWidth: 40,
        startAngle: 0,
        total: 100,
        showLabel: false,
      };

      // console.log("dataDailySalesChart", dataDailySalesChart); {value: 70, className: 'foo'}, {value: 30, className: 'bar'}
      const pieChart = new Chartist.Pie(element.nativeElement, dataPreferences, optionsPreferences);

      /*new Chartist.Pie( element.nativeElement, {
        series: [{value: randNr, className: className}, {value: (100-randNr), className: 'blue'}]
    }, {
        donut: true,
        donutWidth: 30,
        startAngle: 270,
        donutSolid: true,
        total: 200,
        showLabel: false
    } );
    *

      // this.startAnimationForChildrenChart(pieChart);

    }

  }

  */

  /*
  isMissingChartData(index) {
    const currentChild: User = this.getAuthedUserChildren()[index];
    return !this.childrenChartData.find(x => x.user.userId === currentChild.userId);
  }
  */

  /*
  populateChildrenGraphsWithData(elementArr) {

    console.log('childrenChartData', this.childrenChartData);

    // tslint:disable-next-line: forin
    for (const elIndex in elementArr) {

      const currentChild: User = this.getAuthedUserChildren()[elIndex];

      if (!currentChild) { continue; }

      const element = elementArr[elIndex];

      const childChartData = this.childrenChartData.find(x => x.user.userId === currentChild.userId);

      if(!childChartData || childChartData.weightData.length === 0) {continue;}

      let mappedArr = this.makeXYMappedArr(childChartData.weightData[0].getChartData());

      if (mappedArr.length > 5) {
        mappedArr = mappedArr.slice(Math.max(mappedArr.length - 5, 1));
      }

      const mappedRegArr = this.generateRegression(mappedArr);

      const formattedChartData: any = {
        labels: (mappedArr.length <= 10) ? mappedArr.map(obj => `${obj.x.getDate()}/${obj.x.getMonth() + 1}`) : '',
        series: [
          {
            name: 'series-1',
            data: mappedArr.map(x => {
              return {
                meta: `${x.x.toDateString()}`,
                value: (x.y) ? x.y.toFixed(1) : 0
              };
            })
          },
          {
            name: 'series-2',
            data: mappedRegArr
          }
        ]
      };

      const chartOptions: any = {
        lineSmooth: Chartist.Interpolation.cardinal({
          // tension: 0
          // divisor: 2
        }),
        low: (Math.min.apply(null, childChartData.weightData[0].getChartData().y) - 1),
        high: (Math.max.apply(null, childChartData.weightData[0].getChartData().y) + 1),
        chartPadding: { top: 0, right: 0, bottom: 0, left: 0 },
        chartMargin: { top: 20, right: 0, bottom: 0, left: 20 },
        axisY: {
          offset: 50
        },
        plugins: [
          /*
            Chartist.plugins.tooltip({
                anchorToPoint: false,
                appendToBody: false,
                class: 'cgi-tooltip'
            })
            *
          Chartist.plugins.tooltip({ class: 'cgi-tooltip' })
        ],
        series: {
          'series-1': {
          },
          'series-2': {
            showPoint: false
          }
        }
      };

      // console.log("dataDailySalesChart", dataDailySalesChart);

      const lineChart = new Chartist.Line(element.nativeElement, formattedChartData, chartOptions);
      this.startAnimationForLineChart(lineChart);


    }



  }
   */

  /*
  startAnimationForLineChart(chart) {

    // console.log("chart", chart);

    let seq: any, delays: any, durations: any;
    seq = 0;
    delays = 80;
    durations = 500;

    chart.on('draw', function(data) {
      if (data.type === 'line' || data.type === 'area') {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === 'point') {
        seq++;
        data.element.animate({
          opacity: {
            begin: seq * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'ease'
          }
        });
      }
    });

    seq = 0;
  }

  */
  private generateRegression(data: { x: Date, y: number }[]): number[] {

    let counter = 0;
    const dataX: number[] = data.map(x => {
      ++counter;
      return counter;
    });

    const dataY: number[] = data.map(x => {
      return (x.y) ? x.y : +0;
    });

    const reg = this._helperService.regress(dataX, dataY);

    if (dataY.length > 1) {
      dataY.shift();
    }

    const regArr: number[] = (dataY.length > 1) ? dataY.map(x => null) : dataY; // dataY.push((isNaN(reg['intercept'])) ? reg['sy'] : reg['intercept']);
    regArr.push(dataY[dataY.length - 1]);
    /*
    for(const val of dataY){
      const lastVal = regArr[regArr.length-1];
      regArr.push(lastVal*(1+reg['slope']));
    }
    */

    for (let i = 0; i < 4; i++) {
      const lastVal = regArr[regArr.length - 1];
      const factor = (isNaN(reg.r) ? 0 : reg.r);
      regArr.push(lastVal + factor);
    }

    // console.log('reg', reg);
    // console.log('regArr', regArr);

    return regArr;
  }

  private makeXYMappedArr(data: ChartDataXY): { x: Date, y: number }[] {

    const Xarr = data.x;
    const Yarr = data.y;
    const tempArr = [];
    for (const key in Xarr) {
      tempArr.push({
        x: Xarr[key],
        y: Yarr[key]
      });
    }
    return tempArr.sort((a, b) => {
      if (moment(a.x) < moment(b.x)) {
        return -1;
      } else {
        return 1;
      }
    });
  }

}
