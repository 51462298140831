import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs/Rx";
import {ChartDataHolder, Chatbot, ChatbotSummary, DetailedChartData, HeartNotification, User} from '../models';
import $ from 'jquery';


@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private isFullLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isFullLoading: Observable<boolean> = this.isFullLoadingSubject.asObservable();

  /*
  public messageSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public message: Observable<string> = this.messageSubject.asObservable();
  */

  private languageSubject: BehaviorSubject<string> = new BehaviorSubject<string>('sv');
  public language: Observable<string> = this.languageSubject.asObservable();


  constructor() { }

  convertStringToDayPlusMonthName(dateString: string) {

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const d = this.dateFromTSString(dateString);

    //document.write(dateString"The current month is " + monthNames[d.getMonth()]);

    return d.getDate() + ' ' + monthNames[d.getMonth()];
  }

  getMonthName(monthNr: number) {

    const monthNames = ["January", "February", "March", "April", "May", "June",
      "Juli", "August", "September", "October", "November", "December"];

    return monthNames[monthNr];
  }

  showFullScreenLoding(): void {
    this.isFullLoadingSubject.next(true);
  }

  hideFullScreenLoading(): void {
    this.isFullLoadingSubject.next(false);
  }

  setNewSiteLanguage(lang: string) {
    this.languageSubject.next(lang);
  }

  isArraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length)
      return false;
    for (let i = arr1.length; i--;) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i]))
        return false;
    }
    return true;
  }


  isInArray(obj: object, array: object[]): number {

    if (array) {
      for (let i = 0; i < array.length; i++) {
        if (JSON.stringify(array[i]) === JSON.stringify(obj)) {
          return i;
        }
      }
      return null;
    } else {
      return null
    }


  }

  isInArrayByParam(obj: object, array: object[], param: string): number[] {

    const indexes = [];

    for (let i = 0; i < array.length; i++) {
      if (array[i][param] === obj[param]) {
        indexes.push(i);
      }
    }

    return indexes;

  }


  filterUsersByRole(userArr: User[], roleType: string = 'Patient'): User[] {

    return userArr.filter(x => {

      if (roleType === 'Patient') {
        return x.isPatient();
      } else if (roleType === 'Doctor') {
        return x.isDoctor();
      } else {
        return true;
      }

    });

  }


  paramsMapToObject(aMap): object {
    const obj = {};
    aMap.forEach((v, k) => { obj[k] = v });
    return obj;
  }

  private dateFromTSString(dateString) {
    //const a = $.map(dateString.split(/[^0-9]/), function(s) { return parseInt(s, 10) });
    //return new Date(a[0], a[1]-1 || 0, a[2] || 1, a[3] || 0, a[4] || 0, a[5] || 0, a[6] || 0);
    return new Date(dateString);
  }

  getPrettyDate(date: Date): string {
    return this.getDateString(date) + ' ' + this.getTimeString(date);
  }

  getTimeString(date: Date): string {

    let hours = date.getHours();
    const hoursS = (hours < 10) ? '0' + hours : hours;

    let minutes = date.getMinutes();
    const minutesS = (minutes < 10) ? '0' + minutes : minutes;

    return hoursS + ':' + minutesS;

  }

  getDateString(date: Date): string {

    let month = date.getMonth()+1;
    const monthS = (month < 10) ? '0' + month : month;

    let day = date.getDate();
    const dayS = (day < 10) ? '0' + day : day;

    return date.getFullYear() + '-' + monthS + '-' + dayS;
  }

  sortHeartNotificationByDate(a: HeartNotification, b: HeartNotification ) {
    if ( a.timestampAsDate.getTime() > b.timestampAsDate.getTime() ){
      return -1;
    }
    if ( a.timestampAsDate.getTime() < b.timestampAsDate.getTime() ){
      return 1;
    }
    return 0;
  }

  sortChatbotSummaryByDate(a: ChatbotSummary, b: ChatbotSummary ) {
    if ( a.firstTimeOfRegistrationAsDate.getTime() > b.firstTimeOfRegistrationAsDate.getTime() ){
      return -1;
    }
    if ( a.firstTimeOfRegistrationAsDate.getTime() < b.firstTimeOfRegistrationAsDate.getTime() ){
      return 1;
    }
    return 0;
  }

  sortChatbotByDate(a: Chatbot, b: Chatbot ) {
    if ( a.timeOfRegistrationAsDate.getTime() > b.timeOfRegistrationAsDate.getTime() ){
      return -1;
    }
    if ( a.timeOfRegistrationAsDate.getTime() < b.timeOfRegistrationAsDate.getTime() ){
      return 1;
    }
    return 0;
  }

  sortUsersByName(a: User, b: User ) {
    if ( a.getFullName().toLowerCase() < b.getFullName().toLowerCase() ){
      return -1;
    }
    if ( a.getFullName().toLowerCase() > b.getFullName().toLowerCase() ){
      return 1;
    }
    return 0;
  }


  sortChildrenChartByName(a: ChartDataHolder, b: ChartDataHolder ) {
    if ( a.user.getFullName().toLowerCase() < b.user.getFullName().toLowerCase() ){
      return -1;
    }
    if ( a.user.getFullName().toLowerCase() > b.user.getFullName().toLowerCase() ){
      return 1;
    }
    return 0;
  }


  tooltipFixer(event) {
    const elem = $(event.target);
    const tooltipElem = elem.closest('.ct-chart').find('.cgi-tooltip');

    if (elem.hasClass('ct-point')) {
      tooltipElem.show();

    } else {
      tooltipElem.hide();
    }

    this.hidePointsFunctionality();

  }


  hidePointsFunctionality() {

    const hideElem = $('.cgi-tooltip-transparent-point');

    //console.log('hideElem', hideElem);

    if( hideElem.length > 0 ){
      hideElem.closest('.ct-chart').find('.ct-point').css('stroke', 'transparent');
    }

  }


  regress(x: number[], y: number[]) {
    const n = y.length;
    let sx = 0;
    let sy = 0;
    let sxy = 0;
    let sxx = 0;
    let syy = 0;
    for (let i = 0; i < n; i++) {
      sx += x[i];
      sy += y[i];
      sxy += x[i] * y[i];
      sxx += x[i] * x[i];
      syy += y[i] * y[i];
    }
    const mx = sx / n;
    const my = sy / n;
    const yy = n * syy - sy * sy;
    const xx = n * sxx - sx * sx;
    const xy = n * sxy - sx * sy;
    const slope = xy / xx;
    const intercept = my - slope * mx;
    const r = xy / Math.sqrt(xx * yy);
    const r2 = Math.pow(r,2);
    let sst = 0;
    for (let i = 0; i < n; i++) {
      sst += Math.pow((y[i] - my), 2);
    }
    const sse = sst - r2 * sst;
    const see = Math.sqrt(sse / (n - 2));
    const ssr = sst - sse;
    return {slope, intercept, r, r2, sse, ssr, sst, sy, sx, see};
  }



}
