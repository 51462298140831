import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ApiService, UserService, HelperService, ChildrenService } from 'src/app/shared/services';
import { User, ChildrenParentsUser } from 'src/app/shared/models';
import { timeout } from 'rxjs/operators';

interface UserContainer {
  user: User;
  jobs: Array<any>;
  currentlyRunning: Array<any>;
}

@Component({
  selector: 'app-data-extract',
  templateUrl: './data-extract.component.html',
  styleUrls: ['./data-extract.component.css']
})
export class DataExtractComponent implements OnInit {


  authedUser: User = new User(null);
  allUsers: User[] = [];
  childrenParentsUser: ChildrenParentsUser = new ChildrenParentsUser();



  userContainerArray: Array<UserContainer> = [];

  isWaitingForRegisteredUsers: boolean = true;
  isWaitingForAvailableUsers: boolean = true;

  constructor(
    private _apiService: ApiService,
    private _userService: UserService,
    private _datePipe: DatePipe,
    private _activeRoute: ActivatedRoute,
    private _helperService: HelperService,
    private _childrenService: ChildrenService) { }

  ngOnInit() {
    this._userService.getUser().then(theUser => {
      this.authedUser = theUser;

      this._childrenService.getAllUsers().then(users => {

        this.allUsers = users;

        this.allUsers.forEach(user => {

          this.userContainerArray.push({
            user,
            jobs: [],
            currentlyRunning: [],
          });

          this.getPendingJobs(user);

        });

        console.log('this.allUsers', this.allUsers);
        this.isWaitingForAvailableUsers = false;
        this.isWaitingForRegisteredUsers = false;
      });

    });
  }

  getPendingJobs(user: User) {
    console.log('getPendingJobs USER', user);

    const foundUser = this.userContainerArray.find(u => u.user.userId === user.userId);

    console.log('foundUser', foundUser);

    if (foundUser) {
      this._apiService.get(`/administration/${user.userId}/data`).subscribe(result => {
        console.log('Get Jobs result', result);
        foundUser.jobs = result;

        //get all pending jobs
        const pendingJobs = foundUser.jobs.find(x => x.state === 'Pending');

        if (pendingJobs) {

          // update after a while again!!
          setTimeout(() => {
            this.getPendingJobs(user);
          }, 10000);

        }

      }, err => {

        console.log('Error getting jobs for user: ' + user.userId, err);
      });
    }
  }

  downloadFromResultingUri(uri: string) {
    setTimeout(() => {
      window.open(uri, '_blank');
    }, 1);
  }

  startDataExtractJob(userContainer: UserContainer) {

    // POST /api/administration/{userId}/data

    const createJobBody = {
      id: '',
      operation: 'CreateJob',
      uri: '',
      state: '',
      message: ''
    };

    this._apiService.post(`/administration/${userContainer.user.userId}/data`, createJobBody).subscribe(result => {

      console.log('Create Job result', result);

      const foundUser = this.userContainerArray.find(u => u.user.userId === userContainer.user.userId);
      const retrieve = foundUser.jobs;


      console.log("Running jobs:", retrieve);

      //update runnig stuff...
      this.getPendingJobs(userContainer.user);

    }, err => {

      console.log('Error getting jobs for user: ' + userContainer.user.userId, err);

    });
  }

}
