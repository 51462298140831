
export class HealthScheduleAction {
  id: string;
  typeOfAction: string;
  title: string;
  description: string;
  completeByTime: string;
  completionLog: string[];
  completeByTimeAsDate: Date;
  completionLogAsDate: Date[];
  type: string;


    constructor(chartObj: any = null, actionType: string = null) {
        this.id = (chartObj && chartObj['id']) ? chartObj.id : '';
        //this.typeOfAction = (chartObj && chartObj['typeOfAction']) ? chartObj.typeOfAction : '';
        this.typeOfAction = actionType;
        this.title = (chartObj && chartObj['title']) ? chartObj.title : '';
        this.description = (chartObj && chartObj['description']) ? chartObj.description : '';
        this.completeByTime = (chartObj && chartObj['completeByTime']) ? chartObj.completeByTime : '';
        this.completionLog = (chartObj && chartObj['completionLog']) ? chartObj.completionLog : '';
        this.completeByTimeAsDate = (chartObj && chartObj['completeByTime']) ? new Date(chartObj.completeByTime) : new Date();
        this.completionLogAsDate = (chartObj && chartObj['completionLog']) ? chartObj.completionLog.map(x => new Date(x)) : [];
        this.type = (chartObj && chartObj['type']) ? chartObj.type : '';
        //this.type = actionType;

    }


}
