
export interface ValueObj {
  v: number;
  max: number;
  avg: number;
  min: number;
  d: number;
}

export interface DetailObj {
  LocalTimeOffsetSeconds: number;
  Origin: string;
  TypeOfMeasurement: string;
  Values: ValueObj[];
}

export class ChartDataSummary {
  activeKiloCaloriesAverage: number;
  activeKiloCaloriesHasDetails: boolean;
  avaliableDetails: string[];
  boneMassAverage: number;
  boneMassMeasures: DetailObj[];
  calendarDate: string;
  calendarDateAsDate: Date;
  distanceHasBlobDetails: boolean;
  distanceTotal: number;
  fatFreeMassKgAverage: number;
  fatFreeMassKgMeasures: DetailObj[];
  fatMassWeightKgAverage: number;
  fatMassWeightKgMeasures: DetailObj[];
  fatRatioAverage: number;
  fatRatioMeasures: DetailObj[];
  hydrationAverage: number;
  hydrationMeasures: DetailObj[];
  metAverage: number;
  metHasDetails: boolean;
  motionIntensityHasBlobDetails: boolean;
  motionIntensityMaxAverage: number;
  motionIntensityMeanAverage: number;
  muscleMassAverage: number;
  muscleMassMeasures: DetailObj[];
  nyhaClassificationAverage: number;
  nyhaClassificationLatest: number;
  nyhaClassificatonMeasures: DetailObj[];
  pulseBpmAverage: number;
  pulseBpmHasBlobDetails: boolean;
  pulseBpmLatest: number;
  pulseOxAverage: number
  pulseOxHasBlobDetails: boolean;
  pulseOxLatest: number;
  pulseWaveVelocityAverage: number;
  pulseWaveVelocityMeasures: DetailObj[];
  stepsHasBlobDetails: boolean;
  stepsTotal: number;
  stressLevelAverage: number;
  stressLevelMax: number;
  weightKgAverage: number;
  weightKgLatest: number;
  weightKgMeasures: DetailObj[];

    constructor(chartObj: any = null) {
      this.calendarDateAsDate = (chartObj && chartObj['calendarDateAsDate']) ? new Date(chartObj.calendarDateAsDate) : new Date();
      this.calendarDate = (chartObj && chartObj['calendarDate']) ? chartObj.calendarDate : '';
      this.nyhaClassificationAverage = (chartObj && chartObj['nyhaClassificationAverage']) ? +chartObj.nyhaClassificationAverage : null;
      this.nyhaClassificationLatest = (chartObj && chartObj['nyhaClassificationLatest']) ? +chartObj.nyhaClassificationLatest : null;
      this.nyhaClassificatonMeasures = (chartObj && chartObj['nyhaClassificatonMeasures']) ? chartObj.nyhaClassificatonMeasures : null;
      this.weightKgAverage = (chartObj && chartObj['weightKgAverage']) ? +chartObj.weightKgAverage : null;
      this.weightKgLatest = (chartObj && chartObj['weightKgLatest']) ? +chartObj.weightKgLatest : null;
      this.weightKgMeasures = (chartObj && chartObj['weightKgMeasures']) ? chartObj.weightKgMeasures : null;
      this.pulseBpmAverage = (chartObj && chartObj['pulseBpmAverage']) ? +chartObj.pulseBpmAverage : null;
      this.pulseBpmLatest = (chartObj && chartObj['pulseBpmLatest']) ? +chartObj.pulseBpmLatest : null;
      this.pulseBpmHasBlobDetails = (chartObj && chartObj['pulseBpmHasBlobDetails']) ? chartObj.pulseBpmHasBlobDetails : false;
      this.pulseOxAverage = (chartObj && chartObj['pulseOxAverage']) ? +chartObj.pulseOxAverage : null;
      this.pulseOxLatest = (chartObj && chartObj['pulseOxLatest']) ? +chartObj.pulseOxLatest : null;
      this.pulseOxHasBlobDetails = (chartObj && chartObj['pulseOxHasBlobDetails']) ? chartObj.pulseOxHasBlobDetails : false;
      this.stepsTotal = (chartObj && chartObj['stepsTotal']) ? +chartObj.stepsTotal : null;
      this.stepsHasBlobDetails = (chartObj && chartObj['stepsHasBlobDetails']) ? chartObj.stepsHasBlobDetails : false;
      this.stressLevelAverage = (chartObj && chartObj['stressLevelAverage']) ? +chartObj.stressLevelAverage : null;
      this.stressLevelMax = (chartObj && chartObj['stressLevelMax']) ? +chartObj.stressLevelMax : null;
      this.avaliableDetails = (chartObj && chartObj['avaliableDetails']) ? chartObj.avaliableDetails : [];
      this.boneMassAverage = (chartObj && chartObj['boneMassAverage']) ? +chartObj.boneMassAverage : null;
      this.activeKiloCaloriesAverage = (chartObj && chartObj['activeKiloCaloriesAverage']) ? +chartObj.activeKiloCaloriesAverage : null;
      this.activeKiloCaloriesHasDetails = (chartObj && chartObj['activeKiloCaloriesHasDetails']) ? chartObj.activeKiloCaloriesHasDetails : false;
      this.boneMassMeasures = (chartObj && chartObj['boneMassMeasures']) ? chartObj.boneMassMeasures : [];
      this.distanceHasBlobDetails = (chartObj && chartObj['distanceHasBlobDetails']) ? chartObj.distanceHasBlobDetails : false;
      this.distanceTotal = (chartObj && chartObj['distanceTotal']) ? +chartObj.distanceTotal : null;
      this.fatFreeMassKgAverage = (chartObj && chartObj['fatFreeMassKgAverage']) ? +chartObj.fatFreeMassKgAverage : null;
      this.fatMassWeightKgAverage = (chartObj && chartObj['fatMassWeightKgAverage']) ? +chartObj.fatMassWeightKgAverage : null;
      this.fatFreeMassKgMeasures = (chartObj && chartObj['fatFreeMassKgMeasures']) ? chartObj.fatFreeMassKgMeasures : [];
      this.fatMassWeightKgMeasures = (chartObj && chartObj['fatMassWeightKgMeasures']) ? chartObj.fatMassWeightKgMeasures : [];
      this.fatRatioMeasures = (chartObj && chartObj['fatRatioMeasures']) ? chartObj.fatRatioMeasures : [];
      this.fatRatioAverage = (chartObj && chartObj['fatRatioAverage']) ? +chartObj.fatRatioAverage : null;
      this.hydrationAverage = (chartObj && chartObj['hydrationAverage']) ? +chartObj.hydrationAverage : null;
      this.hydrationMeasures = (chartObj && chartObj['hydrationMeasures']) ? chartObj.hydrationMeasures : [];
      this.metAverage = (chartObj && chartObj['metAverage']) ? +chartObj.metAverage : null;
      this.metHasDetails = (chartObj && chartObj['metHasDetails']) ? chartObj.metHasDetails : false;
      this.motionIntensityHasBlobDetails = (chartObj && chartObj['motionIntensityHasBlobDetails']) ? chartObj.motionIntensityHasBlobDetails : false;
      this.motionIntensityMaxAverage = (chartObj && chartObj['motionIntensityMaxAverage']) ? +chartObj.motionIntensityMaxAverage : null;
      this.motionIntensityMeanAverage = (chartObj && chartObj['motionIntensityMeanAverage']) ? +chartObj.motionIntensityMeanAverage : null;
      this.muscleMassMeasures = (chartObj && chartObj['muscleMassMeasures']) ? chartObj.muscleMassMeasures : [];
      this.muscleMassAverage = (chartObj && chartObj['muscleMassAverage']) ? +chartObj.muscleMassAverage : null;
      this.pulseWaveVelocityAverage = (chartObj && chartObj['pulseWaveVelocityAverage']) ? +chartObj.pulseWaveVelocityAverage : null;
      this.pulseWaveVelocityMeasures = (chartObj && chartObj['pulseWaveVelocityMeasures']) ? chartObj.pulseWaveVelocityMeasures : [];
    }
}
