import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {IconsComponent} from "./icons.component";
import {AuthGuard} from "../../shared/guards";

const iconsRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'icons',
        component: IconsComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
        IconsComponent,
    ],
    imports: [
        SharedModule,
        iconsRouting,
    ],
})

export class IconsModule {}
