import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import * as Chartist from 'chartist';
import 'chartist-plugin-tooltips';

import {
  ApiService,
  ChartDataService, ChatbotService,
  ChildrenService,
  HelperService,
  UserService
} from '../../shared/services';
import {
  ChartData,
  ChartDataHolder,
  ChartDataXY,
  Chatbot,
  ChatbotSummary,
  ChildrenParentsUser,
  DateFilterHolder,
  User
} from '../../shared/models';
import { HttpParams } from "@angular/common/http";
import { DatePipe, Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment';
import { TranslatePipe } from 'heart-app-shared-pipes';

@Component({
  selector: 'app-chatbot-single',
  templateUrl: './chatbot-single.component.html',
  styleUrls: ['./chatbot-single.component.scss']
})

export class ChatbotSingleComponent implements OnInit {

  //@ViewChild('lineChart') lineChart: ElementRef;

  user: User = new User(null);
  currentChatbotArr: Chatbot[] = [];
  currentChatbotChildId: string = '';
  currentChatbotChatId: string = '';
  isWaitingForChartData: boolean = true;

  dateFilterHolder: DateFilterHolder[] = [new DateFilterHolder().init()];

  isUserDoctor: boolean = false;
  isWaitingForConnectedChildrenMeasures: boolean = true;

  constructor(
    private _apiService: ApiService,
    private _userService: UserService,
    private _datePipe: DatePipe,
    private _activeRoute: ActivatedRoute,
    private _location: Location,
    private _chatbotService: ChatbotService,
    private _helperService: HelperService,
    private _router: Router) { }

  ngOnInit() {

    this._userService.getUser().then(theUser => {
      this.user = theUser;
      this.currentChatbotChildId = theUser.userId;

      this.handleHttpParams();
      if (this.user.isDoctor()) {
        this.isUserDoctor = true;
        this.getChildrenWithFetchedChartData();
        //this.getAuthedUserChildrenAndParentsData();
        this.isWaitingForChartData = false;

      } else {

        this._chatbotService.getUserChatbotData(this.user, this.currentChatbotChatId).then(chartDataHolder => {
          this.currentChatbotArr = chartDataHolder;
          this.isWaitingForChartData = false;
        }).catch(err => {
          this.isWaitingForChartData = false;
        });

        this.isWaitingForConnectedChildrenMeasures = false;
      }
    });

  }

  handleHttpParams() {

    this._activeRoute.queryParams.subscribe(params => {

      if (params['childId']) {
        this.currentChatbotChildId = params['childId'];
      }

      if (params['chatId']) {
        this.currentChatbotChatId = params['chatId'];
      }

    });

  }

  getChildrenWithFetchedChartData() {

    this._chatbotService
      .getDoctorsChildrenSingleChat(this.user.userId, this.currentChatbotChildId, this.currentChatbotChatId)
      .then(childrenChatbotData => {

        console.log('childrenChatbotData', childrenChatbotData);
        this.currentChatbotArr = childrenChatbotData;

        this.isWaitingForConnectedChildrenMeasures = false;

      });
  }


  backClicked() {
    this._location.back();
  }

  getPrettyDate(date: Date): string {
    return this._helperService.getPrettyDate(date);
  }

  parseDate(date: Date): string {
    return this._helperService.getDateString(date);
  }

  parseTime(date: Date): string {
    return this._helperService.getTimeString(date);
  }

  getChatbotUser(): User {
    const foundUser = this.currentChatbotArr.find(x => x.user.userId === this.currentChatbotChildId);
    return (foundUser) ? foundUser.user : new User();
  }

  changeCurrentUserId(userId: string) {
    this.currentChatbotChildId = userId;
    //this.populateGraphsWithData(this.currentTargetIndex, this.currentChatbotChildId);
  }


}
