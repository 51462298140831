import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {MapsComponent} from "./maps.component";
import {AuthGuard} from "../../shared/guards";

const mapsRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'maps',
        component: MapsComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
        MapsComponent,
    ],
    imports: [
        SharedModule,
        mapsRouting,
    ],
})

export class MapsModule {}
