import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { User } from "../../shared/models";
import { ApiService, HelperService, UserService } from "../../shared/services";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { ModalService } from "../../shared/modal";
import { calcPossibleSecurityContexts } from "@angular/compiler/src/template_parser/binding_parser";
import { Router } from "@angular/router";

@Component({
    selector: 'app-consent-patient',
    templateUrl: './consent-patient.component.html',
    styleUrls: ['./consent-patient.component.scss']
})
export class ConsentPatientComponent implements OnInit {

    deviceForm: FormGroup;
    user: User = new User(null);
    showLogOutBtn: boolean = false;
    modalMessage: string = '';

    constructor(
        private _formBuilder: FormBuilder,
        private _userService: UserService,
        private _helperService: HelperService,
        private _apiService: ApiService,
        private _modalService: ModalService,
        private _router: Router,
    ) { }


    ngOnInit() {

        this.deviceForm = this._formBuilder.group({
            garminUserId: ['Not yet synced', [Validators.required]],
            nokiaWithingsUserId: ['Not yet synced', [Validators.required]],
            devices: [[], [Validators.required]]
        });

        this._userService.getUser().then(user => {
            this.user = user;
            if (this.deviceForm) {
                this.deviceForm.patchValue(this.user);
            }
            console.log('form', this.deviceForm);
            this.showLogOutBtn = !this.user.isValidPatientRoll();
        });

    }

    //TODO: Fix synk to get devices from garmin and nokia based on ID.
    syncGarmin() {

        this._helperService.showFullScreenLoding();

        this._apiService.get('/me/auth/garmin').subscribe(
            data => {
                this._helperService.hideFullScreenLoading();
                this.modalMessage = 'Please complete authorization process for Garmin on the newly created tab';
                this._modalService.open('custom-modal-1', data).then(x => {
                    if (x === 'Auth process done for custom-modal-1') {

                        this._userService.getUser(true)
                            .then(user => {
                                this.modalMessage = 'Sending to Dashboard...';
                                this._router.navigateByUrl('/dashboard');
                            }).catch(error => {
                                this.modalMessage = error;
                            });

                    } else {
                        this.modalMessage = 'Something went wrong..';
                    }
                }).catch(err => {
                    console.log("err", err);
                    this._modalService.close('custom-modal-1');
                });

            }, error => {
                console.log("error", error);
                this._helperService.hideFullScreenLoading();
            }
        );
    }

    syncNokia() {

        this._helperService.showFullScreenLoding();

        this._apiService.get('/me/auth/withings').subscribe(
            data => {
                this._helperService.hideFullScreenLoading();
                this.modalMessage = 'Please complete authorization process for Nokia on the newly created tab';
                this._modalService.open('custom-modal-1', data).then(x => {
                    if (x === 'Auth process done for custom-modal-1') {

                        this._userService.getUser(true)
                            .then(user => {
                                this.modalMessage = 'Sending to Dashboard...';
                                this._router.navigateByUrl('/dashboard');
                            }).catch(error => {
                                this.modalMessage = error;
                            });

                    } else {
                        this.modalMessage = 'Something went wrong..';
                    }
                }).catch(err => {
                    console.log("err", err);
                    this._modalService.close('custom-modal-1');
                });
            }, error => {
                console.log("error", error);
                this._helperService.hideFullScreenLoading();
            }
        );


    }


    isSyncedWithProvider(searchProvider) {

        if (this.deviceForm && searchProvider === 'nokia') {
            return this.deviceForm.controls['nokiaWithingsUserId']['status'] === 'VALID';
        } else if (this.deviceForm && searchProvider === 'garmin') {
            return this.deviceForm.controls['garminUserId']['status'] === 'VALID';
        }
        return false;
    }


    updateGarminDevices() {

    }

    updateNokiaDevices() {
        this._helperService.showFullScreenLoding();

        let httpParams = new HttpParams();
        httpParams = httpParams.set('action', 'getdevice');
        httpParams = httpParams.set('access_token', this.user.nokiaWithingsAccessToken);

        this._apiService.getCustom('https://wbsapi.withings.net/v2/user', httpParams).subscribe(data => {

            this._helperService.hideFullScreenLoading();

            if (data['status'] === 0) {

                this.modalMessage = 'Updating your registered devices...';
                this._modalService.open('custom-modal-1');
                this.updateDevicesInDB(data['body']['devices']);

            } else {
                this.modalMessage = 'Could not authorize, please try sync with Nokia, and then try again';
                this._modalService.open('custom-modal-1')
            }


        }, error => {
            console.log("NokiaError", error);
            this._helperService.hideFullScreenLoading();
        });
    }


    private updateDevicesInDB(devices: object[]) {


        //this._apiService.put(/)


        console.log("devices", devices);

    }



    private checkGarminToken(): Promise<any> {

        return new Promise((resolve, reject) => {
            const userToken = this.user.garminAccessToken;

            if (userToken !== '') {

                let httpHeaders = new HttpHeaders();
                httpHeaders = httpHeaders.set("customAuthorization", userToken);

                this._apiService.getCustom('https://healthapi.garmin.com/wellness-api/rest/user/id', new HttpParams(), httpHeaders).subscribe(
                    data => {
                        console.log("data", data);

                        if (data['userId'] && data['userId'] === this.user.garminUserId) {
                            resolve(data);
                        } else {
                            reject("Not valid user id")
                        }

                    }, error => {
                        console.log("error", error);
                        reject("Not valid token")
                    }
                );
            } else reject("Not valid token");

        });

    }


    private checkNokiaToken(): Promise<any> {

        return new Promise((resolve, reject) => {
            const userToken = this.user.nokiaWithingsAccessToken;

            if (userToken !== '') {

                this._apiService.getCustom('https://wbsapi.withings.net/v2/user?action=getdevice&access_token=' + userToken).subscribe(
                    data => {
                        console.log("data", data);

                        if (data['status'] === 0) {
                            resolve(data);
                        } else {
                            reject("Not valid access token")
                        }

                    }, error => {
                        console.log("error", error);
                        reject("Not valid token")
                    }
                );
            } else reject("Not valid token");

        });

    }


    closeModal(id: string) {
        this._modalService.close(id);
    }

    logOut() {
        this._userService.logOut();
    }

}
