import {User} from "./user.model";
import {ChartDataHolder} from './chart-data-holder.model';

export class ChildrenParentsUser {
    children: User[];
    parents: User[];
    user: User;

    constructor(childrenParentsUserObj: any = null) {
        this.children = (childrenParentsUserObj && childrenParentsUserObj['children']) ? childrenParentsUserObj.children.map(x => new User(x)).sort(this.sortUserByName) : [];
        this.parents = (childrenParentsUserObj && childrenParentsUserObj['parents']) ? childrenParentsUserObj.parents.map(x => new User(x)).sort(this.sortUserByName) : [];
        this.user = (childrenParentsUserObj && childrenParentsUserObj['user']) ? new User(childrenParentsUserObj.user) : new User();
    }

    hasData() : boolean {
        return this.children.length > 0 || this.parents.length > 0 || this.user.isAuthed();
    }

    private sortUserByName(a: User, b: User ) {
      if ( a.getFullName().toLowerCase() < b.getFullName().toLowerCase() ){
        return -1;
      }
      if ( a.getFullName().toLowerCase() > b.getFullName().toLowerCase() ){
        return 1;
      }
      return 0;
    }

}
