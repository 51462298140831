import { Injectable, OnInit } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad, Route,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import 'rxjs/add/operator/take';
import { User } from "../models";
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { ApiService, StorageService, UserService } from "../services";



@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {

    constructor(
        private _userService: UserService,
        private _apiService: ApiService,
        private _router: Router,
        private _storageService: StorageService
    ) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        return this._storageService.getToken().then(token => {
            if (token) {
                return this._userService.getUser().then(user => {

                    if ( user.isMissingUserValues() || !user.isHaveRoll() ) {
                        this._router.navigateByUrl('/registration');
                        return false;
                    } else if ( user.isUnassigned() ) {
                      this._router.navigateByUrl('/info');
                      return false;
                    } else if ( !user.isHaveGarminOrNokiaId() && !user.isSupervisor() ) {
                      this._router.navigateByUrl('/consent-patient');
                      return false;
                    } else if (user.isHaveRoll()) {
                        return true;
                    } else {
                        return this.notAuthed();
                    }

                });

            } else {
                return this.notAuthed();
            }

        });
    }


    private notAuthed(): boolean {
        this._router.navigateByUrl('/');
        return false;
    }

    /*
    constructor(
        private _userService: UserService,
        config: MsalConfig,
        authService: MsalService,
        router: Router,
        activatedRoute: ActivatedRoute,
        location: Location,
        platformLocation: PlatformLocation,
        broadcastService: BroadcastService
  ) {
  
        super(config, authService, router, activatedRoute, location, platformLocation, broadcastService);
  
        this._userService.authedUser.subscribe(data => {
            this.user = new User(data);
        });
  
    }
  
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const msalGuard = super.canActivate(route, state);
  
        console.log("msalGuard", msalGuard);
        console.log("this.user", this.user);
  
        console.log("this.userisNotAuthed", this.user.isNotAuthed());
  
        return msalGuard;
  
  
        if( this.user.isNotAuthed() ) {
            return false;
        } else {
            return msalGuard;
        }
  
  
    }
    */


}
