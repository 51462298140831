import {HealthDailySchedule} from './health-daily-schedule.model';
import {User} from './user.model';
import {HealthScheduleAction} from './health-schedule-action.model';
import {Validators} from '@angular/forms';

export class HealthSchedule {
  id: string;
  monday: HealthDailySchedule;
  tuesday: HealthDailySchedule;
  wednesday: HealthDailySchedule;
  thursday: HealthDailySchedule;
  friday: HealthDailySchedule;
  saturday: HealthDailySchedule;
  sunday: HealthDailySchedule;
  firstDayOfWeek: string;
  firstDayOfWeekAsDate: Date;
  user: User;

    constructor(chartObj: any = null) {
        this.id = (chartObj && chartObj['id']) ? chartObj.id : '';
        this.monday = (chartObj && chartObj['monday']) ? new HealthDailySchedule(chartObj.monday) : new HealthDailySchedule();
        this.tuesday = (chartObj && chartObj['tuesday']) ? new HealthDailySchedule(chartObj.tuesday) : new HealthDailySchedule();
        this.wednesday = (chartObj && chartObj['wednesday']) ? new HealthDailySchedule(chartObj.wednesday) : new HealthDailySchedule();
        this.thursday = (chartObj && chartObj['thursday']) ? new HealthDailySchedule(chartObj.thursday) : new HealthDailySchedule();
        this.friday = (chartObj && chartObj['friday']) ? new HealthDailySchedule(chartObj.friday) : new HealthDailySchedule();
        this.saturday = (chartObj && chartObj['saturday']) ? new HealthDailySchedule(chartObj.saturday) : new HealthDailySchedule();
        this.sunday = (chartObj && chartObj['sunday']) ? new HealthDailySchedule(chartObj.sunday) : new HealthDailySchedule();
        this.firstDayOfWeek = (chartObj && chartObj['firstDayOfWeek']) ? chartObj.firstDayOfWeek : 'monday';
        this.firstDayOfWeekAsDate = (chartObj && chartObj['firstDayOfWeek']) ? new Date(chartObj.firstDayOfWeek) : new Date();
        this.user = (chartObj && chartObj['user']) ? chartObj.user : null;
    }


  isHaveDailyActions(): boolean {
      return this.monday.isHaveActions() ||
        this.tuesday.isHaveActions() ||
        this.wednesday.isHaveActions() ||
        this.thursday.isHaveActions() ||
        this.friday.isHaveActions() ||
        this.saturday.isHaveActions() ||
        this.sunday.isHaveActions();
  }


  getAmountOfMeasurements(): number {
    return this.monday.measurements.length +
      this.tuesday.measurements.length +
      this.wednesday.measurements.length +
      this.thursday.measurements.length +
      this.friday.measurements.length +
      this.saturday.measurements.length +
      this.sunday.measurements.length;
  }

  getAmountOfMedicines(): number {
    return this.monday.medicines.length +
      this.tuesday.medicines.length +
      this.wednesday.medicines.length +
      this.thursday.medicines.length +
      this.friday.medicines.length +
      this.saturday.medicines.length +
      this.sunday.medicines.length;
  }

  getAmountOfOthers(): number {
    return this.monday.other.length +
      this.tuesday.other.length +
      this.wednesday.other.length +
      this.thursday.other.length +
      this.friday.other.length +
      this.saturday.other.length +
      this.sunday.other.length;
  }


  patchValue( values: {day: string, medicines: any, measurements: any, other: any}[] ) {

    for (const param in values){
      const value = values[param];

      for(const paramThis in this){
        const thisValue = this[paramThis];
        if(thisValue instanceof HealthDailySchedule && paramThis === value.day) {

          thisValue.medicines = this.mergeActonArr(thisValue.medicines, value.medicines);
          thisValue.measurements = this.mergeActonArr(thisValue.measurements, value.measurements);
          thisValue.other = this.mergeActonArr(thisValue.other, value.other);

        }
      }
    }

    return this;
  }

    private mergeActonArr( originalArr: HealthScheduleAction[], updatedArr: {id: string, type: string, title: string, description: string, completeByTime: string}[] ): HealthScheduleAction[] {

      if(updatedArr.length > 0) {

        const originalToRemove = [];

        const prettyUpdatedArr = originalArr.map((x, i) => {

          const foundDuplicate = updatedArr.find(y => y.id === x.id);
          if(foundDuplicate) {
            x.type = foundDuplicate.type;
            x.title = foundDuplicate.title;
            x.description = foundDuplicate.description;
            x.completeByTime = foundDuplicate.completeByTime;
          } else {
            originalToRemove.push(i)
          }

          return x;
        });

        originalToRemove.forEach(x => {
          prettyUpdatedArr.splice(x, 1);
        });

        const newItems = updatedArr.filter(x => x.id === 'new').map(x => new HealthScheduleAction(x));

        return prettyUpdatedArr.concat(newItems);

      }

      return [];

  }


  makeApiFriendly(): object {

      const healthSchedule: HealthSchedule = this;
      const healthObj: HealthSchedule = JSON.parse(JSON.stringify(this));
      delete healthObj['firstDayOfWeekAsDate'];
      delete healthObj['user'];
      healthObj['firstDayOfWeek'] = (healthObj['firstDayOfWeek'] === 'string') ? 'monday' : healthObj['firstDayOfWeek'];

      for(const paramThis in healthSchedule) {
        let thisValue = healthSchedule[paramThis];
        if (thisValue instanceof HealthDailySchedule) {
          healthObj[paramThis] = this.makeDailyScheduleApiFriendly(thisValue);
        }
      }

      return healthObj;
  }

  private makeDailyScheduleApiFriendly( healthDailySchedule: object ): object {

    const healthObj = JSON.parse(JSON.stringify(healthDailySchedule));

    delete healthObj['id'];

    healthObj.medicines.forEach((item, index) => {

      healthObj.medicines[index] = this.makeScheduleActionApiFriendly(item)
    });
    healthObj.measurements.forEach((item, index) => {
      // @ts-ignore
      healthObj.measurements[index] = this.makeScheduleActionApiFriendly(item)
    });
    healthObj.other.forEach((item, index) => {
      // @ts-ignore
      healthObj.other[index] = this.makeScheduleActionApiFriendly(item);
    });

    return healthObj;
  }


  private makeScheduleActionApiFriendly( scheduleAction: object ): object {

    const healthObj = JSON.parse(JSON.stringify(scheduleAction));
    delete healthObj['completeByTimeAsDate'];
    delete healthObj['completionLogAsDate'];
    delete healthObj['completionLog'];
    //delete healthObj['typeOfAction'];
    return healthObj;

  }


}
