import { Component, OnInit } from '@angular/core';
import {ChildrenService, HelperService, NotificationService, UserService} from '../../shared/services';
import {ChildrenParentsUser, HeartNotification, User} from '../../shared/models';
import {Router} from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  currentUserNotifications: HeartNotification[] = [];
  currentDoctorsNotifications: HeartNotification[] = [];
  groupedDoctorsChildrenNotifications: {child: User, notifications: HeartNotification[]}[] = [];
  doctorsChildren: User[] = [];
  user: User = new User();
  allUsers: User[] = [];

  isLoading = true;

  constructor(
    private _userService: UserService,
    private _notificationService: NotificationService,
    private _childrenService: ChildrenService,
    private _router: Router
  ) { }


  ngOnInit() {

    this._userService.getUser().then(theUser => {
      this.user = theUser;

      console.log('this.user', this.user);

      this._childrenService.getAllUsers().then(allUsers => {

        console.log('this.allUsers', allUsers);
        this.allUsers = allUsers;

        this._notificationService.listenNotifications().subscribe(notification => {
          if(notification) {
            this.currentUserNotifications = notification;
            this.isLoading = false;
            console.log('this.currentUserNotifications', this.currentUserNotifications);
          }
        });

        if(this.user.isDoctor()){
          this._childrenService.getDoctorsChildren(this.user.userId).then(x => {
            this.doctorsChildren = x.children;
            console.log('this.doctorsChildren', this.doctorsChildren);

            this._notificationService.getSupervisorNotifications( x.children ).then(y => {
              this.currentDoctorsNotifications = y;
              this.groupedDoctorsChildrenNotifications = this.setGroupedNotifications( y, x.children );
              this.isLoading = false;
              console.log('this.currentDoctorsNotifications', this.currentDoctorsNotifications);
            });
          });
        }

      });

    });


  }


  setGroupedNotifications( allChildrenNotificationArr: HeartNotification[], children: User[]): {child: User, notifications: HeartNotification[]}[] {

    const notificationArr: {child: User, notifications: HeartNotification[]}[] = [];

    for(const child of children) {
      notificationArr.push({child: child, notifications: []});
    }

    for(const notification of notificationArr) {
      const inArray = allChildrenNotificationArr.filter(x => x.sentBy === this.user.userId && x.userId === notification.child.userId);
      if( inArray ){
        notification.notifications = inArray;
      }
    }

    return notificationArr;
  }



  getTableHeaders(): string[] {
    return ['Nr', 'Message', 'State', 'Date', 'Sent by'];
  }

  getTableDataRows( notifications: HeartNotification[] = [] ): any[]{

    let counter = 0;

    return notifications.map(x => {

      const sentByUser = this.allUsers.find( y => y.userId === x.sentBy);
      let sentByName = x.sentBy;
      if( sentByUser ){
        sentByName = sentByUser.getFullName();
      }
      ++counter;

      return [counter, x.message, x.state, x.timestampAsDate.toLocaleString(), sentByName];
    });
  }

  navigateToNotification( theId = null, isUserId = false){

    if(theId && isUserId){
      this._router.navigateByUrl('/notifications-single?userId=' +theId);
    } else if(theId){
      this._router.navigateByUrl('/notifications-single?id=' +theId);
    }

  }

  showNotification(from, align){
    const type = ['','info','success','warning','danger'];

    const color = Math.floor((Math.random() * 4) + 1);

    $.notify({
      icon: "ti-gift",
      message: "Welcome to <b>Paper Dashboard</b> - a beautiful freebie for every web developer."
    },{
      type: type[color],
      timer: 4000,
      placement: {
        from: from,
        align: align
      }
    });

  }
}
