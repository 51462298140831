import {User} from './user.model';

export class Chatbot {
  chatId: string;
  timeOfRegistration: string;
  timeOfRegistrationAsDate: Date;
  question: string;
  answer: string;
  result: string;
  user: User;

  constructor(chatbotObj: any = null, user: User = null) {
    this.chatId = (chatbotObj && chatbotObj['chatId']) ? chatbotObj.chatId : '';
    this.timeOfRegistration = (chatbotObj && chatbotObj['timeOfRegistration']) ? chatbotObj.timeOfRegistration : '';
    this.timeOfRegistrationAsDate = (chatbotObj && chatbotObj['timeOfRegistration']) ? new Date(chatbotObj.timeOfRegistration) : new Date();
    this.question = (chatbotObj && chatbotObj['question']) ? chatbotObj.question : '';
    this.answer = (chatbotObj && chatbotObj['answer']) ? chatbotObj.answer : '';
    this.result = (chatbotObj && chatbotObj['result']) ? chatbotObj.result : '';
    this.user = user;

  }

}
