import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {ScheduleComponent} from "./schedule.component";
import {AuthGuard, AuthSupervisorGuard} from '../../shared/guards';

const scheduleRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'schedule',
        component: ScheduleComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
      ScheduleComponent,
    ],
    imports: [
      SharedModule,
      scheduleRouting,
    ],
})

export class ScheduleModule {}
