import {DetailedChartDataValue} from './detailed-chart-data-value.model';
import {User} from './user.model';

export class DetailedChartData {
  typeOfMeasurement: string;
  origin: string;
  localTimeOffsetSeconds: number;
  values: DetailedChartDataValue[];
  dateString: string;
  originDate: Date;
  userId: string;

  constructor(chartObj: any = null) {
    this.typeOfMeasurement = (chartObj && chartObj['TypeOfMeasurement']) ? chartObj.TypeOfMeasurement : '';
    this.origin = (chartObj && chartObj['Origin']) ? chartObj.Origin : '';
    this.localTimeOffsetSeconds = (chartObj && chartObj['LocalTimeOffsetSeconds']) ? +chartObj.LocalTimeOffsetSeconds : +0;
    this.dateString = (chartObj && chartObj['Date']) ? chartObj.Date : '';
    this.originDate = (chartObj && chartObj['Date']) ? new Date(chartObj.Date) : new Date();
    this.values = (chartObj && chartObj['Values']) ? chartObj.Values.map(x => new DetailedChartDataValue(x, this.originDate)) : [];
    this.userId = (chartObj && chartObj['userId']) ? chartObj.userId : '';
  }
}
