import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import * as Chartist from 'chartist';
import 'chartist-plugin-tooltips';

import {
  ApiService,
  ChartDataService,
  ChildrenService,
  HelperService,
  UserService
} from "../../shared/services";
import { ChartData, ChartDataHolder, ChartDataXY, ChildrenParentsUser, DateFilterHolder, User } from '../../shared/models';
import { HttpParams } from "@angular/common/http";
import { DatePipe, Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment';
import { TranslatePipe } from 'heart-app-shared-pipes';

@Component({
  selector: 'app-detailed-chart',
  templateUrl: './detailed-chart.component.html',
  styleUrls: ['./detailed-chart.component.scss']
})
export class DetailedChartComponent implements OnInit {

  //@ViewChild('lineChart') lineChart: ElementRef;

  user: User = new User(null);
  userChartData: ChartDataHolder = null;
  targetChartData: ChartData = new ChartData();
  currentTargetIndex: number = 0;
  currentChartUserId: string = '';
  isWaitingForChartData: boolean = true;

  dateFilterHolder: DateFilterHolder[] = [new DateFilterHolder().init()];

  isUserDoctor: boolean = false;
  isWaitingForConnectedChildrenMeasures: boolean = true;
  childrenChartData: ChartDataHolder[] = [];

  allChartData: ChartDataHolder[] = [];
  isChildHaveNoData: boolean = false;
  isHaveGottenMoreChartData: boolean = false;
  isShowAllGraphs: boolean = true;

  graphs: Array<any> = [];

  constructor(
    private _apiService: ApiService,
    private _userService: UserService,
    private _datePipe: DatePipe,
    private _activeRoute: ActivatedRoute,
    private _location: Location,
    private _chartDataService: ChartDataService,
    private _childrenChartDataService: ChildrenService,
    private _helperService: HelperService,
    private _router: Router) { }

  ngOnInit() {

    this._userService.getUser().then(theUser => {
      this.user = theUser;
      // this.currentChartUserId = theUser.userId;

      if (this.user.isDoctor()) {
        this.isUserDoctor = true;
        this.handleHttpParams();
        this.getChildrenWithFetchedChartData();
        //this.getAuthedUserChildrenAndParentsData();
        this.isWaitingForChartData = false;

      } else {
        this.isWaitingForConnectedChildrenMeasures = false;
      }


      this._chartDataService.getUserChartData(theUser).then(chartDataHolder => {
        this.userChartData = chartDataHolder;
        this.isWaitingForChartData = false;
      }).catch(err => {
        this.isWaitingForChartData = false;
      });


      this._chartDataService.listenChartData().subscribe(chartDataHolder => {

        if (chartDataHolder) {
          this.userChartData = chartDataHolder;

          if ( !this.user.isDoctor() ) {
            this.currentChartUserId = this.user.userId; // is a patient
            this.setAllChartData();
            this.populateGraphsWithData(this.currentTargetIndex, this.currentChartUserId);
          }

          if (chartDataHolder.mappedData.length > 0) {
            this.isWaitingForChartData = false;
          }
        }

      });

    });

  }

  handleHttpParams() {

    this._activeRoute.queryParams.subscribe(params => {

      if (!isNaN(parseInt(params['index']))) {
        this.currentTargetIndex = parseInt(params['index']);
      }

      if (params['userId']) {
        this.currentChartUserId = params['userId'];
      }

    });

  }

  getChildrenWithFetchedChartData( isShowAll = false ) {

    this._childrenChartDataService.getDoctorsChildrenChartData(this.user.userId).then(childrenChartData => {

      console.log('childrenChartData', childrenChartData);

      this.childrenChartData = childrenChartData.filter(x => x.hasData());

      // if(this.childrenChartData.length > 0 && this.currentChartUserId === '') {
      //   this.currentChartUserId = this.childrenChartData[0].user.userId;
      // }

      this.setAllChartData();
      this.populateGraphsWithData(this.currentTargetIndex, this.currentChartUserId, ((isShowAll) ? 'all' : '1MonthBack'));

      this.isWaitingForConnectedChildrenMeasures = false;

      });
  }

  getMoreChartData( isHaveGottenMoreChartData: boolean = false) {

    if( !isHaveGottenMoreChartData ) {
      this.isWaitingForConnectedChildrenMeasures = true;
      this._helperService.showFullScreenLoding();

      const currentChildChartHolder = this.allChartData.find(x => x.user.userId === this.currentChartUserId);

      if(currentChildChartHolder) {
        this._childrenChartDataService.getChildrenChartData(currentChildChartHolder.user, true, 12).then(chartData => {
          this.isHaveGottenMoreChartData = true;
          this.getChildrenWithFetchedChartData( true );
          this._helperService.hideFullScreenLoading();
        });
      }

    } else {
      this.populateGraphsWithData(this.currentTargetIndex, this.currentChartUserId, 'all');
      this._helperService.hideFullScreenLoading();
    }


  }

  backClicked() {
    this._location.back();
  }

  goToDetailed(event) {

    const clickedPoint = event.points[0];
    const x = clickedPoint.x;
    // const y = clickedPoint.y;

    const date = moment(x).format('YYYY-MM-DD');

    let validMeasures = '';
    const childChartData = this.childrenChartData.find(x => x.user.userId === this.currentChartUserId);
    if(childChartData) {
      const validMeasureDetails = childChartData.chartData.find(x => x.calendarDate === date);
      if(validMeasureDetails) {
        validMeasures = validMeasureDetails.avaliableDetails.join('+');
      }
    }

    this._router.navigateByUrl(`/detailed-chart-day?userId=${this.currentChartUserId}&date=${date}&measure=&validMeasures=${validMeasures}`);

    /*
    if( activeBars.length > 0 ) {
      //const data = this.datasets[chartIndex];
      const clickIndex = activeBars[0]._index;
      const measureUniqueId = data.measureUniqueId[clickIndex];

      this._router.navigate(["health-chart-single"], { queryParams: { uniqueId: measureUniqueId, type:data.name, index:chartIndex  }});

    }
    */
  }

  handlePlotlyClickEvent(event: any) {

    if (event && event.points && event.points.length > 0) {
      this.goToDetailed(event);
    }
  }

  private generatePlotlys(chartDataArr: ChartData[]) {

    this.graphs = new Array<any>();
    const translatePipe = new TranslatePipe();

    // build Plotly GRAPH

    for (const chartData of chartDataArr) {

    this.graphs.push({
      data: [
        {
          name: translatePipe.transform(chartData.type),
          x: chartData.getChartDataOrderedByDate().x,
          y: chartData.getChartDataOrderedByDate().y,
          type: 'scatter',
          mode: 'lines+markers',
          connectgaps: true,
          line: {
            color: '#749BB6',
            width: 4,
            shape: 'spline',
            smoothing: '0.1',
            // color: rgb(91, 175, 234),
            simplify: true,
          },
          marker: {
            size: 10
          }
        }
      ],
      layout: {
        // height: 350,
        autosize: true,
        title: {
          text: translatePipe.transform(chartData.type),
          x: 0.01,
          font: {
            size: 20,
            color: 'rgba(0, 0, 0, 0.9)', // override title font color

          }
        },
        font: { // global font settings
          family: '\'Muli\', \'Helvetica\', Open Sans, verdana, arial, sans-serif',
          color: 'rgba(0, 0, 0, 0.6)',
        },
        xaxis: {
          // tickformat: 'Vecka %-U',
        }
      },
      config: {
        // showLink: true,
        showSendToCloud: true,
        displaylogo: false,
        // locale: 'sv'
      }
    });
    }
  }

  populateGraphsWithData(index, userId, monthOrCustomFilter = '1MonthBack') {

    if( this.isShowAllGraphs ) {
      this.populateAllGraphsWithData( userId, monthOrCustomFilter )
    } else {
      this.populateSingleGraphsWithData(index, userId, monthOrCustomFilter);
    }

  }

  private populateSingleGraphsWithData(index, userId, monthOrCustomFilter = '1MonthBack') {

    this.isShowAllGraphs = false;

    if (this.allChartData.length > 0) {
      this.currentTargetIndex = index;
      this.targetChartData = this.getUserSingleChartData(userId, index);
    }

    if (this.targetChartData.isValid()) {

      const chartData = this.targetChartData; // this.setDateIntervalAndReturnFilterChartDataBySelectedDate();

      this.dateFilterHolder = [new DateFilterHolder().init(false)];
      this.setDateFilterInterval(chartData, monthOrCustomFilter);

      const filteredChartData = ( monthOrCustomFilter === 'all') ? chartData : this.filterChartDataBySelectedDate( chartData );

      this.generatePlotlys( [filteredChartData] );

    }
  }

  private populateAllGraphsWithData(userId, monthOrCustomFilter = '1MonthBack') {

    this.isShowAllGraphs = true;

    const userChartDataArr = this.getUserChartDataArr(userId);

    this.dateFilterHolder = [new DateFilterHolder().init(false)];
    for(let chartData of userChartDataArr) {
      this.setDateFilterInterval(chartData, monthOrCustomFilter);
    }

    let filteredChartData = [];

    if( monthOrCustomFilter === 'all' ) {
      filteredChartData = userChartDataArr;
    } else {
      for(let chartData of userChartDataArr) {

        const chartDataByDate = this.filterChartDataBySelectedDate( chartData );

        if( chartDataByDate.isHaveData() ){
          filteredChartData.push( chartDataByDate );
        }
      }
    }

    this.generatePlotlys( filteredChartData );

  }


  getCurrentUser(userId: string): User {

    const findUser = this.allChartData.find(chartData => {
      return chartData.user.userId === userId;
    });

    // console.log('findUser', findUser);

    if (findUser) {
      return findUser.user;
    }

    return new User();

  }

  changeCurrentUserId(userId: string) {
    this.isHaveGottenMoreChartData = false;
    this.currentChartUserId = userId;
    this.populateGraphsWithData(this.currentTargetIndex, this.currentChartUserId);
  }


  getCurrentDateFilter(): string {
    const current = this.dateFilterHolder.find(x => x.isCurrent === true);
    return (current) ? current.name : 'All';
  }


  populateGraphsWithDataNewDate(monthOrCustom: string) {
    this.populateGraphsWithData(this.currentTargetIndex, this.currentChartUserId, monthOrCustom)
  }

  private filterChartDataBySelectedDate(chartData: ChartData): ChartData {

    const current = this.dateFilterHolder.find(x => x.isCurrent === true);

    if (current) {
      return chartData.filterDataByDate( current.startDate, current.endDate)
    }

    return chartData;
  }



  private setDateFilterInterval(mappedArrAll: ChartData, monthOrCustomFilter: string) {

    //this.dateFilterHolder = [new DateFilterHolder().init(false)];

    const maxDate = new Date(Math.max.apply(null, mappedArrAll.getChartData().x));
    const minDate = new Date(Math.min.apply(null, mappedArrAll.getChartData().x));

    let firstDayOfMonth = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
    let lastDayOfMonth = new Date(minDate.getFullYear(), minDate.getMonth() + 1, 0);
    maxDate.setMonth(maxDate.getMonth() + 1);

    while (lastDayOfMonth <= maxDate) {

      // JSON because disconnect from date object
      const obj = JSON.parse(JSON.stringify({
        name: this._helperService.getMonthName(firstDayOfMonth.getMonth()) + ' - ' + firstDayOfMonth.getFullYear(),
        monthOrCustom: 'month-' + firstDayOfMonth.getMonth()+'-'+firstDayOfMonth.getFullYear(),
        startDate: firstDayOfMonth.getTime(),
        endDate: lastDayOfMonth.getTime(),
      }));

      let newFilterItem = new DateFilterHolder(obj);

      const isInArray = this.dateFilterHolder.find(x => x.monthOrCustom === newFilterItem.monthOrCustom);
      if (!isInArray) {
        this.dateFilterHolder.push(newFilterItem);
      }

      firstDayOfMonth.setMonth(firstDayOfMonth.getMonth() + 1);
      lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);

    }

    //console.log('this.dateFilterHolder', this.dateFilterHolder);


    for (let dateFilter of this.dateFilterHolder) {
      dateFilter.isCurrent = dateFilter.monthOrCustom === monthOrCustomFilter;
    }


  }

  private getUserSingleChartData(userId: string, index: number): ChartData {

    const userChartDataArr = this.getUserChartDataArr(userId);

    if (userChartDataArr && userChartDataArr.length > 0) {

      if (index > (userChartDataArr.length - 1)) index = 0;

      return userChartDataArr[index];
    }

    return new ChartData();

  }

  private getUserChartDataArr(userId: string): ChartData[] {

    //console.log('this.allChartDatathis.allChatbotSummaryData', this.allChatbotSummaryData);
    //console.log('userId', userId);


    const findChartData = this.allChartData.find(chartData => {
      return chartData.user.userId === userId;
    });

    //console.log('findChartData', findChartData);

    if (findChartData) {
      return findChartData.mappedData;
    }

    return [];

  }

  private setAllChartData() {

    this.allChartData = [];

    if (this.userChartData && this.userChartData.hasData() && !this.user.isSupervisor()) {
      this.allChartData.push(this.userChartData);
    }

    this.allChartData = this.allChartData.concat(this.childrenChartData);
    //this.allChatbotSummaryData = this.childrenChartData;

    const found = this.allChartData.find(x => x.user.userId === this.currentChartUserId);

    if(!found && this.allChartData.length > 0) {
      this.currentChartUserId = this.allChartData[0].user.userId;
    }

    /*
    if (this.allChartData.length > 0 && !found) {
      this._router.navigateByUrl('/');
    }*/


  }


}
