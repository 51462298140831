import { Component, OnInit, ElementRef, ViewChild, Renderer } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { ROUTES } from '../menu-routes.routes';
import { User, HeartNotification } from '../../models';
import { HelperService, NotificationService, UserService } from '../../services';

declare var $layer: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  location: Location;
  user: User = new User();
  currentUserNotifications: HeartNotification[] = [];

  private listTitles: any[];
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;

  @ViewChild('navbar-cmp') button;

  constructor(
    location: Location,
    private renderer: Renderer,
    private element: ElementRef,
    public _userService: UserService,
    private _notificationService: NotificationService,
    private _router: Router
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];

    this._userService.listenUser().subscribe(theUser => {
      if (theUser) {
        this.user = theUser;
      }
    });

    this._notificationService.listenNotifications().subscribe(notifications => {
      if (notifications) {
        this.currentUserNotifications = notifications.filter(x => x.state === 'Success');
      }
    });

  }


  get6LatestNotifications(): HeartNotification[] {
    return this.currentUserNotifications.slice(0, 6);
  }



  getTitle() {

    const titlePath = window.location.pathname;
    for (const item of this.listTitles) {
      if (item.path === titlePath) {
        return item.title;
      }
    }
    return 'Dashboard';
  }
  sidebarToggle() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];

    if (this.sidebarVisible === false) {
      setTimeout(() => {
        toggleButton.classList.add('toggled');
      }, 500);
      body.classList.add('nav-open');
      this.sidebarVisible = true;
    } else {
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      body.classList.remove('nav-open');
    }
  }

  navigateToNotification(notificationId = null) {
    if (notificationId) {
      this._router.navigateByUrl('/notifications-single?id=' + notificationId);
    }
  }

  changeSiteRole(roleType) {
    this._userService.roleToDisplay = roleType;
    this._userService.getUser(true).then(x => console.log('updatedRole', x));
  }
}
