export class MeasureChildSummaryValue {
    totalMeasuresCount: number;
    totalMeasureAverage: number;
    maxMeasureValue: number;
    minMeasureValue: number;
    type: string;

    constructor(chartObj: any = null) {
        this.totalMeasuresCount = (chartObj && chartObj['totalMeasuresCount']) ? chartObj.totalMeasuresCount : 0;
        this.totalMeasureAverage = (chartObj && chartObj['totalMeasureAverage']) ? chartObj.totalMeasureAverage : 0;
        this.maxMeasureValue = (chartObj && chartObj['maxMeasureValue']) ? chartObj.maxMeasureValue : 0;
        this.minMeasureValue = (chartObj && chartObj['minMeasureValue']) ? chartObj.minMeasureValue : 0;
        this.type = (chartObj && chartObj['type']) ? chartObj.type : '';
    }

}
