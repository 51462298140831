import { Component, OnInit } from '@angular/core';
import {ChildrenService, NotificationService, UserService} from '../../shared/services';
import {ChildrenParentsUser, HealthSchedule, HeartNotification, User} from '../../shared/models';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-notifications-single',
  templateUrl: './notifications-single.component.html',
  styleUrls: ['./notifications-single.component.scss']
})
export class NotificationsSingleComponent implements OnInit {

  currentUserNotifications: HeartNotification[] = [];
  currentDoctorsNotifications: HeartNotification[] = [];

  allNotifications: HeartNotification[] = [];

  doctorsChildren: User[] = [];
  user: User = new User();
  allUsers: User[] = [];

  targetDoctorsNotifications: HeartNotification[] = [];
  targetNotification: HeartNotification = new HeartNotification();
  targetUserId: string = '';
  targetNotificationId: string = '';

  isloading = true;

  constructor(
    private _userService: UserService,
    private _notificationService: NotificationService,
    private _childrenService: ChildrenService,
    private _activeRoute: ActivatedRoute,
    private _location: Location,
    private _router: Router
  ) {}


  ngOnInit() {

    this.handleHttpParams();

    this._userService.getUser().then(theUser => {
      this.user = theUser;

      console.log('this.user', this.user);

      this._childrenService.getAllUsers().then(allUsers => {

        console.log('this.allUsers', allUsers);
        this.allUsers = allUsers;

        this._notificationService.listenNotifications().subscribe(notification => {
          if(notification) {
            this.currentUserNotifications = notification;

            this.allNotifications = this.allNotifications.concat(this.currentUserNotifications);

            const isTargetFound = this.allNotifications.find(z => z.id === this.targetNotificationId);
            if( isTargetFound ) {
              this.targetNotification = isTargetFound;
              this.isloading = false;
            }
            console.log('this.currentUserNotifications', this.currentUserNotifications);
          }
        });

        if(this.user.isDoctor()){
          this._childrenService.getDoctorsChildren(this.user.userId).then(x => {
            this.doctorsChildren = x.children;
            console.log('this.doctorsChildren', this.doctorsChildren);

            this._notificationService.getSupervisorNotifications( x.children ).then(y => {
              this.currentDoctorsNotifications = y;

              this.allNotifications = this.allNotifications.concat(this.currentDoctorsNotifications);
              const isTargetFound = this.allNotifications.find(z => z.id === this.targetNotificationId);
              if( isTargetFound ) {
                this.targetNotification = isTargetFound;
              }

              this.targetDoctorsNotifications = y.filter(z => z.userId === this.targetUserId);
              this.isloading = false;
              console.log('this.currentDoctorsNotifications', this.currentDoctorsNotifications);
            });
          });
        }

      });

    });

  }


  handleHttpParams() {

    this._activeRoute.queryParams.subscribe(params => {

      if( params['userId'] ) {
        this.targetUserId = params['userId'];
        this.targetDoctorsNotifications = this.currentDoctorsNotifications.filter(z => z.userId === this.targetUserId);
      }

      if( params['id'] ) {
        this.targetNotificationId = params['id'];
        const isTargetFound = this.allNotifications.find(z => z.id === this.targetNotificationId);
        if( isTargetFound ) {
          this.targetNotification = isTargetFound;
        }
      }

    });
  }

  getTargetUserName( userId ): string {
    const foundUser = this.allUsers.find(x => x.userId === userId);
    return (foundUser) ? foundUser.getFullName() : userId;
  }

  backClicked() {
    this._location.back();
  }

  getTableHeaders(): string[] {
    return ['Nr', 'Message', 'State', 'Date', 'Sent by'];
  }

  getTableDataRows( notifications: HeartNotification[] = [] ): any[]{

    let counter = 0;

    return notifications.map(x => {

      const sentByUser = this.allUsers.find( y => y.userId === x.sentBy);
      let sentByName = x.sentBy;
      if( sentByUser ){
        sentByName = sentByUser.getFullName();
      }
      ++counter;

      return [counter, x.message, x.state, x.timestampAsDate.toLocaleString(), sentByName];
    });
  }

  navigateToNotification( theId, theUserId){

    if(theId && theUserId){
      this._router.navigateByUrl('/notifications-single?userId=' +theUserId +'&id=' +theId);
    }

  }

}
