import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { LayoutModule } from './shared/layouts';
import {HttpInterceptorTmp} from './shared/interceptors';
import * as PlotlyJS from 'plotly.js/dist/plotly.js';
import { PlotlyModule } from 'angular-plotly.js';
import {ConfirmationDialogComponent} from './shared/confirmation-dialog';
import {MatDialogModule} from '@angular/material';

PlotlyModule.plotlyjs = PlotlyJS;

declare const $: any;

@NgModule({
  imports: [
      BrowserAnimationsModule,
      FormsModule,
      HttpModule,
      HttpClientModule,
      RouterModule,
      AppRoutingModule,
      LayoutModule,
      PlotlyModule,
      MatDialogModule
  ],
  declarations: [
      AppComponent,
      ConfirmationDialogComponent
  ],
  providers: [{
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorTmp,
      multi: true
  }],
  entryComponents: [
    ConfirmationDialogComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }
