import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs/Rx";
import { ChartData, ChartDataHolder, ChildrenParentsUser, DetailedChartData, HealthSchedule, MeasureSummary, User } from '../models';
import { HttpParams } from "@angular/common/http";
import { ApiService } from "./api.service";
import { DatePipe } from "@angular/common";
import { HelperService } from "./helper.service";
import * as moment from 'moment';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})

export class ChildrenService {

  //private childrenChartData: ChartDataHolder[] = null;
  private childrenDetailedChartData: DetailedChartData[] = null;
  private allUsers: User[] = null;


  private childrenChartDataSubject: BehaviorSubject<ChartDataHolder[]> = new BehaviorSubject<ChartDataHolder[]>(null);
  private childrenChartData: Observable<ChartDataHolder[]> = this.childrenChartDataSubject.asObservable();

  private currentDoctorsChildrenSubject: BehaviorSubject<ChildrenParentsUser> = new BehaviorSubject<ChildrenParentsUser>(null);
  private currentDoctorsChildren: Observable<ChildrenParentsUser> = this.currentDoctorsChildrenSubject.asObservable();

  private childrenLastWeekChartDataSubject: BehaviorSubject<ChartDataHolder[]> = new BehaviorSubject<ChartDataHolder[]>(null);
  private childrenLastWeekChartData: Observable<ChartDataHolder[]> = this.childrenLastWeekChartDataSubject.asObservable();

  constructor(
    private _apiService: ApiService,
    private _datePipe: DatePipe,
    private _helperService: HelperService
  ) { }


  listenChildrenChartData(): Observable<ChartDataHolder[]> {
    return this.childrenChartData;
  }

  getChildrenChartData(childUser: User, forceRefresh = false, nrMonthBackFromNow: number = 1): Promise<ChartDataHolder[]> {
    return new Promise((resolve) => {

      if (this.childrenChartDataSubject.getValue()) {

        // if child user already in array return all children chart data holders
        if ( this.childrenChartDataSubject.getValue().find(x => x.user.userId === childUser.userId) && !forceRefresh ) {
          resolve(this.childrenChartDataSubject.getValue());
        } else {
          this.refreshChildChartData(childUser, nrMonthBackFromNow, resolve);
        }

      } else {
        this.refreshChildChartData(childUser, nrMonthBackFromNow, resolve);
      }

    });
  }

  getChildrenDetailedChartData(childUserId: string, searchDate: string, measures: string[]): Promise<DetailedChartData[]> {
    return new Promise((resolve) => {
      if (this.childrenDetailedChartData) {

        // check if new measurements in request
        let measureIsInArr = false;
        for (const measure of measures) {
          if (this.childrenDetailedChartData.find(x => x.typeOfMeasurement === measure && x.dateString === searchDate && x.userId === childUserId)) {
            measureIsInArr = true;
          }
        }

        if (measureIsInArr) {
          resolve(this.childrenDetailedChartData);
        } else {
          this.refreshChildDetailedChartData(childUserId, searchDate, measures, resolve);
        }


      } else if (childUserId && searchDate && measures) {
        this.refreshChildDetailedChartData(childUserId, searchDate, measures, resolve);
      } else {
        resolve([]);
      }
    });
  }

  listenCurrentDoctorsChildren(): Observable<ChildrenParentsUser> {
    return this.currentDoctorsChildren;
  }

  getDoctorsChildrenChartData( parentId: string, forceRefresh = false, nrMonthBackFromNow: number = 1): Promise<ChartDataHolder[]> {

    return new Promise((resolve) => {

      this.getDoctorsChildren( parentId ).then((childParentUser: ChildrenParentsUser) => {

        let numberOfHandledChildren = 0;

        if( childParentUser.children.length > 0 ) {

          for(let child of childParentUser.children) {
            this.getChildrenChartData( child, forceRefresh, nrMonthBackFromNow ).then( x => {
              numberOfHandledChildren++;

              if(numberOfHandledChildren === childParentUser.children.length) {
                resolve(this.childrenChartDataSubject.getValue());
              }
            });
          }

        } else {
          resolve([]);
        }

      }, reject => {
        resolve([]);
      });

    });

  }


  getDoctorsChildren(parentId: string, forceRefresh = false): Promise<ChildrenParentsUser> {

    return new Promise((resolve, reject) => {
      if (this.currentDoctorsChildrenSubject.getValue() && !forceRefresh) {
        resolve(this.currentDoctorsChildrenSubject.getValue());
      } else {
        this.refreshParentsChildren(parentId, resolve, reject);
      }

    });

  }


  getAllUsers( isForced = false ): Promise<User[]> {
    return new Promise((resolve) => {
      if (this.allUsers && !isForced) {
        resolve(this.allUsers);
      } else {
        this.refreshAllUser(resolve);
      }
    });
  }

  getChildrenLastWeekData(children: User[], isRefresh = false): Promise<ChartDataHolder[]> {

    return new Promise((resolve) => {
      if (this.childrenLastWeekChartDataSubject.getValue() && !isRefresh) {
        resolve(this.childrenLastWeekChartDataSubject.getValue());
      } else {
        this.refreshChildrenLastWeekData(children).then(x => resolve(x));
      }

    });

  }


  private refreshAllUser(resolve): void {

    this._apiService.get(`/administration/users?details=true`).subscribe(data => {
      const allUsers = data.map(x => new User(x));
      this.allUsers = allUsers.sort(this._helperService.sortUsersByName);
      resolve(allUsers);
    }, error => {
      console.log('error GET /administration/users?details=true', error);
      resolve([]);
    });
  }


  private refreshChildChartData(childUser: User, nrMonthBackFromNow: number = 1, resolve): void {

    const nowDate = moment();
    const endDate = nowDate.format('YYYY-MM-DD');
    const startDate = nowDate.subtract(nrMonthBackFromNow, 'months').format('YYYY-MM-DD');

    const httpParams = new HttpParams()
      .set('endDate', endDate)
      .set('startDate', startDate); // default 1 month prior

    //console.log('refreshChildChartData with params:', httpParams);

    let tempData = {
      user: childUser, // ads the user object to ChartDataHolder
      ChartData: [],
      isInit: true
    };

    this.checkIfUpdatedChartDataAndAddToObservable(new ChartDataHolder(tempData));

    this._apiService.get(`/administration/${childUser.userId}/measure`, httpParams).subscribe(data => {

      tempData.ChartData = data;
      tempData.isInit = false;
      this.checkIfUpdatedChartDataAndAddToObservable(new ChartDataHolder(tempData));
      resolve(this.childrenChartDataSubject.getValue());

    }, error => {
      console.log('ERROR Refreshing measurement data for child', error);
      resolve([]);
    });

  }


  private refreshChildDetailedChartData(childUserId: string, searchDate: string, measures: string[], resolve): void {

    let detailedChartDataArr: DetailedChartData[] = (this.childrenDetailedChartData) ? this.childrenDetailedChartData : [];

    const tempMeasures: string[] = measures;
    if (tempMeasures.length > 0) {

      // remove measures that is already fetched
      if (this.childrenDetailedChartData) {
        for (let i = 0; i < tempMeasures.length - 1; i++) {
          if (this.childrenDetailedChartData.find(x => x.typeOfMeasurement === tempMeasures[i] && x.dateString === searchDate && x.userId === childUserId)) {
            tempMeasures.splice(i, 1);
          }
        }
      }


      let counter = 0;

      for (const measure of tempMeasures) {

        const httpParams = new HttpParams()
          .set('date', searchDate)
          .set('measure', measure);

            this._apiService.get(`/administration/${childUserId}/measure/details`, httpParams).subscribe(data => {

              ++counter;
              if (data) {

                let tempData = data;
                tempData['userId'] = childUserId;

                //console.log('tempData[userId]', tempData['userId']);

                const newObj = new DetailedChartData(tempData);

                const foundIndex = this._helperService.isInArray(newObj, detailedChartDataArr);

                if (foundIndex) {
                  detailedChartDataArr.splice(foundIndex, 1);
                }

                detailedChartDataArr.push(newObj);

              }

              if (counter === tempMeasures.length) {
                this.childrenDetailedChartData = detailedChartDataArr;
                resolve(detailedChartDataArr);
              }

            }, error => {
              console.log('ERROR Refreshing measurement data for child 2', error);
              ++counter;

              if (counter === tempMeasures.length) {
                resolve(detailedChartDataArr);
              }
            });

      }



    } else if (this.childrenDetailedChartData) {
      resolve(this.childrenDetailedChartData);
    } else {
      resolve([]);
    }

  }


  private refreshParentsChildren(parentId: string, resolve, reject): void {

    this._apiService.get(`/administration/${parentId}/connect`).subscribe(data => {

      const childrenParentsUser = new ChildrenParentsUser(data);

      if (JSON.stringify(childrenParentsUser) !== JSON.stringify(this.currentDoctorsChildrenSubject.getValue())) {
        this.currentDoctorsChildrenSubject.next(childrenParentsUser);
        resolve(this.currentDoctorsChildrenSubject.getValue());
      } else {
        reject( 'The doctors children was not updated' );
      }


    }, error => {
      console.log('ERROR GET /administration/userId/connect', error);
      reject( 'Error getting doctors children' );
    });

  }


  private refreshChildrenLastWeekData(childrenUsers: User[]): Promise<ChartDataHolder[]> {

    const childrenLastWeekPromise: Promise<ChartDataHolder[]> = new Promise((resolve) => {

      const childrenLastWeekArr: ChartDataHolder[] = (this.childrenLastWeekChartDataSubject.getValue()) ? this.childrenLastWeekChartDataSubject.getValue() : [];
      let counter = 0;

      for (const childrenUser of childrenUsers) {

        this._apiService.get(`/administration/${childrenUser.userId}/measure/summary?summaryType=LastWeek`).subscribe(data => {

          ++counter;

          // console.log('childrenUser', childrenUser);

          if (data) {

            const tempDataBody = {
              ChartData: data,
              user: childrenUser
            };

            const healthSchedule = new ChartDataHolder(tempDataBody);

            // console.log('healthSchedule', healthSchedule);
            const foundIndex = this._helperService.isInArray(healthSchedule, this.childrenLastWeekChartDataSubject.getValue());

            if (foundIndex) {
              childrenLastWeekArr.splice(foundIndex, 1);
            }

            childrenLastWeekArr.push(healthSchedule);

          }

          if (counter === childrenUsers.length) {
            resolve(childrenLastWeekArr);
          }

        }, error => {
          //console.log("GET /administration/{userId}/measure/summary error", error);

          ++counter;

          if (counter === childrenUsers.length) {
            resolve(childrenLastWeekArr);
          }
        });

      }

    });

    return childrenLastWeekPromise.then(x => {
      this.childrenLastWeekChartDataSubject.next(x);
      return x;
    });

  }


  private checkIfUpdatedChartDataAndAddToObservable(childChartData: ChartDataHolder) {

    let currentChartDataArr: ChartDataHolder[] = (this.childrenChartDataSubject.getValue()) ? this.childrenChartDataSubject.getValue() : [];

    //let foundIndex = this._helperService.isInArray(childChartData, currentChartDataArr);

    const foundIndex = currentChartDataArr.findIndex(x => x.user.userId === childChartData.user.userId);

    if( foundIndex !== -1) {

        /*
        if(childChartData.isWaitingForConnectedChildren) {
          currentChartDataArr.splice(foundIndex, 1);
        }
        */

        currentChartDataArr[foundIndex] = childChartData;
      } else {
        currentChartDataArr.push(childChartData);
      }

    //}

    this.childrenChartDataSubject.next(currentChartDataArr.sort(this._helperService.sortChildrenChartByName));

  }

}
