import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';

import {
  ApiService,
  ChartDataService,
  ChildrenService,
  HelperService, 
  ScheduleService,
  UserService
} from '../../shared/services';
import {
  ChildrenParentsUser, HealthDailySchedule,
  HealthSchedule, HealthScheduleAction,
  User
} from '../../shared/models';
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from "../../shared/modal";
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-handle-schedule',
  templateUrl: './handle-schedule.component.html',
  styleUrls: ['./handle-schedule.component.scss']
})
export class HandleScheduleComponent implements OnInit {

  @ViewChildren('lineCharts') lineCharts: QueryList<ElementRef>;
  @ViewChildren('statusChildrenCharts') statusChildrenCharts: QueryList<ElementRef>;

  user: User = new User(null);

  userSchedule: HealthSchedule = new HealthSchedule();
  isWaitingForSchema: boolean = true;
  siteLanguage: string = '';
  childrenParentsUser: ChildrenParentsUser = new ChildrenParentsUser();
  childrenSchedules: HealthSchedule[] = [];

  isEditSchedule: boolean = false;
  targetChild: User = null;
  targetSchedule: HealthSchedule = null;

  //updateScheduleResponse: {message: string, isSuccess: boolean} = {message: '', isSuccess: true};
  healthScheduleFormGroups: FormGroup[] = [];

  validMeasureTypesMeasures: string[] = [
    'WeightKg',
    'NyhaClassification',
    'WaterMass',
    'EKG',
    'Height',
    'FatfreeMassKg',
    'FatRatio',
    'FatMassWeightKg',
    'MuscleMass',
    'Hydration',
    'BoneMass',
    'PulseWaveVelocity',
    'PulseBPM',
    'PulseOx',
    'StressLevel',
    'ConsumedCalories',
    'BmrKiloCalories',
    'DistanceInMeters',
    'Steps',
  ];

  validMeasureTypesMedication: string[] = [
    'Furix'
  ];

  validMeasureTypesOther: string[] = [
    'Exercise',
    'Walk',
    'Light walk',
    'Fast walk',
    'Run',
    'Light run',
    'Fast run',
    'Survey',
  ];

  constructor(
    private _apiService: ApiService,
    private _userService: UserService,
    private _datePipe: DatePipe,
    private _router: Router,
    private _chartDataService: ChartDataService,
    private _childrenChartDataService: ChildrenService,
    private _helperService: HelperService,
    private _scheduleService: ScheduleService,
    private _modalService: ModalService,
    private _formBuilder: FormBuilder,
    private _activeRoute: ActivatedRoute,
    private _dialog: MatDialog) { }

  ngOnInit() {

    this._userService.getUser().then(theUser => {
      this.user = theUser;

      if (this.user.isDoctor()) {

        this._childrenChartDataService.getDoctorsChildren(this.user.userId).then(children => {
          if (children) {
            this.childrenParentsUser = children;
            this._scheduleService.getSupervisorSchedule(children.children).then(childrenSchedules => {
              this.isWaitingForSchema = false;
              this.childrenSchedules = childrenSchedules;
              this.handleHttpParams();
            });
          }
        });

      }

    });



    this._helperService.language.subscribe(lang => this.siteLanguage = lang);
  }

  addToFormControl(type: string, day: string) {

    // @ts-ignore
    const formArray: FormArray = this.healthScheduleFormGroups.find(x => x.controls['day'].value === day).controls[type];
    formArray.push(this.createItem());

  }

  removeFromFormControl(index: number, type: string, day: string) {

    // @ts-ignore
    const formArray: FormArray = this.healthScheduleFormGroups.find(x => x.controls['day'].value === day).controls[type];
    formArray.removeAt(index);

    const theTargetRemoveDayActions = this.targetSchedule[day][type] as HealthScheduleAction[];
    const theIndex = theTargetRemoveDayActions.findIndex(x => x.id == formArray.value['id']);
    theTargetRemoveDayActions.splice(theIndex, 1);

    //this.initEditChildSchedule();

  }

  getRandomInt() {
    return Math.floor(Math.random() * Math.floor(100000000000));
  }

  isScheduleFormInvalid(): boolean {
    return (this.healthScheduleFormGroups.find(x => !x.valid === true)) ? true : false;
  }

  handleHttpParams() {

    this._activeRoute.queryParams.subscribe(params => {

      if (params['userId']) {
        this.isEditSchedule = true;
        this.targetChild = this.childrenParentsUser.children.find(x => x.userId === params['userId']);
        const targetSchedule = this.childrenSchedules.find(x => x.user.userId === params['userId']);
        this.targetSchedule = (targetSchedule) ? targetSchedule : new HealthSchedule();
        this.initEditChildSchedule();
      }

    });
  }

  initEditChildSchedule() {

    console.log(' this.targetSchedule', this.targetSchedule);
    this.healthScheduleFormGroups = [];
    const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    for (const weekday of weekDays) {

      this.healthScheduleFormGroups.push(this._formBuilder.group({
        day: this._formBuilder.control({ value: weekday, disabled: true }, [Validators.required]),
        medicines: this._formBuilder.array([]),
        measurements: this._formBuilder.array([]),
        other: this._formBuilder.array([]),
      })
      );
    }

    if (this.targetSchedule && this.targetSchedule.isHaveDailyActions()) {
      for (const targetDay in this.targetSchedule) {
        const value = this.targetSchedule[targetDay];
        if (value instanceof HealthDailySchedule) {
          for (const key in this.healthScheduleFormGroups) {

            if (this.healthScheduleFormGroups[key].controls['day'].value === targetDay) {

              const measurementsCtr: FormArray = this.healthScheduleFormGroups[key].controls['measurements'] as FormArray;
              const medicinesCtr: FormArray = this.healthScheduleFormGroups[key].controls['medicines'] as FormArray;
              const otherCtr: FormArray = this.healthScheduleFormGroups[key].controls['other'] as FormArray;

              value.measurements.forEach(x => {
                measurementsCtr.push(this.createItem(x));
              });

              value.medicines.forEach(x => {
                medicinesCtr.push(this.createItem(x));
              });

              value.other.forEach(x => {
                otherCtr.push(this.createItem(x));
              });

            }
          }
        }
      }
    }

  }

  private createItem(obj: object = null): FormGroup {
    return this._formBuilder.group({
      type: this._formBuilder.control(((obj && obj['type']) ? obj['type'] : ''), [Validators.required]),
      title: this._formBuilder.control(((obj && obj['title']) ? obj['title'] : ''), [Validators.required]),
      description: this._formBuilder.control(((obj && obj['description']) ? obj['description'] : ''), [Validators.required]),
      completeByTime: this._formBuilder.control(((obj && obj['completeByTime']) ? obj['completeByTime'] : ''), [Validators.required, Validators.pattern('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')]),
      id: this._formBuilder.control(((obj && obj['id']) ? obj['id'] : 'new'), [Validators.required])
    });
  }

  submitSchedule() {
    //console.log('healthScheduleFormGroups', this.healthScheduleFormGroups);

    if (!this.isScheduleFormInvalid()) {

      let newSchedule = (this.targetSchedule) ? this.targetSchedule : new HealthSchedule();

      console.log('this.targetSchedule', this.targetSchedule);

      newSchedule = newSchedule.patchValue(this.healthScheduleFormGroups.map(x => {
        const tempValue = x.value;
        tempValue['day'] = x.controls['day'].value;
        return tempValue;
      }));

      console.log('newSchedule', newSchedule);

      const apiParsedScheduleBody = newSchedule.makeApiFriendly();

      console.log('apiParsedScheduleBody', apiParsedScheduleBody);

      this._helperService.showFullScreenLoding();
      this._apiService.post(`/administration/${this.targetChild.userId}/schedule`, apiParsedScheduleBody).subscribe(data => {

        // send notification...
        const body = {
          message: 'Schemat har ändrats',
          additionalData: encodeURIComponent(JSON.stringify({ type: 'schema_updated', descr: 'Schemat har ändrats av Din doktor' }))
        };
        this._apiService.post(`/notify/connected/${this.targetChild.userId}`, body)
        .subscribe(() => console.log('Notification sent to: ' + this.targetChild.userId));

        // update the children with the new value
        this._scheduleService.getSupervisorSchedule([this.targetChild], true).then(x => {
          console.log('x', x);

          this.childrenSchedules = x;
          this._helperService.hideFullScreenLoading();
          this.responsePopup( this.targetChild.getFullName() + ' schema har uppdaterats' );
        });


      }, error => {
        console.log('error GET /administration/{userId}/schedule', error);
        this.responsePopup( 'Schema kunde inte uppdateras' );
        this._helperService.hideFullScreenLoading();
      });

    }

  }


  responsePopup( message: string) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {message: message, isConfirmation: false}
    });
  }


  redirectToView(userId) {
    this._router.navigateByUrl(`/schedule?userId=${userId}`)
  }

  redirectToEdit(userId = null) {

    if (userId) {
      this._router.navigateByUrl(`/handle-schedule?userId=${userId}`);
      this.isEditSchedule = (!(this.targetChild && userId === this.targetChild.userId && this.isEditSchedule));
    } else {
      this._router.navigateByUrl(`/handle-schedule`);
      this.isEditSchedule = false;
    }

  }

  userHaveMeasures(userId): boolean {
    const healthSchedule = this.getHealthSchedule(userId);
    if (healthSchedule) {
      return healthSchedule.isHaveDailyActions();
    }
    return false;
  }


  getHealthSchedule(userId): HealthSchedule {
    const healthSchedule = this.childrenSchedules.find(x => x.user.userId === userId);
    return (healthSchedule) ? healthSchedule : new HealthSchedule();

  }

}
