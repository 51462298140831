import { ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../../shared";
import { DetailedChartDayComponent } from "./detailed-chart-day.component";
import { AuthGuard } from "../../shared/guards";
import { PlotlyModule } from 'angular-plotly.js';
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";

const DetailedChartDayRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'detailed-chart-day',
        component: DetailedChartDayComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
        DetailedChartDayComponent,
    ],
    imports: [
        SharedModule,
        DetailedChartDayRouting,
        PlotlyModule,
        JwBootstrapSwitchNg2Module
    ],
})

export class DetailedChartDayModule { }
