import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import * as Chartist from 'chartist';
import 'chartist-plugin-tooltips';

import {
    ApiService,
    ChartDataService,
    ChildrenService,
    HelperService,
    UserService
} from "../../shared/services";
import { ChartData, ChartDataHolder, ChartDataSummary, ChartDataXY, ChildrenParentsUser, MeasureSummary, User } from '../../shared/models';
import { HttpParams } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { ModalService } from "../../shared/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as  moment from 'moment';
import {TranslatePipe} from 'heart-app-shared-pipes';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

    user: User = new User(null);
    userChartData: ChartDataHolder = new ChartDataHolder();

    graphs: any[] = [];

    isWaitingForChartData: boolean = true;
    isWaitingForAvgValues: boolean = true;

    siteLanguage: string = '';

    isNoDataFound: boolean = false;

    constructor(
        private _apiService: ApiService,
        private _userService: UserService,
        private _datePipe: DatePipe,
        private _router: Router,
        private _chartDataService: ChartDataService,
        private _childrenChartDataService: ChildrenService,
        private _helperService: HelperService,
        private _modalService: ModalService,
        private _formBuilder: FormBuilder) { }

    ngOnInit() {

        this.isWaitingForAvgValues = false;

        this._userService.getUser().then(theUser => {
            this.user = theUser;
            this.getPatientData();
        });

        this._helperService.language.subscribe(lang => this.siteLanguage = lang);

    }

    getPatientData() {

        this._chartDataService.getUserChartData(this.user).then(chartDataHolder => {
            this.isWaitingForChartData = false;
        }).catch(err => {
            this.isWaitingForChartData = false;
        });

        this._chartDataService.listenChartData().subscribe(chartDataHolder => {

            if (chartDataHolder && chartDataHolder.mappedData.length > 0) {
                this.userChartData = chartDataHolder;
                this.generatePlotlys();
                this.isWaitingForChartData = false;
            }

            if (chartDataHolder && chartDataHolder.mappedData.length === 0) {
              this.isNoDataFound = true;
              this.isWaitingForChartData = false;
            }

        });

    }

    ngAfterViewInit() {

    }


    redirectToDetailed(index) {
        this._router.navigateByUrl('/detailed-chart?index=' + index);
    }



  private generatePlotlys() {

    this.graphs = [];

    for (const chartData of this.userChartData.mappedData) {

      this.graphs.push({
        type: chartData.type,
        data: [
          {
            name: chartData.type,
            x: chartData.getChartDataOrderedByDate().x,
            y: chartData.getChartDataOrderedByDate().y,
            type: 'scatter',
            mode: 'lines+markers',
            connectgaps: true,
            line: {
              color: '#749BB6',
              width: 4,
              shape: 'spline',
              smoothing: '0.5',
              // color: rgb(91, 175, 234),
              simplify: true,
            },
            marker: {
              size: 10
            }
          }
        ],
        layout: {
          height: 350,
          autosize: true,
          title: {
            text: '',
            x: 0.01,
            font: {
              size: 20,
              color: 'rgba(0, 0, 0, 0.9)', // override title font color

            }
          },
          font: { // global font settings
            family: '\'Muli\', \'Helvetica\', Open Sans, verdana, arial, sans-serif',
            color: 'rgba(0, 0, 0, 0.6)',
          },
          /*xaxis: {
            tickformat: 'Vecka %-U',
          }*/
        },
        config: {
          // showLink: true,
          showSendToCloud: true,
          displaylogo: false,
          locale: 'sv'
        }
      });
    }
  }



}
