import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services';
import { ROUTES } from '../menu-routes.routes';
import { User } from '../../models';

declare const $: any;

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  showOn: string[]; // enum[]: all, patient, doctor, manual-patient
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  menuItems: RouteInfo[];
  user: User = new User(null);

  constructor(private _userService: UserService) { }

  ngOnInit() {

    this._userService.listenUser().subscribe(theUser => {

      if (theUser) {
        this.user = theUser;
        if (this.user.isDoctorAndPatient()) {
          this.menuItems = ROUTES
            .filter(menuItem =>
              (menuItem.showOn.indexOf('patient') > -1 || menuItem.showOn.indexOf('doctor') > -1 || menuItem.showOn.indexOf('all') > -1));
        } else if (this.user.isDoctor()) {
          this.menuItems = ROUTES
            .filter(menuItem =>
              (menuItem.showOn.indexOf('doctor') > -1 || menuItem.showOn.indexOf('all') > -1));
        } else if (this.user.isPatient()) {
          this.menuItems = ROUTES
            .filter(menuItem =>
              (menuItem.showOn.indexOf('patient') > -1 || menuItem.showOn.indexOf('all') > -1));
        } else if (this.user.isSuperUser()) {
          this.menuItems = ROUTES
            .filter(menuItem =>
              (menuItem.showOn.indexOf('superuser') > -1 || menuItem.showOn.indexOf('all') > -1));
        } else if (this.user.isResearcher()) {
          this.menuItems = ROUTES
            .filter(menuItem =>
              (menuItem.showOn.indexOf('researcher') > -1 || menuItem.showOn.indexOf('all') > -1));
        } else if (this.user.isUnassigned()) {
          this.menuItems = ROUTES
            .filter(menuItem =>
              (menuItem.showOn.indexOf('unassigned') > -1 || menuItem.showOn.indexOf('all') > -1));
        } else {
          this.menuItems = ROUTES
            .filter(menuItem =>
              (menuItem.showOn.indexOf('no-roll') > -1));
        }
      }

    }, err => console.log('error subscribe theUser', err));

  }

  isNotMobileMenu() {
    return $(window).width() <= 991;
  }

  /*
  isMobileMenu() {
      return $(window).width() <= 991;
  };
  */

  logOut() {
    this._userService.logOut();
  }

}
