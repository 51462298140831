import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {AddManualDataComponent} from "./add-manual-data.component";
import {SharedModule} from "../../shared";
import {AuthGuard} from "../../shared/guards";

const userProfileRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'add-manual-data',
        component: AddManualDataComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
        AddManualDataComponent,
    ],
    imports: [
        SharedModule,
        userProfileRouting,
    ],
})

export class AddManualDataModule {}
