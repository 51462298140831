import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import * as Chartist from 'chartist';
import 'chartist-plugin-tooltips';

import {
  ApiService,
  ChartDataService, ChatbotService,
  ChildrenService,
  HelperService,
  UserService
} from '../../shared/services';
import { ChartData, ChartDataHolder, ChartDataXY, ChatbotSummary, ChildrenParentsUser, DateFilterHolder, User } from '../../shared/models';
import { HttpParams } from "@angular/common/http";
import { DatePipe, Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment';
import { TranslatePipe } from 'heart-app-shared-pipes';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})

export class ChatbotComponent implements OnInit {

  //@ViewChild('lineChart') lineChart: ElementRef;

  user: User = new User(null);
  userChatBotSummary: ChatbotSummary[] = null;
  currentChatbotUserId: string = '';
  isWaitingForChartData: boolean = true;

  dateFilterHolder: DateFilterHolder[] = [new DateFilterHolder().init()];

  isUserDoctor: boolean = false;
  isWaitingForConnectedChildrenMeasures: boolean = true;

  childrenChatbotSummaryData: ChatbotSummary[] = [];
  allChatbotSummaryData: ChatbotSummary[] = [];


  constructor(
    private _apiService: ApiService,
    private _userService: UserService,
    private _datePipe: DatePipe,
    private _activeRoute: ActivatedRoute,
    private _location: Location,
    private _chatbotService: ChatbotService,
    private _helperService: HelperService,
    private _router: Router) { }

  ngOnInit() {

    this._userService.getUser().then(theUser => {
      this.user = theUser;
      //this.currentChatbotUserId = theUser.userId;

      if (this.user.isDoctor()) {
        this.isUserDoctor = true;
        this.handleHttpParams();
        this.getChildrenWithFetchedChartData();
        //this.getAuthedUserChildrenAndParentsData();
        this.isWaitingForChartData = false;

      } else {
        this.isWaitingForConnectedChildrenMeasures = false;
      }



      this._chatbotService.getChatbotData(theUser).then(chartDataHolder => {
        this.userChatBotSummary = chartDataHolder;
        this.isWaitingForChartData = false;
      }).catch(err => {
        this.isWaitingForChartData = false;
      });


      this._chatbotService.listenChatbot().subscribe(chartDataHolder => {

        if (chartDataHolder.length > 0) {
          this.userChatBotSummary = chartDataHolder.filter(x => x.user.userId === this.user.userId);

          if ( !this.user.isDoctor() ) {
            this.currentChatbotUserId = this.user.userId; // is a patient
            this.setAllChartData();
          }

          this.isWaitingForChartData = false;

        }

      });

    });

  }

  handleHttpParams() {

    this._activeRoute.queryParams.subscribe(params => {

      if (params['userId']) {
        this.currentChatbotUserId = params['userId'];
      }

    });

  }

  getChildrenWithFetchedChartData() {

    this._chatbotService.getDoctorsChildrenChatBotData(this.user.userId).then(childrenChatbotSummaryData => {

      console.log('childrenChartData', childrenChatbotSummaryData);
      this.childrenChatbotSummaryData = childrenChatbotSummaryData;

      // if(this.childrenChatbotSummaryData.length > 0 && this.currentChatbotUserId === '') {
      //   this.currentChatbotUserId = this.childrenChatbotSummaryData[0].user.userId;
      // }

      this.setAllChartData();

      this.isWaitingForConnectedChildrenMeasures = false;

    });
  }


  backClicked() {
    this._location.back();
  }

  getPrettyDate(date: Date): string {
    return this._helperService.getPrettyDate(date);
  }

  parseDate(date: Date): string {
    return this._helperService.getDateString(date);
  }

  parseTime(date: Date): string {
    return this._helperService.getTimeString(date);
  }

  goToDetailed(chatId) {
    this._router.navigateByUrl(`/chatbot-single?childId=${this.currentChatbotUserId}&chatId=${chatId}`);
  }

  getChatbotChildrenUsers(): User[] {
    let index = [];

    const uniqUserChatbotItems = this.allChatbotSummaryData.filter(chatbotItem => {
      return index.indexOf(chatbotItem.user.userId) >= 0 ? false : index.push(chatbotItem.user.userId);
    });

    const chatbotUsers = uniqUserChatbotItems.map(x => x.user);

    return chatbotUsers.sort(this._helperService.sortUsersByName);

  }

  getChatbotSummariesToDisplay(): ChatbotSummary[] {
    return this.allChatbotSummaryData.filter(x => x.user.userId === this.currentChatbotUserId)
  }


  getCurrentUser(userId: string): User {

    const findUser = this.allChatbotSummaryData.find(chartData => {
      return chartData.user.userId === userId;
    });

    // console.log('findUser', findUser);

    if (findUser) {
      return findUser.user;
    }

    return new User();

  }

  changeCurrentUserId(userId: string) {
    this.currentChatbotUserId = userId;
    //this.populateGraphsWithData(this.currentTargetIndex, this.currentChatbotChildId);
  }


  getCurrentDateFilter(): string {
    const current = this.dateFilterHolder.find(x => x.isCurrent === true);
    return (current) ? current.name : 'All';
  }


  private setAllChartData() {

    this.allChatbotSummaryData = [];

    if (this.userChatBotSummary && !this.user.isSupervisor()) {
      this.allChatbotSummaryData = this.allChatbotSummaryData.concat(this.userChatBotSummary);
    }

    this.allChatbotSummaryData = this.allChatbotSummaryData.concat(this.childrenChatbotSummaryData);
    //this.allChatbotSummaryData = this.childrenChartData;

    const found = this.allChatbotSummaryData.find(x => x.user.userId === this.currentChatbotUserId);

    if(!found && this.allChatbotSummaryData.length > 0) {
      this.currentChatbotUserId = this.allChatbotSummaryData[0].user.userId;
    }

    /*
    if (this.allChatbotSummaryData.length > 0 && !found) {
      this._router.navigateByUrl('/');
    }
    */

  }


}
