import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SupportComponent} from "./support.component";
import {SharedModule} from "../../shared";
import {AuthLoggedInGuard} from "../../shared/guards";

const manualDataRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'support',
        component: SupportComponent,
        canActivate: [AuthLoggedInGuard]
    }
]);

@NgModule({
    declarations: [
        SupportComponent,
    ],
    imports: [
        SharedModule,
        manualDataRouting,
    ],
})

export class SupportModule {}
