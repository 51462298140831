export * from './dashboard/dashboard.module';
export * from './icons/icons.module';
export * from './maps/maps.module';
export * from './notifications/notifications.module';
export * from './table-list/table-list.module';
export * from './typography/typography.module';
export * from './user-profile/user-profile.module';
export * from './consent-patient/consent-patient.module';
export * from './login/login.module';
export * from './auth-callback/auth-callback.module';
export * from './add-manual-data/add-manual-data.module';
export * from './detailed-chart/detailed-chart.module';
export * from './detailed-chart-day/detailed-chart-day.module';
export * from './handle-users/handle-users.module';
export * from './patient-summaries/patient-summaries.module';
export * from './manual-data/manual-data.module';
export * from './dashboard-supervisor/dashboard-supervisor.module';
export * from './support/support.module';
export * from './registration/registration.module';
// export * from './auth-callback/auth-callback.module';
export * from './schedule/schedule.module';
export * from './handle-schedule/handle-schedule.module';
export * from './notifications-single/notifications-single.module';
export * from './data-extract/data-extract.module';
export * from './info/info.module';
export * from './chatbot/chatbot.module';
export * from './chatbot-single/chatbot-single.module';



