import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/index';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private _http: HttpClient,
  ) { }

  private setHeaders(httpHeaders: HttpHeaders = new HttpHeaders(), isWwwFromUrlencoded = false): HttpHeaders {
    httpHeaders = httpHeaders.set('Accept', 'application/json');

    if (isWwwFromUrlencoded) {
      httpHeaders = httpHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
    } else {
      httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    }


    return httpHeaders;
  }

  getCustom(path: string, params: HttpParams = new HttpParams(), httpHeaders = new HttpHeaders()): Observable<any> {
    return this._http.get(path, { headers: this.setHeaders(httpHeaders), params: params });
  }

  get(path: string, params: HttpParams = new HttpParams(), httpHeaders = new HttpHeaders()): Observable<any> {
    return this._http.get(`${environment.api_url}${path}`, { headers: this.setHeaders(httpHeaders), params: params });
  }


  put(path: string, body: Object = {}, params: HttpParams = new HttpParams(), httpHeaders = new HttpHeaders()): Observable<any> {
    return this._http.put(
      `${environment.api_url}${path}`,
      JSON.stringify(body),
      { headers: this.setHeaders(httpHeaders), params: params }
    );
  }

  // tslint:disable-next-line:max-line-length
  post(path: string, body: Object = {}, params: HttpParams = new HttpParams(), httpHeaders = new HttpHeaders(), isWwwFromUrlencoded = false): Observable<any> {
    return this._http.post(
      (isWwwFromUrlencoded) ? path : `${environment.api_url}${path}`,
      body,
      { headers: this.setHeaders(httpHeaders, isWwwFromUrlencoded), params: params }
    );
  }

  postCustom(path: string, body: Object = {}, params: HttpParams = new HttpParams(), httpHeaders = new HttpHeaders()): Observable<any> {
    return this._http.post(
      path,
      body,
      { headers: this.setHeaders(httpHeaders), params: params }
    );
  }

  delete(path, params: HttpParams = new HttpParams()): Observable<any> {
    return this._http.delete(
      `${environment.api_url}${path}`,
      { headers: this.setHeaders(), params: params }
    );
  }

}
