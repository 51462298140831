import { ChartDataXY } from './chart-data-xy.model';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import {ChartDataGroup} from './chart-data-group.model';
import {DataXy} from './data-xy.model';

export class ChartData {
    dataSource: string;
    dataOrigin: string;
    data: ChartDataXY[];
    type: string;
    dataGroup: ChartDataGroup;

    constructor(chartObj: any = null, chartDataGroup: ChartDataGroup = ChartDataGroup.Default) {
        this.dataSource = (chartObj && chartObj['dataSource']) ? chartObj.dataSource : '';
        this.dataOrigin = (chartObj && chartObj['dataOrigin']) ? chartObj.dataOrigin : '';
        this.type = (chartObj && chartObj['type']) ? chartObj.type : '';
        this.dataGroup = chartDataGroup;

        this.data = [];
        this.data.push(
            chartObj && chartObj['data']
                ? new ChartDataXY(chartObj.data, chartDataGroup)
                : new ChartDataXY(null, chartDataGroup));

    }

    isValid(): boolean {
        return this.type !== '';
    }

    isHaveData(): boolean {
      return this.data.length > 0 && this.data[0].x.length > 0;
    }

    getChartData(chartDataGroup: ChartDataGroup = ChartDataGroup.Default): ChartDataXY {
        return this.data.find(x => x.dataGroup === chartDataGroup);
    }


    getChartDataByDay( dateString: string) {

      let start = new Date( dateString );
      start.setHours(0,0,0,0);

      let end = new Date( dateString );
      end.setHours(23,59,59,999);

      return this.getChartDataOrderedByDate().filterByDate(start, end)

    }

    getChartDataOrderedByDate(chartDataGroup: ChartDataGroup = ChartDataGroup.Default): ChartDataXY {

        const data = this.getChartData(chartDataGroup);
        const tempArr = data.getXyAsArray();
        return this.makeChartDataXySortedByDate( tempArr );

    }


    getChartDataMissingDates(chartDataGroup: ChartDataGroup = ChartDataGroup.Default): ChartDataXY {

      const tempArr = this.getChartDataOrderedByDate( chartDataGroup ).getXyAsArray();

      //console.log('tempArr', tempArr);

      // let checkDate = new Date();
      let missingDates = [];

      //console.log('this.getLastDays()', this.getLastDays());

      for(let day of this.getLastDays()) {

        let isDayFound = false;
        for (let item of tempArr) {
          if (this.sameDay(day, item.x)) {
            //console.log('found date', item);
            isDayFound = true;
            break;
          }
        }

        if(!isDayFound) {
          missingDates.push(day);
        }

      }

      const missingXyDates = [];
      for(let missingDate of missingDates) {
        missingXyDates.push(new DataXy(missingDate, 0, chartDataGroup))
      }

      //console.log('missingDates', missingXyDates);

      return this.makeChartDataXySortedByDate( missingXyDates );

    }

    private getLastDays(nrfOfDays: number = 30): Date[] {

      const dateArr = [];
      const today = new Date();

      for (let i = 0; i < nrfOfDays; i++) {
        const targetDate = new Date();
        targetDate.setDate(today.getDate() - i);
        dateArr.push(targetDate);
      }

      return dateArr;
    }

    private sameDay(d1: Date, d2: Date): boolean {
      return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
    }

    private makeChartDataXySortedByDate( tempArr: DataXy[] ): ChartDataXY {

      const sortedByDate = tempArr.sort((a, b) => {
        if (moment(a.x) < moment(b.x)) {
          return -1;
        } else {
          return 1;
        }
      });

      const chartDataXy = new ChartDataXY(null, this.dataGroup);

      sortedByDate.forEach(x => chartDataXy.appendXY(x.x, x.y));

      return chartDataXy;
    }

    appendXY(x: Date, y: any = null, chartDataGroup: ChartDataGroup = ChartDataGroup.Default): ChartData {

        if (!isNullOrUndefined(x)) {

            let group = this.data.find(x => x.dataGroup === chartDataGroup);

            if (isNullOrUndefined(group)) {
                this.data.push(new ChartDataXY(null, chartDataGroup));
                group = this.data.find(x => x.dataGroup === chartDataGroup);
            }

            if (!isNullOrUndefined(group)) {
                group.appendXY(x, y);
            }
        }

        return this;
    }

    setType(newType: string): ChartData {
        this.type = newType;
        return this;
    }


    filterDataByDate(startDate: Date, endDate: Date) {

      if(this.data.length > 0) {
        return new ChartData({
          dataSource: this.dataSource,
          dataOrigin: this.dataOrigin,
          data: this.getChartDataOrderedByDate().filterByDate(startDate, endDate),
          type: this.type,
          dataGroup: this.dataGroup
        });
      }

      return this;




    }


    //update or replace data
    setNewData(chartDataGroup: ChartDataGroup = ChartDataGroup.Default){

    }
}
