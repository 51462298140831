import { Component, OnInit } from '@angular/core';


import { ApiService, ChildrenService, HelperService, UserService } from '../../shared/services';
import { ChildrenParentsUser, User } from "../../shared/models";
import { HttpParams } from "@angular/common/http";
import { DatePipe, Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import {FormControl, Validators} from '@angular/forms';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'app-handle-users',
    templateUrl: './handle-users.component.html',
    styleUrls: ['./handle-users.component.scss']
})
export class HandleUsersComponent implements OnInit {

    authedUser: User = new User(null);
    allUsers: User[] = [];
    //childrenParentsUser: ChildrenParentsUser = new ChildrenParentsUser();
    selectedParentControl: FormControl = new FormControl('', [Validators.required]);
    newParentControl: FormControl = new FormControl('', [Validators.required]);
    selectedNewChildrenControl: FormControl = new FormControl('', [Validators.required]);
    selectedChildControl: FormControl = new FormControl('', [Validators.required]);

    selectedChildrenParentsUser: ChildrenParentsUser = new ChildrenParentsUser();
    selectedChildChildrenParentsUser: ChildrenParentsUser = new ChildrenParentsUser();

    isWaitingForRegisteredUsers: boolean = false;
    isWaitingForAvailableUsers: boolean = true;
    isWaitingForAvailablePatients: boolean = true;

    constructor(
        private _apiService: ApiService,
        private _userService: UserService,
        private _datePipe: DatePipe,
        private _activeRoute: ActivatedRoute,
        private _location: Location,
        private _helperService: HelperService,
        private _childrenService: ChildrenService,
        private _dialog: MatDialog
    ) { }

    ngOnInit() {

        this._userService.getUser().then(theUser => {
            this.authedUser = theUser;
            this.getAllUsers();

            /*
            this._childrenService.getDoctorsChildren(this.authedUser.userId).then(childrenParent => {
                if (childrenParent) {
                    //this.childrenParentsUser = childrenParent;
                    this.isWaitingForRegisteredUsers = false;
                    //console.log('childrenParentsUser', this.childrenParentsUser);
                }
            });
            */
        });

    }

    getAllUsers( isForceRefresh = false ) {
      this.isWaitingForAvailableUsers = true;
      this._childrenService.getAllUsers( isForceRefresh ).then(users => {
        this.allUsers = users;
        console.log('this.allUsers', this.allUsers);
        this.isWaitingForAvailableUsers = this.isWaitingForAvailablePatients = false;
      });
    }

    backClicked() {
        this._location.back();
    }

    getCardClass(index) {
        let cardClasses = ['card-header-info', 'card-header-danger', 'card-header-success', 'card-header-warning'].reverse();

        return cardClasses[index % cardClasses.length];
    }


    /*
    getAuthedUserChildren(roleType: string): User[] {

        //roleType = 'remove this when bug is fixed';
        return this._helperService.filterUsersByRole(this.childrenParentsUser.children, roleType);
    }*/

    /*
    getAvailableUsers(roleType: string): User[] {
        //roleType = 'remove this when bug is fixed';
        return this.getAllAvailableUser(this.childrenParentsUser.children, roleType);
        //return this._helperService.filterUsersByRole( this.childrenParentsUser.currentDoctorsChildren, roleType);
    }*/


    getAvailablePatientsForSelectedDoctor(): User[] {
      return this.allUsers.filter(x => {
        return (x.isUnassigned() || x.isPatient()) && !this.selectedChildrenParentsUser.children.find(y => y.userId === x.userId);
      });
    }

    getUsersIsNotDoctor(): User[] {
      return this.allUsers.filter(x => x.isUnassigned() || x.isPatient());
    }

    getUsersIsDoctor(): User[] {
      return this.allUsers.filter(x => x.isDoctor());
    }


    addSelectedPatientsToDoctor() {
        console.log('selectedNewChildrenControl', this.selectedNewChildrenControl.value);

      if(this.selectedNewChildrenControl.valid && this.selectedNewChildrenControl.value.length > 0) {
        this._helperService.showFullScreenLoding();
        let counter = 0;
        for(const selectedUser of this.selectedNewChildrenControl.value) {
          counter++;
          if(selectedUser instanceof User){

            if(counter === this.selectedNewChildrenControl.value.length) {
              this.addChildrenToAuthedUser(selectedUser.userId, this.selectedChildrenParentsUser.user.userId);
            } else {
              this.addChildrenToAuthedUser(selectedUser.userId, this.selectedChildrenParentsUser.user.userId, false);
            }

          }
        }

      }

    }

    changedDoctor( event) {
      console.log('event selectedParentControl', this.selectedParentControl.value);
      console.log('event', event);

      if(this.selectedParentControl.valid && this.selectedParentControl.value instanceof User){
        this.updateChildrenParentsUser( this.selectedParentControl.value.userId );
      }

    }

    changedPatient( event) {
        console.log('event selectedChildControl', this.selectedChildControl.value);
        console.log('event', event);

        if(this.selectedChildControl.valid && this.selectedChildControl.value instanceof User){
          this.updateChildChildrenParentsUser( this.selectedChildControl.value.userId );
        }

      }


    private updateChildrenParentsUser( parentId ) {

      this._helperService.showFullScreenLoding();
      this._apiService.get(`/administration/${parentId}/connect`).subscribe(data => {
        this.selectedChildrenParentsUser = new ChildrenParentsUser(data);
        //this.getAllUsers(true);
        this._helperService.hideFullScreenLoading();
      }, error => {
        this._helperService.hideFullScreenLoading();
        console.log('ERROR GET /administration/userId/connect', error);
      });


    }


  private updateChildChildrenParentsUser( childId ) {

    this._helperService.showFullScreenLoding();
    this._apiService.get(`/administration/${childId}/connect`).subscribe(data => {
      this.selectedChildChildrenParentsUser = new ChildrenParentsUser(data);
      //this.getAllUsers(true);
      this._helperService.hideFullScreenLoading();
    }, error => {
      this._helperService.hideFullScreenLoading();
      console.log('ERROR GET /administration/childId/connect', error);
    });


  }


  openRemoveAnDoctorDialog(): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {message: "Are you sure you want to remove the doctor?", isConfirmation: true}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log('Yes clicked');
        this.removeAnDoctor();
        // DO SOMETHING
      } else {
        console.log('No clicked');
      }
    });
  }

   private removeAnDoctor() {
      console.log('selectedParentControl', this.selectedParentControl.value);

      if(this.selectedParentControl.valid && this.selectedParentControl.value instanceof User){
        this._helperService.showFullScreenLoding();
        this.removeDoctorRoleToUser( this.selectedParentControl.value.userId ).then(res => {
          this.removeChildrenForAuthedUser( this.selectedParentControl.value.userId, this.authedUser.userId );
        });
      }

    }




    makeAnUserDoctor() {

      console.log('newParentControl', this.newParentControl.value);

      if(this.newParentControl.valid && this.newParentControl.value instanceof User){
        this._helperService.showFullScreenLoding();
        this.addDoctorRoleToUser( this.newParentControl.value.userId ).then(res => {
          this.addChildrenToAuthedUser( this.newParentControl.value.userId, this.authedUser.userId );
        });
      }

    }

    private addDoctorRoleToUser(childUserId: string, roleId: string = '102daaac725441fdb972fff0826fcd10'): Promise<any> {
      return new Promise((resolve, reject) => {

        const httpParams = new HttpParams().set('roleId', roleId);
        this._apiService.post(`/administration/${childUserId}/role`, {}, httpParams).subscribe(data => {

          console.log("PostRoleData", data);
          resolve(data);

        }, error => {
          console.log("error POST /administration/userId/role", error);
          reject(error);
        });

      });

    }

    private removeDoctorRoleToUser(childUserId: string, roleId: string = '102daaac725441fdb972fff0826fcd10'): Promise<any> {
      return new Promise((resolve, reject) => {

        const httpParams = new HttpParams().set('roleId', roleId);
        this._apiService.delete(`/administration/${childUserId}/role`, httpParams).subscribe(data => {

          console.log("DeleteRoleData", data);
          resolve(data);

        }, error => {
          console.log("error POST /administration/userId/role", error);
          reject(error);
        });

      });

    }

    private addChildrenToAuthedUser(childUserId, parentUserId, refreshHolders = true) {

      this._helperService.showFullScreenLoding();

      const httpParams = new HttpParams().set('connectedUserId', childUserId);
      this._apiService.post(`/administration/${parentUserId}/connect`, {}, httpParams).subscribe(data => {

          console.log("POSTdata", data);
          if( refreshHolders ) {
            this.updateChildrenParentsUser( parentUserId );
            //this.childrenParentsUser = childrenParent;
            this.getAllUsers(true);
            this._helperService.hideFullScreenLoading();
          }

      }, error => {
        console.log("error POST /administration/userId/connect", error);
        this._helperService.hideFullScreenLoading();
      });

    }


    removeChildrenForAuthedUser(childUserId, parentUserId, direction = 'child') {

      this._helperService.showFullScreenLoding();

      const httpParams = new HttpParams()
        .set('connectedUserId', childUserId)
        .set('direction', direction);
      this._apiService.delete(`/administration/${parentUserId}/connect`, httpParams).subscribe(data => {

          //this.childrenParentsUser = childrenParent;
          this.getAllUsers(true);
          this.updateChildrenParentsUser( parentUserId );

      }, error => {
        console.log("error DELETE /administration/userId/connect", error);
        this._helperService.hideFullScreenLoading();
      });

    }

    /*
    private getAllAvailableUser(childrenToFilterAway: User[], roleType: string): User[] {


        return this.allUsers.filter(u => {

            return u.isHaveEssentialUserCredential() && !childrenToFilterAway.some(c => {
                const isSameUserID = c.userId === u.userId;

                if (roleType == 'Doctor') {
                    return isSameUserID && c.isDoctor();
                } else if (roleType == 'Patient') {
                    return isSameUserID && c.isPatient();
                } else {
                    return isSameUserID
                }

            });

        });

    }
    */


}
