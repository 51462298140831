import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs/Rx";
import {
  ChartData,
  ChartDataHolder, Chatbot,
  ChatbotSummary,
  ChildrenParentsUser,
  DetailedChartData,
  HealthSchedule,
  MeasureSummary,
  User
} from '../models';
import { HttpParams } from "@angular/common/http";
import { ApiService } from "./api.service";
import { DatePipe } from "@angular/common";
import { HelperService } from "./helper.service";
import * as moment from 'moment';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})

export class ChatbotService {

  private chatbotSummaryDataSubject: BehaviorSubject<ChatbotSummary[]> = new BehaviorSubject<ChatbotSummary[]>([]);
  private chatbotSummaryData: Observable<ChatbotSummary[]> = this.chatbotSummaryDataSubject.asObservable();

  private currentDoctorsChildrenSubject: BehaviorSubject<ChildrenParentsUser> = new BehaviorSubject<ChildrenParentsUser>(null);
  private currentDoctorsChildren: Observable<ChildrenParentsUser> = this.currentDoctorsChildrenSubject.asObservable();

  constructor(
    private _apiService: ApiService,
    private _datePipe: DatePipe,
    private _helperService: HelperService
  ) { }


  /*


  getChildrenDetailedChartData(childUserId: string, searchDate: string, measures: string[]): Promise<DetailedChartData[]> {
    return new Promise((resolve) => {
      if (this.childrenDetailedChartData) {

        // check if new measurements in request
        let measureIsInArr = false;
        for (const measure of measures) {
          if (this.childrenDetailedChartData.find(x => x.typeOfMeasurement === measure && x.dateString === searchDate)) {
            measureIsInArr = true;
          }
        }

        if (measureIsInArr) {
          resolve(this.childrenDetailedChartData);
        } else {
          this.refreshChildDetailedChartData(childUserId, searchDate, measures, resolve);
        }


      } else if (childUserId && searchDate && measures) {
        this.refreshChildDetailedChartData(childUserId, searchDate, measures, resolve);
      } else {
        resolve([]);
      }
    });
  }

  */

  listenChatbot(): Observable<ChatbotSummary[]> {
    return this.chatbotSummaryData;
  }


  getDoctorsChildrenChatBotData(parentId: string, forceRefresh = false ): Promise<ChatbotSummary[]> {

    return new Promise((resolve) => {

      this.getDoctorsChildren( parentId ).then((childParentUser: ChildrenParentsUser) => {

        let numberOfHandledChildren = 0;

        if( childParentUser.children.length > 0 ) {

          for(let child of childParentUser.children) {
            this.getChildrenChatbotData( child, forceRefresh ).then(x => {
              numberOfHandledChildren++;

              if(numberOfHandledChildren === childParentUser.children.length) {
                resolve(this.chatbotSummaryDataSubject.getValue());
              }
            });
          }

        } else {
          resolve([]);
        }

      }, reject => {
        resolve([]);
      });

    });

  }


  getChildrenChatbotData(childUser: User, forceRefresh = false ): Promise<ChatbotSummary[]> {
    return new Promise((resolve) => {

      if (this.chatbotSummaryDataSubject.getValue().length > 0) {

        // if child user already in array return all children chart data holders
        //console.log('this.chatbotSummaryDataSubject.getValue().find(x => x.user.userId === childUser.userId)', this.chatbotSummaryDataSubject.getValue().find(x => x.user.userId === childUser.userId));
        //console.log('childUser.userId)', childUser.getFullName());

        if ( this.chatbotSummaryDataSubject.getValue().find(x => x.user.userId === childUser.userId) && !forceRefresh ) {
          resolve(this.chatbotSummaryDataSubject.getValue());
        } else {
          this.refreshChildChatbotData(childUser, resolve);
        }

      } else {
        this.refreshChildChatbotData(childUser, resolve);
      }

    });
  }


  getChatbotData(user: User, forceRefresh = false ): Promise<ChatbotSummary[]> {
    return new Promise((resolve) => {

      if ( this.chatbotSummaryDataSubject.getValue().find(x => x.user.userId === user.userId) && !forceRefresh ) {
        resolve(this.chatbotSummaryDataSubject.getValue());

      } else {

        this._apiService.get(`/chat`).subscribe(data => {
          this.checkIfUpdatedChatbotAndAddToObservable(data.map(x => new ChatbotSummary(x, user)), resolve);
        }, error => {
          console.log('ERROR Refreshing measurement data for child', error);
          resolve([]);
        });

      }

    });
  }

  getDoctorsChildren(parentId: string, forceRefresh = false): Promise<ChildrenParentsUser> {

    return new Promise((resolve, reject) => {
      if (this.currentDoctorsChildrenSubject.getValue() && !forceRefresh) {
        resolve(this.currentDoctorsChildrenSubject.getValue());
      } else {
        this.refreshParentsChildren(parentId, resolve, reject);
      }

    });

  }

  getDoctorsChildrenSingleChat(parentId: string, childId: string, chatId: string): Promise<Chatbot[]> {

    return new Promise((resolve) => {

      this.getDoctorsChildren( parentId ).then((childParentUser: ChildrenParentsUser) => {

        const foundChild = childParentUser.children.find(x => x.userId === childId);

        if(foundChild) {

          this._apiService.get(`/administration/${childId}/chat/${chatId}`).subscribe(data => {

            let tempData = data.map(x => new Chatbot(x, foundChild));
            tempData = tempData.sort(this._helperService.sortChatbotByDate);
            resolve( tempData );

          }, error => {
            console.log('ERROR getting chatbot arr for' + foundChild.userId, error);
            resolve([]);
          });

        } else {
          resolve([]);
        }

      }, reject => {
        resolve([]);
      });

    });

  }

  getUserChatbotData(user: User, chatId: string): Promise<Chatbot[]> {

    return new Promise((resolve) => {

      this._apiService.get(`/chat/${chatId}`).subscribe(data => {

        let tempData = data.map(x => new Chatbot(x, user));
        tempData = tempData.sort(this._helperService.sortChatbotByDate);
        resolve( tempData );

      }, error => {
        console.log('ERROR getting chatbot arr for' + user.userId, error);
        resolve([]);
      });

    });


  }


  private refreshParentsChildren(parentId: string, resolve, reject): void {

    this._apiService.get(`/administration/${parentId}/connect`).subscribe(data => {

      const childrenParentsUser = new ChildrenParentsUser(data);

      if (JSON.stringify(childrenParentsUser) !== JSON.stringify(this.currentDoctorsChildrenSubject.getValue())) {
        this.currentDoctorsChildrenSubject.next(childrenParentsUser);
        resolve(this.currentDoctorsChildrenSubject.getValue());
      } else {
        reject( 'The doctors children was not updated' );
      }


    }, error => {
      console.log('ERROR GET /administration/userId/connect', error);
      reject( 'Error getting doctors children' );
    });

  }

  /*

  getAllUsers(): Promise<User[]> {
    return new Promise((resolve) => {
      if (this.allUsers) {
        resolve(this.allUsers);
      } else {
        this.refreshAllUser(resolve);
      }
    });
  }

  getChildrenLastWeekData(children: User[], isRefresh = false): Promise<ChartDataHolder[]> {

    return new Promise((resolve) => {
      if (this.childrenLastWeekChartDataSubject.getValue() && !isRefresh) {
        resolve(this.childrenLastWeekChartDataSubject.getValue());
      } else {
        this.refreshChildrenLastWeekData(children).then(x => resolve(x));
      }

    });

  }



  private refreshAllUser(resolve): void {

    this._apiService.get(`/administration/users`).subscribe(data => {
      const allUsers = data.map(x => new User(x));
      this.allUsers = allUsers;
      resolve(allUsers);
    }, error => {
      console.log('error GET /administration/users', error);
      resolve([]);
    });
  }
 */


  private refreshChildChatbotData(childUser: User, resolve): void {

    this._apiService.get(`/administration/${childUser.userId}/chat`).subscribe(data => {

      let parsed = data.map(x => new ChatbotSummary(x, childUser));

      if(parsed.length === 0) {
        parsed = [new ChatbotSummary(null, childUser).setHaveNoChat()]
      }

      this.checkIfUpdatedChatbotAndAddToObservable(parsed, resolve);
    }, error => {
      console.log('ERROR Refreshing measurement data for child', error);
      resolve([]);
    });

  }

  /*
  private refreshChildDetailedChartData(childUserId: string, searchDate: string, measures: string[], resolve): void {

    let detailedChartDataArr: DetailedChartData[] = [];

    const tempMeasures: string[] = measures;
    if (tempMeasures.length > 0) {

      // remove measures that is already fetched
      if (this.childrenDetailedChartData) {
        detailedChartDataArr = this.childrenDetailedChartData;
        for (let i = 0; i < tempMeasures.length - 1; i++) {
          if (this.childrenDetailedChartData.find(x => x.typeOfMeasurement === tempMeasures[i] && x.dateString === searchDate)) {
            tempMeasures.splice(i, 1);
          }
        }
      }
      let counter = 0;

      const parallellObservables = [];

      for (const measure of tempMeasures) {

        const httpParams = new HttpParams()
          .set('date', searchDate)
          .set('measure', measure);

        parallellObservables
          .push(
            this._apiService.get(`/administration/${childUserId}/measure/details`, httpParams)
              .pipe(
                map((res) => res),
                catchError(error => of(console.log('ERROR Refreshing daily measurement details for child', error)))
              )
          );
      }

      Observable.forkJoin(parallellObservables).subscribe(data => {

        data.forEach(x => {
          if (!isNullOrUndefined(x)) {

            const tempData = x;
            tempData['userId'] = childUserId;

            detailedChartDataArr.push(new DetailedChartData(tempData));
          }
        });

        this.childrenDetailedChartData = detailedChartDataArr;
        resolve(detailedChartDataArr);

      });

    } else if (this.childrenDetailedChartData) {
      resolve(this.childrenDetailedChartData);
    } else {
      resolve([]);
    }

  }



  private refreshChildrenLastWeekData(childrenUsers: User[]): Promise<ChartDataHolder[]> {

    const childrenLastWeekPromise: Promise<ChartDataHolder[]> = new Promise((resolve) => {

      const childrenLastWeekArr: ChartDataHolder[] = (this.childrenLastWeekChartDataSubject.getValue()) ? this.childrenLastWeekChartDataSubject.getValue() : [];
      let counter = 0;

      for (const childrenUser of childrenUsers) {

        this._apiService.get(`/administration/${childrenUser.userId}/measure/summary?summaryType=LastWeek`).subscribe(data => {

          ++counter;

          // console.log('childrenUser', childrenUser);

          if (data) {

            const tempDataBody = {
              ChartData: data,
              user: childrenUser
            };

            const healthSchedule = new ChartDataHolder(tempDataBody);

            // console.log('healthSchedule', healthSchedule);
            const foundIndex = this._helperService.isInArray(healthSchedule, this.childrenLastWeekChartDataSubject.getValue());

            if (foundIndex) {
              childrenLastWeekArr.splice(foundIndex, 1);
            }

            childrenLastWeekArr.push(healthSchedule);

          }

          if (counter === childrenUsers.length) {
            resolve(childrenLastWeekArr);
          }

        }, error => {
          //console.log("GET /administration/{userId}/measure/summary error", error);

          ++counter;

          if (counter === childrenUsers.length) {
            resolve(childrenLastWeekArr);
          }
        });

      }

    });

    return childrenLastWeekPromise.then(x => {
      this.childrenLastWeekChartDataSubject.next(x);
      return x;
    });

  }
  */


  private checkIfUpdatedChatbotAndAddToObservable(childChartData: ChatbotSummary[], resolve) {

    let currentChartDataArr: ChatbotSummary[] = this.chatbotSummaryDataSubject.getValue();

    let foundIndexs = this._helperService.isInArrayByParam(childChartData, currentChartDataArr, 'chatId');

    if (foundIndexs.length > 0) {
      for(let foundIndex of foundIndexs) {
        currentChartDataArr.splice(foundIndex, 1);
      }
    }

    currentChartDataArr = currentChartDataArr.concat(childChartData);

    currentChartDataArr = currentChartDataArr.sort(this._helperService.sortChatbotSummaryByDate);

    this.chatbotSummaryDataSubject.next(currentChartDataArr);
    resolve(currentChartDataArr);

  }

}
