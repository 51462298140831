import {MeasureChildSummaryValue} from "./measure-child-summary-value.model";

export class MeasureChildSummary {
    totalMeasurePoints: string;
    totalMeasureGroups: string;
    measureGroups: MeasureChildSummaryValue[];

    constructor(chartObj: any = null) {
        this.totalMeasurePoints = (chartObj && chartObj['totalMeasurePoints']) ? chartObj.totalMeasurePoints : '';
        this.totalMeasureGroups = (chartObj && chartObj['totalMeasureGroups']) ? chartObj.totalMeasureGroups : '';
        this.measureGroups = (chartObj && chartObj['measureGroups']) ? chartObj.measureGroups.map(x => new MeasureChildSummaryValue(x)) : [];
    }

    isValid() : boolean {
        return this.totalMeasurePoints !== '' && this.totalMeasureGroups !== '' && this.measureGroups.length > 0;
    }

}
