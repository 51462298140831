import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {DashboardComponent} from "./dashboard.component";
import {AuthGuard} from "../../shared/guards";

const dashboardRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
        DashboardComponent,
    ],
    imports: [
        SharedModule,
        dashboardRouting,
    ],
})

export class DashboardModule {}
