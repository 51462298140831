import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OauthService {
  
  authCallbackUrl = location.origin + this._location.prepareExternalUrl('auth-callback/');
  authCallbackSubUrl = location.origin + this._location.prepareExternalUrl('auth-callback/');

  constructor(
    private _router: Router,
    private _location: Location) { }

  logout() {
    let au = 'https://cgisehealth.b2clogin.com/cgisehealth.onmicrosoft.com/oauth2/v2.0/logout?p=b2c_1_health_backend_login';
    au += '&post_logout_redirect_uri=' + encodeURIComponent(location.origin);

    this._router.ngOnDestroy();
    setTimeout(() => {
      window.location.href = au;
    }, 1);
  }

  loginRedirect() {
    let au = 'https://cgisehealth.b2clogin.com/cgisehealth.onmicrosoft.com/b2c_1_health_backend_login/oauth2/v2.0/authorize?';
    au += 'client_id=5ed13716-77b8-4d1e-8bf3-b5f806b881eb';
    au += '&response_type=id_token+token';
    au += '&response_mode=fragment';
    au += '&redirect_uri=' + encodeURIComponent(this.authCallbackUrl); // heartapp://heartapp.cgi.com/callback&';
    au += '&scope=';
    au += encodeURIComponent('offline_access openid https://cgisehealth.onmicrosoft.com/healthbackend/user_impersonation');
    au += '&state=oauth2';
    au += '&nonce=12345';
    au += '&p=B2C_1_health_backend_login';

    window.location.href = au;
  }
}
