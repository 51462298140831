import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import * as Chartist from 'chartist';
import 'chartist-plugin-tooltips';

import * as moment from 'moment';

import $ from 'jquery';

import {
    ApiService,
    ChartDataService,
    ChildrenService,
    HelperService,
    UserService
} from '../../shared/services';
import {
    ChartData, ChartDataHolder,
    ChartDataXY,
    ChildrenParentsUser, DetailedChartData,
    MeasureChildSummary,
    User
} from '../../shared/models';
import { HttpParams } from '@angular/common/http';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { ModalService } from '../../shared/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { from, Observable, of, BehaviorSubject } from 'rxjs';
import { isUndefined, isNullOrUndefined } from 'util';
import { TranslatePipe } from 'heart-app-shared-pipes';

interface SimData {
    type: string;
    descr: string;
}

@Component({
    selector: 'app-patient-summaries',
    templateUrl: './patient-summaries.component.html',
    styleUrls: ['./patient-summaries.component.scss']
})
export class PatientSummariesComponent implements OnInit, AfterViewInit {

    user: User = new User(null);

    currentChildUserId = '';

    childrenChartData: ChartDataHolder[] = [];
    targetChildrenChartData: ChartDataHolder = null;
    isWaitingForChartData = true;

    notificationForm: FormGroup;
    notificationResponseMessage: object = { message: '', isError: false };

    siteLanguage = '';

    isHaveNoData = false;

    simulations: Array<SimData> = [
        { type: '1', descr: 'simulering 1' },
        { type: '2', descr: 'simulering 2' },
        { type: '3', descr: 'simulering 3' },
        { type: '4', descr: 'simulering 4' },
        { type: 'schema_updated', descr: 'Schemat har ändrats av Din doktor' },
        { type: 'new_activity_registration', descr: 'Det finns nya registreringar' },

    ];

    graphs: any[] = [];

    constructor(
        private _apiService: ApiService,
        private _userService: UserService,
        private _datePipe: DatePipe,
        private _router: Router,
        private _chartDataService: ChartDataService,
        private _childrenChartDataService: ChildrenService,
        private _helperService: HelperService,
        private _modalService: ModalService,
        private _formBuilder: FormBuilder,
        private _activeRoute: ActivatedRoute,
        private _location: Location
    ) {

        moment.updateLocale(moment().locale(), {
            week: {
                dow: 1, // First day of week is Monday
                doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
            }
        });
    }

    ngOnInit() {

        this._userService.getUser().then(theUser => {
            this.user = theUser;

            if (this.user.isDoctor()) {

                this.handleHttpParams();

                this._childrenChartDataService.getDoctorsChildrenChartData(this.user.userId).then(childrenChartData => {

                    this.childrenChartData = childrenChartData;

                    const currentChild = this.childrenChartData.find(x => x.user.userId === this.currentChildUserId);
                    if (currentChild) {
                        this.targetChildrenChartData = currentChild;
                        this.generatePlotlys();
                    } else {
                        this.isHaveNoData = true;
                    }


                    this.isWaitingForChartData = false;

                });
            }
        });

        this._helperService.language.subscribe(lang => this.siteLanguage = lang);

        this.notificationForm = this._formBuilder.group({
            userEmail: this._formBuilder.control({ value: '', disabled: true }, [Validators.required]),
            userId: this._formBuilder.control({ value: '', disabled: true }, [Validators.required]),
            message: ['', [Validators.required]]
        });

    }

    handleHttpParams() {

        this._activeRoute.queryParams.subscribe(params => {

            if (params.userId) {
                this.currentChildUserId = params.userId;

                // this.getChildSummaries( this.currentChildUserId );
            }
        });

    }


    ngAfterViewInit() {

    }

    runSimulation(data: SimData) {

        this._helperService.showFullScreenLoding();

        const body = {
            message: data.descr,
            additionalData: encodeURIComponent(JSON.stringify(data))
        };

        this._apiService.post(`/notify/connected/${this.currentChildUserId}`, body).subscribe(res => {

            this._helperService.hideFullScreenLoading();
            console.log('res', res);

            this.notificationResponseMessage = {
                message: res, // 'Successfully sent',
                isError: false
            };

        }, err => {

            this._helperService.hideFullScreenLoading();

            this.notificationResponseMessage = {
                message: 'Something went wrong',
                isError: true
            };

            console.log('err', err);


        });
    }



    handlePlotlyClickEvent(event: any) {

        if (event && event.points && event.points.length > 0) {
            const clickedPoint = event.points[0];
            const x = clickedPoint.x;
            const y = clickedPoint.y;
        }
    }

    private generatePlotlys() {

        this.graphs = new Array<any>();
        const translatePipe = new TranslatePipe();

        // build Plotly GRAPH


        for (const chartData of this.targetChildrenChartData.mappedData) {

            this.graphs.push({
                data: [
                    {
                        name: translatePipe.transform(chartData.type),
                        x: chartData.getChartDataOrderedByDate().x,
                        y: chartData.getChartDataOrderedByDate().y,
                        type: 'scatter',
                        mode: 'lines+markers',
                        connectgaps: true,
                        line: {
                            color: '#749BB6',
                            width: 4,
                            shape: 'spline',
                            smoothing: '0.5',
                            // color: rgb(91, 175, 234),
                            simplify: true,
                        },
                        marker: {
                            size: 10
                        }
                    }
                ],
                layout: {
                    height: 350,
                    autosize: true,
                    title: {
                        text: translatePipe.transform(chartData.type),
                        x: 0.01,
                        font: {
                            size: 20,
                            color: 'rgba(0, 0, 0, 0.9)', // override title font color

                        }
                    },
                    font: { // global font settings
                        family: '\'Muli\', \'Helvetica\', Open Sans, verdana, arial, sans-serif',
                        color: 'rgba(0, 0, 0, 0.6)',
                    },
                    /*xaxis: {
                        tickformat: 'Vecka %-U',
                    }*/
                },
                config: {
                    // showLink: true,
                    showSendToCloud: true,
                    displaylogo: false,
                    locale: 'sv'
                }
            });
        }
    }



    /*
    getChildSummaries( currentChildUserId ) {

        this._apiService.get(`/administration/${currentChildUserId}/measure/summary?summaryType=LastWeek`).subscribe( summaries => {
            this.isWaitingForAvgValues = false;
            this.measureChildSummaries = new MeasureChildSummary( summaries );
        }, error => {
            this.isWaitingForAvgValues = false;
        });

    }



    getCardClass( index ) {
        let cardClasses = ['card-header-info', 'card-header-danger', 'card-header-success', 'card-header-warning'].reverse();

        return cardClasses[index % cardClasses.length];
    }

    */

    redirectToDetailed(index, userId = null) {

        if (userId) {
            this._router.navigateByUrl('/detailed-chart?index=' + index + '&userId=' + userId);
        } else {
            this._router.navigateByUrl('/detailed-chart?index=' + index);
        }

    }


    openNotificationForm(user: User) {
        user['message'] = '';
        this.notificationForm.patchValue(user);
        this.notificationResponseMessage['message'] = '';
        this._modalService.open('notify-modal-1');
    }



    backClicked() {
        this._location.back();
    }

    closeModal(id: string) {
        this._modalService.close(id);
    }


    sendNotification() {

        const form = this.notificationForm;

        if (form.valid && this.user.isDoctor()) {

            this._helperService.showFullScreenLoding();

            const simData: SimData = {
              type: 'custom',
              descr: 'Message from your doctor - ' + this.user.getFullName(),
            };

            const body = {
              message: form.controls.message.value,
              additionalData: encodeURIComponent(JSON.stringify(simData))
            };

            this._apiService.post(`/notify/connected/${this.currentChildUserId}`, body).subscribe(res => {

                this._helperService.hideFullScreenLoading();
                console.log('res', res);

                this.notificationResponseMessage = {
                    message: res, // 'Successfully sent',
                    isError: false
                };

            }, err => {

                this._helperService.hideFullScreenLoading();

                this.notificationResponseMessage = {
                    message: 'Something went wrong',
                    isError: true
                };

                console.log('err', err);


            });

        }

    }

  /*
    private doChartGrouping(data: ChartData[]) {

        const groupedChartData: ChartData[] = [];

        let groups: any; // Array<any> = [];



        // calculate nr of weeks in timespan
        for (const d of data) {

            const xyData = d.getChartData().getXyAsArray();

            groups = xyData.reduce((acc, xy) => {

                const yearWeek = moment(xy.x).year() + '-' + moment(xy.x).week();

                // check if the week number exists
                if (isNullOrUndefined(acc[yearWeek])) {
                    acc[yearWeek] = [];
                }

                acc[yearWeek].push(xy);

                return acc;

            }, {});

            const newGroupedData = new ChartData().setType(d.type);

            if (groups) {
                Object.getOwnPropertyNames(groups).forEach(x => {

                    const firstDate = groups[x][0].x;
                    const weekAvg = groups[x].reduce((total, c) => {
                        return total + c.y;
                    }, 0.0) / groups[x].length;

                    newGroupedData.appendXY(firstDate, weekAvg);

                });
            }
            groupedChartData.push(newGroupedData);
        }

        // const nrOfGroups = 6;

        // for (const d of data) {
        //     const tempD = d;
        //     tempD.getData().x = this.chunkify(d.getData().x, nrOfGroups, true).map(x => {
        //         const dateArr = x.map(y => new Date(y));
        //         const avgDate = dateArr.reduce((p, c) => p + c.getTime(), 0) / dateArr.length;
        //         const maxDate = Math.max.apply(null, dateArr);
        //         return new Date(maxDate);
        //     });

        //     tempD.getData().y = this.chunkify(d.getData().y, nrOfGroups, true).map(x => x.reduce((p, c) => p + c, 0) / x.length);
        //     groupedChartData.push(tempD);
        // }

        return groupedChartData;
    }
*/



    private chunkify(a: any[], n: number, balanced: boolean): Array<any[]> {

        if (n < 2) {
            return [a];
        }

        const len = a.length;
        const out = [];
        let i = 0;
        let size: number;

        if (len % n === 0) {
            size = Math.floor(len / n);
            while (i < len) {
                out.push(a.slice(i, i += size));
            }
        } else if (balanced) {
            while (i < len) {
                size = Math.ceil((len - i) / n--);
                out.push(a.slice(i, i += size));
            }
        } else {

            n--;
            size = Math.floor(len / n);
            if (len % size === 0) {
                size--;
            }
            while (i < size * n) {
                out.push(a.slice(i, i += size));
            }
            out.push(a.slice(size * n));

        }

        return out.reverse();
    }


    private makeXYMappedArr(data: ChartDataXY): { x: Date, y: number }[] {

        const Xarr = data.x;
        const Yarr = data.y;
        const tempArr = [];
        for (const key in Xarr) {
            tempArr.push({
                x: Xarr[key],
                y: Yarr[key]
            });
        }
        return tempArr.sort((a, b) => {
            if (moment(a.x) < moment(b.x)) {
                return -1;
            } else {
                return 1;
            }
        });
    }

}
