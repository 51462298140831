
export class AccessToken {
    access_token: string;
    expires_on: number;
    id_token: string;
    not_before: number;
    profile_info: string;
    refresh_token: string;
    refresh_token_expires_in: number;
    resource: string;
    token_type: string;

    constructor(tokenObj: any = null) {

        this.access_token = (tokenObj && tokenObj['access_token']) ? tokenObj.access_token : '';
        this.expires_on = (tokenObj && tokenObj['expires_on']) ? +tokenObj.expires_on : +0;
        this.id_token = (tokenObj && tokenObj['id_token']) ? tokenObj.id_token : '';
        this.not_before = (tokenObj && tokenObj['not_before']) ? +tokenObj.not_before : 0;
        this.profile_info = (tokenObj && tokenObj['profile_info']) ? tokenObj.profile_info : '';
        this.refresh_token = (tokenObj && tokenObj['refresh_token']) ? tokenObj.refresh_token : '';
        this.refresh_token_expires_in = (tokenObj && tokenObj['refresh_token_expires_in']) ? tokenObj.refresh_token_expires_in : '';
        this.resource = (tokenObj && tokenObj['resource']) ? tokenObj.resource : '';
        this.token_type = (tokenObj && tokenObj['token_type']) ? tokenObj.token_type : '';

    }

}
