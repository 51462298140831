import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Rx';
import { ApiService, HelperService, OauthService, StorageService, UserService } from '../../shared/services';
import {AccessToken} from "../../shared/models";

@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent {


    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _oauthService: OauthService,
        private _userService: UserService,
        private _storage: StorageService,
        private _helperService: HelperService
    ) {


        this._route.fragment.subscribe(fragment => {

            const params = fragment
                .split('&')
                .map(v => v.split('='))
                .reduce((map, [key, value]) => map.set(key, decodeURIComponent(value)), new Map());

            if (params) {
                const parsedToObject = this._helperService.paramsMapToObject( params );
                if (parsedToObject) {
                    const accessToken = new AccessToken(parsedToObject);

                    this._storage.saveToken(accessToken).then( () => {

                      this._userService.getUser()
                        .then(x => {
                          console.log('callX', x);
                          this._router.navigateByUrl('/');
                        })
                        .catch(e2 => {
                          console.log('callE', e2);
                          this._router.navigateByUrl('/');
                        });

                    });




                    // },
                    //     error => {
                    //         console.log('error', error);
                    //         this._router.navigateByUrl('/');
                    //     });

                }

            } else {

                this._router.navigateByUrl('/');

            }

        });

    }


}
