import {HealthScheduleAction} from './health-schedule-action.model';

export class HealthDailySchedule {
  id: string;
  medicines: HealthScheduleAction[];
  measurements: HealthScheduleAction[];
  other: HealthScheduleAction[];

    constructor(chartObj: any = null) {
      this.id = (chartObj && chartObj['id']) ? chartObj.id : '';
      this.medicines = (chartObj && chartObj['medicines']) ? chartObj.medicines.map(x => new HealthScheduleAction(x, 'medicine')) : [];
      this.measurements = (chartObj && chartObj['measurements']) ? chartObj.measurements.map(x => new HealthScheduleAction(x, 'measurement')) : [];
      this.other = (chartObj && chartObj['other']) ? chartObj.other.map(x => new HealthScheduleAction(x, 'other')) : [];
    }


  isHaveActions(): boolean {
      return this.medicines.length > 0 || this.measurements.length > 0 || this.other.length > 0;
  }
}
