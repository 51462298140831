import { Component, OnInit } from '@angular/core';
import { ChildrenParentsUser, User } from "../../shared/models";
import { ApiService, UserService } from "../../shared/services";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

    user: User = new User();

    constructor(
        private _apiService: ApiService,
        private _userService: UserService,
        private _formBuilder: FormBuilder
    ) { }

    ngOnInit() {

        this._userService.getUser().then(theUser => {
            this.user = theUser;
        });

    }

}
