import {ChartDataGroup} from './chart-data-group.model';

export class DataXy {
    x: Date;
    y: any;
    dataGroup: ChartDataGroup;

    constructor(x: Date, y: any, dataGroup: ChartDataGroup) {
        this.x = x;
        this.y = y;
        this.dataGroup = dataGroup;
    }

}
