import { Injectable, OnInit } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad, Route,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import 'rxjs/add/operator/take';
import { User } from '../models';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { Location, PlatformLocation } from '@angular/common';
import { ApiService, StorageService, UserService } from '../services';


@Injectable({
    providedIn: 'root',
})
export class NotShowWhenAuthedGuard implements CanActivate {

    constructor(
        private _userService: UserService,
        private _apiService: ApiService,
        private _router: Router,
        private _storageService: StorageService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        const token = await this._storageService.getToken();
        const user = await this._userService.getUser();
        if (token && user.isDoctor()) {
            this._router.navigateByUrl('/dashboard-supervisor');
            return false;
        } else if (token && user.isPatient()) {
            this._router.navigateByUrl('/dashboard');
            return false;
        } else if (token && (user.isMissingUserValues() || !user.isHaveRoll())) {
            this._router.navigateByUrl('/registration');
            return false;
        } else if (token && user.isUnassigned()) {
            this._router.navigateByUrl('/info');
            return false;
        } else {
            return true;
        }
    }

}
