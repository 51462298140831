import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {ChatbotComponent} from "./chatbot.component";
import {AuthGuard} from "../../shared/guards";
import { PlotlyModule } from 'angular-plotly.js';

const chatbotRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'chatbot',
        component: ChatbotComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
        ChatbotComponent,
    ],
    imports: [
        SharedModule,
        chatbotRouting
    ],
})

export class ChatbotModule {}
