export class ModalService {
    private modals: any[] = [];

    add(modal: any) {
        // add modal to array of active modals
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string, externalUrl: string = null) : Promise<any> {
        // open modal specified by id
        let modal: any[] = this.modals.filter(x => x.id === id);

        if(modal.length > 0) {
            return modal[0].open( externalUrl )
        } else {
            return new Promise((resolve, reject) => {reject('Could not find container id')});
        }
    }

    close(id: string) {
        // close modal specified by id
        let modal: any = this.modals.filter(x => x.id === id)[0];
        modal.close();
    }
}