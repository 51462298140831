import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {HandleScheduleComponent} from "./handle-schedule.component";
import {AuthSupervisorGuard} from '../../shared/guards';

const handleScheduleRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'handle-schedule',
        component: HandleScheduleComponent,
        canActivate: [AuthSupervisorGuard]
    }
]);

@NgModule({
    declarations: [
      HandleScheduleComponent,
    ],
    imports: [
      SharedModule,
      handleScheduleRouting,
    ],
})

export class HandleScheduleModule {}
