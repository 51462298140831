import {Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';

import {
  ApiService,
  ChartDataService,
  ChildrenService,
  HelperService, ScheduleService,
  UserService
} from '../../shared/services';
import {
  ChildrenParentsUser, HealthDailySchedule,
  HealthSchedule,
  User
} from '../../shared/models';
import {DatePipe} from "@angular/common";
import {ActivatedRoute, Router} from '@angular/router';
import {ModalService} from "../../shared/modal";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

    @ViewChildren('lineCharts') lineCharts: QueryList<ElementRef>;
    @ViewChildren('statusChildrenCharts') statusChildrenCharts: QueryList<ElementRef>;

    user: User = new User(null);

    userSchedule: HealthSchedule = new HealthSchedule();
    isWaitingForSchema: boolean = true;
    siteLanguage : string = '';
    weeklyDailiesArr: {day: string, value: HealthDailySchedule}[] = [];
    isDoctor: boolean = false;

    childrenParentsUser: ChildrenParentsUser = new ChildrenParentsUser();
    childrenSchedules: HealthSchedule[] = [];
    targetChildSchedule: HealthSchedule = null;

    currentScheduleUserId: string = '';

    constructor(
      private _apiService: ApiService,
      private _userService: UserService,
      private _datePipe: DatePipe,
      private _router: Router,
      private _chartDataService: ChartDataService,
      private _childrenChartDataService: ChildrenService,
      private _helperService: HelperService,
      private _scheduleService: ScheduleService,
      private _modalService: ModalService,
      private _formBuilder: FormBuilder,
      private _activeRoute: ActivatedRoute) { }

    ngOnInit() {

        this._userService.getUser().then(theUser => {
            this.user = theUser;

            if(this.user.isDoctor()) {
              this.isDoctor = true;
              this.handleHttpParams();
              this.getChildrenWithFetchedSchedules();
              //this.getAuthedUserChildrenAndParentsData();
            } else {
              this.getPatientSchemaData();
            }

        });

        this._helperService.language.subscribe( lang => this.siteLanguage = lang);

    }

    handleHttpParams() {

      this._activeRoute.queryParams.subscribe(params => {

        if( params['userId'] ) {
          this.currentScheduleUserId = params['userId'];
        }

      });
    }

    private getChildrenWithFetchedSchedules() {

      this._childrenChartDataService.getDoctorsChildren( this.user.userId ).then(children => {

        if( children ) {
          this.childrenParentsUser = children;
          this._scheduleService.getSupervisorSchedule( children.children ).then( childrenSchedules => {
            this.targetChildSchedule = childrenSchedules.find(x => x.user.userId === this.currentScheduleUserId);

            if(this.targetChildSchedule) {
              this.weeklyDailiesArr = this.setWeeklyDailies( this.targetChildSchedule );
              this.childrenSchedules = childrenSchedules;
              this.isWaitingForSchema = false;
            } else {
              this.getPatientSchemaData();
            }

          });
        }

      });

    }

    private getPatientSchemaData() {

        this._scheduleService.getSchedule().then(schedule => {

          this.userSchedule = schedule;

          const parsed = this._scheduleService.getHealthDailySchedule( schedule );
          console.log('parsed', parsed);


          this.weeklyDailiesArr = this.setWeeklyDailies( schedule );
          this.isWaitingForSchema = false;
          console.log('this.userSchedule', this.userSchedule);

        });

    }


    private setWeeklyDailies(schedules: HealthSchedule): {day: string, value: HealthDailySchedule}[] {

      const healthDailyArr = [];

      for(const param in schedules){
        const value = schedules[param];

        if(value instanceof HealthDailySchedule) {
          healthDailyArr.push({
            day: param,
            value: value
          });
        }
      }

      console.log('healthDailyArr', healthDailyArr);

      return healthDailyArr;

    }


    stringifyObj(weeklyDailiesValue: HealthDailySchedule): string {
        return JSON.stringify( weeklyDailiesValue.measurements );
    }


    getIconColor(index) {
      let cardClasses = ['icon-primary', 'icon-info', 'icon-success', 'icon-warning', 'icon-danger']
      return cardClasses[index % cardClasses.length];
    }
}
