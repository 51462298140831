import { Component, OnInit } from '@angular/core';
import {ChildrenParentsUser, User} from "../../shared/models";
import {ApiService, HelperService, UserService} from '../../shared/services';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-manual-data',
  templateUrl: './manual-data.component.html',
  styleUrls: ['./manual-data.component.scss']
})
export class ManualDataComponent implements OnInit {

    user: User = new User();
    childrenParentsUser: ChildrenParentsUser = new ChildrenParentsUser();
    isWaitingForRegisteredUsers: boolean = true;
    manualMeasureForm: FormGroup;
    nyhaClassificationForm: FormGroup;

    responseMessage: {class: string, message: string} = {class: '', message: ''};

    nowTime: Date = new Date();

    validMeasureTypes: string[] = [
        'NyhaClassification',
        'WaterMass',
        'EKG',
        'Height',
        'WeightKg',
        'FatfreeMassKg',
        'FatRatio',
        'FatMassWeightKg',
        'MuscleMass',
        'Hydration',
        'BoneMass',
        'PulseWaveVelocity',
        'PulseBPM',
        'PulseOx',
        'StressLevel',
        'ConsumedCalories',
        'BmrKiloCalories',
        'DistanceInMeters',
        'Steps'
    ];


    constructor(
      private _apiService: ApiService,
      private _userService: UserService,
      private _formBuilder: FormBuilder,
      private _helperService: HelperService
  ) { }

  ngOnInit() {

      this._userService.getUser().then(theUser => {
          this.user = theUser;
      });

      this.manualMeasureForm = this._formBuilder.group({
        typeOfMeasure: this._formBuilder.control('', [Validators.required]),
        inputValue: this._formBuilder.control({value: '', disabled: true}, [Validators.required]),
        measureDate: this._formBuilder.control(this.nowTime.toISOString(), [Validators.required]),
        measureTime: this._formBuilder.control(this._helperService.getTimeString(this.nowTime), [Validators.required, Validators.pattern('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')]),
      });


      this.nyhaClassificationForm = this._formBuilder.group({
        typeOfMeasure: this._formBuilder.control('NyhaClassification', [Validators.required]),
        inputValue: this._formBuilder.control('', [Validators.required, Validators.min(1), Validators.max(4)])
      });

  }

  doValueFieldEnable() {

      if(this.manualMeasureForm.controls['typeOfMeasure'].valid) {
        this.manualMeasureForm.controls['inputValue'].enable();
      } else {
        this.manualMeasureForm.controls['inputValue'].disable();
      }
  }

    addManualData( form: FormGroup ) {

      if( form && form.valid ) {

          let measureDateTime = new Date();

          if(form.controls['typeOfMeasure'].value !== 'NyhaClassification') {
            measureDateTime = new Date( form.controls['measureDate'].value );

            const timeArr = form.controls['measureTime'].value.split(':');
            measureDateTime.setHours( timeArr[0] );
            measureDateTime.setMinutes( timeArr[1] );
            measureDateTime.setSeconds( 0 );

          }

          const body = {
            typeOfMeasure: form.controls['typeOfMeasure'].value,
            value: form.controls['inputValue'].value,
            dateTimeOfMeasure: measureDateTime.toISOString(),
            localOffsetInSeconds: 0
          };


            this.responseMessage = {class: '', message: ''};

            this._apiService.post(`/measure`, body).subscribe(data => {

                console.log("data", data);
                this.responseMessage.message = 'Measure sent successfully';
                this.responseMessage.class = 'btn-success';

            }, error => {
                this.responseMessage.message = 'Something went wrong';
                this.responseMessage.class = 'btn-danger';
                console.log("error GET /measure", error)
            });

        }
    }


}
