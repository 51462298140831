import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs/Rx";
import {HeartNotification, User} from '../models';
import {ApiService} from "./api.service";
import {HelperService} from "./helper.service";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    private notificationSubject: BehaviorSubject<HeartNotification[]> = new BehaviorSubject<HeartNotification[]>( null );
    private notification: Observable<HeartNotification[]> = this.notificationSubject.asObservable();

    private supervisorNotificationsSubject: BehaviorSubject<HeartNotification[]> = new BehaviorSubject<HeartNotification[]>( null );
    private supervisorNotifications: Observable<HeartNotification[]> = this.supervisorNotificationsSubject.asObservable();

    constructor (
        private _apiService: ApiService,
        private _helperService: HelperService
    ) {}


    listenNotifications(): Observable<HeartNotification[]> {
      return this.notification;
    }

    listenSupervisorNotifications(): Observable<HeartNotification[]> {
      return this.supervisorNotifications;
    }

    getCurrentUserNotifications(isUpdate = false ): Promise<HeartNotification[]> {

      return new Promise((resolve) => {

        if( this.notificationSubject.getValue() && !isUpdate ) {
          resolve( this.notificationSubject.getValue() )
        } else {
          this.refreshNotificationsData().then(notifications => resolve( notifications ), error => resolve( [] ) );
        }
      });

    }


    getSupervisorNotifications(childrenUsers: User[], isUpdate = false ): Promise<HeartNotification[]> {

      return new Promise((resolve) => {

        if( this.supervisorNotificationsSubject.getValue() && !isUpdate ) {
          resolve( this.supervisorNotificationsSubject.getValue() )
        } else {
          this.refreshNotificationsForSupervisor( childrenUsers ).then(notifications => resolve( notifications ), error => resolve( [] ) );
        }

      });
    }

  private refreshNotificationsData(): Promise<any> {

        return new Promise((resolve, reject) => {

            this._apiService.get('/notify').subscribe(data => {

                if(data && data.length > 0) {
                    const notificationsArr = data.map( x => new HeartNotification(x));
                    if( JSON.stringify(this.notificationSubject.getValue()) !== JSON.stringify(notificationsArr) ) {
                        this.notificationSubject.next( notificationsArr.sort(this._helperService.sortHeartNotificationByDate) );
                        resolve( notificationsArr.sort(this._helperService.sortHeartNotificationByDate) );
                    } else {
                        reject( 'No new values' );
                    }

                } else {
                    reject( 'No values' );
                }

            }, error => {
                console.log("GET /notify error", error);
                reject( error );
            });

        });
    }


  private refreshNotificationsForSupervisor( childrenUsers: User[] ): Promise<HeartNotification[]> {

    const scheduleArr: Promise<HeartNotification[]> = new Promise((resolve) => {

      let notificationsArr: HeartNotification[] = (this.supervisorNotificationsSubject.getValue()) ? this.supervisorNotificationsSubject.getValue() : [];
      let counter = 0;

      for(const childrenUser of childrenUsers) {

        this._apiService.get(`/notify/connected/${childrenUser.userId}`).subscribe(data => {

          ++counter;

          if(data && data.length > 0) {

              const notificationsChildArr: HeartNotification[] = data.map(x => new HeartNotification(x));

              for(const notification of notificationsChildArr) {
                // console.log('healthSchedule', healthSchedule);
                const foundIndex = this._helperService.isInArray(notification, this.supervisorNotificationsSubject.getValue());
                if( foundIndex ) {
                  notificationsArr.splice(foundIndex, 1);
                }
                notificationsArr.push(notification);
              }
          }

          if(counter === childrenUsers.length){
            resolve( notificationsArr );
          }

        }, error => {
          console.log("GET /schedule error", error);
          resolve( notificationsArr );
        });

      }

    });

    return scheduleArr.then(x => {
      this.supervisorNotificationsSubject.next( x );
      return x;
    });
  }

}
