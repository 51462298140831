import { AfterViewInit, Component, OnInit } from '@angular/core';
import { User } from '../../shared/models';
import { Router } from '@angular/router';
import { ApiService, HelperService, OauthService, UserService } from '../../shared/services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, AfterViewInit {

    user = new User(null);

    constructor(
        private _router: Router,
        private _apiService: ApiService,
        private _userService: UserService,
        private _helperService: HelperService,
        private _oauthService: OauthService) {


    }

    ngOnInit() {

        setTimeout(() => {
            this._userService.getUser().then(data => {
                this.user = data;

                console.log('loginUser', this.user);
                /*
                console.log("loginUser", this.user);
                console.log("isValidPatient", this.user.isValidPatient());
                console.log("isMissingPatientValues", this.user.isMissingPatientValues());

                if(this.user.isValidPatient() || this.user.isMissingPatientValues() ){
                    this._router.navigateByUrl('/dashboard');
                }
                */

            });
        });


        // this._userService.tryGetUser();


    }

    ngAfterViewInit() {

        /*
        setTimeout(() => {

            this._userService.authedUser.subscribe(data => {
                this.user = new User(data);

                console.log("1isNotAuthed", this.user.isNotAuthed());
                console.log("1isPendingAuth", this.user.isPendingAuth());


                //if (this.user.isNotAuthed()) this._helperService.hideFullScreenLoading();

                /*
                console.log("loginUser", this.user);
                console.log("isValidPatient", this.user.isValidPatient());
                console.log("isMissingPatientValues", this.user.isMissingPatientValues());

                if(this.user.isValidPatient() || this.user.isMissingPatientValues() ){
                    this._router.navigateByUrl('/dashboard');
                }
                **

            });
        });
        */

    }


    socialSignIn() {
        this._helperService.showFullScreenLoding();
        this._oauthService.loginRedirect();
    }
}
