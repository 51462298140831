import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {HandleUsersComponent} from "./handle-users.component";
import {AuthSupervisorGuard} from "../../shared/guards";

const handleUsersRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'handle-users',
        component: HandleUsersComponent,
        canActivate: [AuthSupervisorGuard]
    }
]);

@NgModule({
    declarations: [
        HandleUsersComponent,
    ],
    imports: [
        SharedModule,
        handleUsersRouting,
    ],
})

export class HandleUsersModule {}
