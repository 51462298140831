import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { AccessToken } from '../models';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  saveToken(token: AccessToken): Promise<AccessToken> {
    // console.log('savedToken', token);
    return new Promise((resolve) => {
      localStorage.setItem('tokenObject', JSON.stringify(token));
      resolve( token );
    });

  }

  deleteToken() {
    // console.log('token DELETED from STORE');
    localStorage.removeItem('tokenObject');
  }

  getToken(): Promise<AccessToken> {

    return new Promise((resolve) => {

      const storedToken: string = localStorage.getItem('tokenObject');

      if ( !storedToken ) {
        resolve(null);
      }
      resolve( new AccessToken(JSON.parse(storedToken)) );
    });

  }
}
