import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {InfoComponent} from "./info.component";
import {SharedModule} from "../../shared";
import {AuthGuard, AuthLoggedInGuard} from '../../shared/guards';

const infoRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'info',
        component: InfoComponent,
        canActivate: [AuthLoggedInGuard]
    }
]);

@NgModule({
    declarations: [
        InfoComponent,
    ],
    imports: [
        SharedModule,
      infoRouting,
    ],
})

export class InfoModule {}
