import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {PatientSummariesComponent} from "./patient-summaries.component";
import {AuthSupervisorGuard} from "../../shared/guards";
import { PlotlyModule } from 'angular-plotly.js';

const summariesRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'patient-summaries',
        component: PatientSummariesComponent,
        canActivate: [AuthSupervisorGuard]
    }
]);

@NgModule({
    declarations: [
        PatientSummariesComponent,
    ],
    imports: [
        SharedModule,
        summariesRouting
    ],
})

export class PatientSummariesModule {}
