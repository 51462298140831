import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs/Rx";
import {
  ChartData,
  ChartDataHolder,
  ChartDataSummary,
  HealthDailySchedule,
  HealthSchedule,
  HealthScheduleAction,
  MeasureSummary,
  User
} from '../models';
import {HttpParams} from "@angular/common/http";
import {ApiService} from "./api.service";
import {DatePipe} from "@angular/common";
import {HelperService} from "./helper.service";

interface HealthActivity {
  day: string,
  time: string,
  activities: HealthScheduleAction[],
  hide: boolean
}

@Injectable({
    providedIn: 'root'
})
export class ScheduleService {

    private scheduleSubject: BehaviorSubject<HealthSchedule> = new BehaviorSubject<HealthSchedule>( null );
    private schedule: Observable<HealthSchedule> = this.scheduleSubject.asObservable();

    private supervisorScheduleSubject: BehaviorSubject<HealthSchedule[]> = new BehaviorSubject<HealthSchedule[]>( null );
    private supervisorSchedule: Observable<HealthSchedule[]> = this.supervisorScheduleSubject.asObservable();

    constructor (
        private _apiService: ApiService,
        private _helperService: HelperService
    ) {}

    getSchedule( isUpdate = false ): Promise<HealthSchedule> {

      return new Promise((resolve) => {

        if( this.scheduleSubject.getValue() && !isUpdate ) {
          resolve( this.scheduleSubject.getValue() )
        } else {
          this.refreshSchemaData().then(healthSchedule => resolve( healthSchedule ), error => resolve( new HealthSchedule() ) );
        }

      });

    }

    getSupervisorSchedule(childrenUsers: User[], isUpdate = false ): Promise<HealthSchedule[]> {

      return new Promise((resolve) => {

        if( this.supervisorScheduleSubject.getValue() && !isUpdate ) {
          resolve( this.supervisorScheduleSubject.getValue() )
        } else {
          this.refreshScheduleForSupervisor( childrenUsers ).then(healthScheduleArr => resolve( healthScheduleArr ), error => resolve( [] ) );
        }

      });
    }

  private refreshSchemaData(): Promise<any> {

        return new Promise((resolve, reject) => {

            this._apiService.get('/me/schedule').subscribe(data => {

                if(data) {

                    const healthSchedule = new HealthSchedule(data);
                    if( JSON.stringify(this.scheduleSubject.getValue()) !== JSON.stringify(healthSchedule) ) {
                        this.scheduleSubject.next( healthSchedule );
                        resolve( healthSchedule );
                    } else {
                        reject( 'No new values' );
                    }

                } else {
                    reject( 'No values' );
                }

            }, error => {
                console.log("GET /schedule error", error);
                reject( error );
            });

        });
    }


  private refreshScheduleForSupervisor( childrenUsers: User[] ): Promise<HealthSchedule[]> {

    const scheduleArr: Promise<HealthSchedule[]> = new Promise((resolve) => {

      const healthScheduleArr: HealthSchedule[] = (this.supervisorScheduleSubject.getValue()) ? this.supervisorScheduleSubject.getValue() : [];
      let counter = 0;

      for(const childrenUser of childrenUsers) {

        this._apiService.get(`/administration/${childrenUser.userId}/schedule`).subscribe(data => {

          ++counter;

          // console.log('childrenUser', childrenUser);

          if(data) {

              let tempData = data;
              tempData['user'] = childrenUser;
              const healthSchedule = new HealthSchedule(tempData);

              // console.log('healthSchedule', healthSchedule);
              const foundIndex = this._helperService.isInArray(healthSchedule, this.supervisorScheduleSubject.getValue());

              if( foundIndex ) {
                healthScheduleArr.splice(foundIndex, 1);
              }

              healthScheduleArr.push(healthSchedule );

            }

            if(counter === childrenUsers.length){
              resolve( healthScheduleArr );
            }

        }, error => {
          console.log("GET /schedule error", error);
          resolve( healthScheduleArr );
        });

      }

    });

    return scheduleArr.then(x => {
      this.supervisorScheduleSubject.next( x );
      return x;
    });
  }



  getHealthDailySchedule( healthSchedule ) {

    const healthActivityArr: HealthActivity[] = [];

    // step 1. set the days
    for(const paramDay in healthSchedule) {
      let thisDaily = healthSchedule[paramDay];
      if (thisDaily instanceof HealthDailySchedule) {
        healthActivityArr.push({
          day: paramDay,
          time: null,
          activities: [],
          hide: false
        });
      }
    }

    // step 2. Add all activities based on day
    for(const healthActivity of healthActivityArr) {
      for(const paramDay in healthSchedule) {
        let thisDaily = healthSchedule[paramDay];
        if (thisDaily instanceof HealthDailySchedule && paramDay === healthActivity.day) {
          healthActivity.activities = healthActivity.activities.concat(thisDaily.measurements).concat(thisDaily.medicines).concat(thisDaily.other);
        }
      }
    }


    // step 3. group activities by whole hours
    const groupedHealthActivityArr: HealthActivity[] = [];
    for(const healthActivity of healthActivityArr) {
      for (const healthItem of healthActivity.activities) {
        let isItemInSameHour = groupedHealthActivityArr.find( x => {
          if(x.time && healthItem.completeByTime){
            return x.time.split(':')[0] === healthItem.completeByTime.split(':')[0] && x.day === healthActivity.day; // grouped by hour
          }
          return false;
        });

        if( isItemInSameHour ) {
         isItemInSameHour.activities.push(healthItem);
        } else {
          groupedHealthActivityArr.push({
            day: healthActivity.day,
            time: healthItem.completeByTime.split(':')[0] +':00',
            activities: [healthItem],
            hide: false
          });
        }
      }
    }

    return groupedHealthActivityArr;

  }

}
