export class MeasureSummaryValue {
    averageValue: string;
    calendarDate: string;
    endTimestamp: string;
    localOffsetInSeconds: number;
    maxValue: number;
    minValue: number;
    origin: string;

    constructor(chartObj: any = null) {
        this.averageValue = (chartObj && chartObj['averageValue']) ? chartObj.averageValue : '';
        this.calendarDate = (chartObj && chartObj['calendarDate']) ? chartObj.calendarDate : '';
        this.endTimestamp = (chartObj && chartObj['endTimestamp']) ? chartObj.endTimestamp : '';
        this.localOffsetInSeconds = (chartObj && chartObj['localOffsetInSeconds']) ? chartObj.localOffsetInSeconds : 0;
        this.maxValue = (chartObj && chartObj['maxValue']) ? chartObj.maxValue : 0;
        this.minValue = (chartObj && chartObj['minValue']) ? chartObj.minValue : 0;
        this.origin = (chartObj && chartObj['origin']) ? chartObj.origin : '';

    }

}
