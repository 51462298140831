import { User } from './user.model';
import { ChartDataSummary } from './chart-data-summary.model';
import { ChartData } from './chart-data.model';
import { ChartDataGroup } from './chart-data-group.model';
import { DetailedChartData } from './detailed-chart-data.model';
import * as moment from 'moment';
import {TranslatePipe} from 'heart-app-shared-pipes';

export class ChartDataHolder {

    user: User;
    chartData: ChartDataSummary[];
    mappedData: ChartData[];
    average: { name: string, avg: number, min: number, max: number, measures: number, sum: number }[];

    constructor(dataObj: any = null) {
        this.user = (dataObj && dataObj['user']) ? new User(dataObj.user) : new User();
        this.chartData = (dataObj && dataObj['ChartData']) ? dataObj.ChartData.map(x => new ChartDataSummary(x)) : [];
        this.mappedData = [];
        this.average = [];

        this.mapChartData(this.chartData);
        this.mapMeasuresData(this.chartData);
        this.setAverages(this.mappedData);

    }

    hasData() {
        return this.mappedData.length > 0;
    }


    averagesToHTMLString(): string {

      let averagesString = '<div class="averages-string">';

      const translatePipe = new TranslatePipe();

      for(const avr of this.average){

        if(avr.avg !== 0) {

          averagesString += '<div class="item">';
          averagesString += '<i class="ti-check"></i><span class="item-header">';
          averagesString += translatePipe.transform(avr.name);
          averagesString += '</span>';
          averagesString += '<span class="item-content">';
          averagesString += 'Min: ' + (Math.round(+avr.min * 100) / 100) + ' | ';
          averagesString += 'Max: ' + (Math.round(+avr.max * 100) / 100) + ' | ';
          averagesString += 'Avg: ' + (Math.round(+avr.avg * 100) / 100);
          averagesString += '</span>';
          averagesString += '</div>';

        }

      }
      averagesString += '</div>';

      return averagesString;

    }


    // TODO: determine from backend response...
    private isDrilldownProperty(property: string) {
        const drilldownProperties = [
            'nyhaClassificatonMeasures',
            'weightKgMeasures',
            'fatMassWeightKgMeasures',
            'boneMassMeasures',
            'hydrationMeasures',
            'fatRatioMeasures',
            'muscleMassMeasures',
            'fatFreeMassKgMeasures',
            'pulseWaveVelocityMeasures'
        ];
        return drilldownProperties.indexOf(property) > -1;
    }

    private isForbiddenProperty(property: string) {

        const forbiddenArray = [
            'calendarDateAsDate',
            'calendarDate',
            'pulseOxHasBlobDetails',
            'stepsHasBlobDetails',
            'pulseBpmHasBlobDetails',
            'avaliableDetails',
            'activeKiloCaloriesHasDetails',
            'metHasDetails',
            'motionIntensityHasBlobDetails'
        ];
        return forbiddenArray.indexOf(property) > -1;

    }

    isValidProperty( property: string ): boolean {
      return !this.isForbiddenProperty( property ) && !this.isDrilldownProperty( property );
    }

    setByDetailedChartData(detailedChartDataValues: DetailedChartData, newUserId: string) {
        this.user = new User({ userId: newUserId });
        this.mapDetailedChartData(detailedChartDataValues);
        this.setAverages(this.mappedData);
        return this;

    }


    private mapDetailedChartData(detailedChartDataValues: DetailedChartData, dataGroup: ChartDataGroup = ChartDataGroup.Default): void {

        for (const chartData of detailedChartDataValues.values) {
            // if chart key already in array then append value, else append to mappedData
            const cd = this.mappedData.find(x => x.type === detailedChartDataValues.typeOfMeasurement);
            if (cd) {
                cd.appendXY(chartData.trueDate, chartData.value);
                cd.appendXY(chartData.trueDate, chartData.valueAvg, ChartDataGroup.Avg);
                cd.appendXY(chartData.trueDate, chartData.valueMax, ChartDataGroup.Max);
                cd.appendXY(chartData.trueDate, chartData.valueMin, ChartDataGroup.Min);
            } else {

                const newChart = new ChartData(null, dataGroup)
                    .setType(detailedChartDataValues.typeOfMeasurement);

                newChart.appendXY(chartData.trueDate, chartData.value);
                newChart.appendXY(chartData.trueDate, chartData.valueAvg, ChartDataGroup.Avg);
                newChart.appendXY(chartData.trueDate, chartData.valueMax, ChartDataGroup.Max);
                newChart.appendXY(chartData.trueDate, chartData.valueMin, ChartDataGroup.Min);

                this.mappedData.push(newChart);
            }
        }

    }

    private mapMeasuresData(chartDataArr: ChartDataSummary[]) {

        for (const chartData of chartDataArr) {

            for (const chartProperty in chartData) {

                if (this.isDrilldownProperty(chartProperty)) {

                    const chartValue = chartData[chartProperty];

                    if (chartValue) {
                        // if chart key already in array then append value, else append to mappedData

                        // const parsedNyha = JSON.parse(chartValue);

                        // tslint:disable-next-line: forin
                        for (const tSec in chartValue['Values']) {

                            // if value is an object, look for parameter 'v'
                            const tSecValue = (typeof chartValue['Values'][tSec] === 'object')
                                ? chartValue['Values'][tSec]['v']
                                : chartValue['Values'][tSec];

                            const momentInTime = moment(chartData.calendarDate);
                            const trueTDate = momentInTime.add(tSec, 'seconds').toDate();

                            this.mappedData = this.pushChartPropertyData(this.mappedData, chartProperty, trueTDate, tSecValue);

                            // TODO: remove later, used for backwards compat
                            // if (chartProperty === 'nyhaClassificatonMeasures') {
                            //     this.nyhaData = this.pushChartPropertyData(this.nyhaData, chartProperty, trueTDate, tSecValue);
                            // } else if (chartProperty === 'weightKgMeasures') {
                            //     this.weightData = this.pushChartPropertyData(this.weightData, chartProperty, trueTDate, tSecValue);
                            // }
                        }
                    }
                }
            }
        }

    }


    private pushChartPropertyData(chartData: ChartData[], chartProperty: string, trueTDate: Date, tSecValue: any, dataGroup: ChartDataGroup = ChartDataGroup.Default) {
        const cd = chartData.find(x => x.type === chartProperty);
        if (cd) {
            cd.appendXY(trueTDate, tSecValue);
        } else {
            chartData.push(new ChartData(null, dataGroup).appendXY(trueTDate, tSecValue).setType(chartProperty));
        }
        return chartData;
    }

    private mapChartData(chartDataArr: ChartDataSummary[], dataGroup: ChartDataGroup = ChartDataGroup.Default): void {

        for (const chartData of chartDataArr) {

            // tslint:disable-next-line: forin
            for (const chartProperty in chartData) {

                const chartValue = chartData[chartProperty];

                if (chartValue && !this.isForbiddenProperty(chartProperty) && !this.isDrilldownProperty(chartProperty)) {
                    // if chart key already in array then append value, else append to mappedData
                    const cd = this.mappedData.find(x => x.type === chartProperty);
                    if (cd) {
                        cd.appendXY(chartData.calendarDateAsDate, chartValue);
                    } else {
                        this.mappedData.push(new ChartData(null, dataGroup)
                            .appendXY(chartData.calendarDateAsDate, chartValue)
                            .setType(chartProperty));
                    }
                }
            }
        }
    }

    private setAverages(chartDataArr: ChartData[]) {

        for (const chartData of chartDataArr) {

            let sum = 0;
            let avg = 0;
            let min = 0;
            let max = 0;


            // dividing by 0 will return Infinity
            // arr must contain at least 1 element to use reduce
            if (chartData.getChartData().y.length) {
              sum = chartData.getChartData().y.reduce((a, b) => a + b);
              avg = sum / chartData.getChartData().y.length;
              min = Math.min.apply(null, chartData.getChartData().y);
              max = Math.max.apply(null, chartData.getChartData().y);
            }

            const obj = {
              name: chartData.type,
              avg: avg,
              measures: chartData.getChartData().y.length,
              sum: sum,
              min: min,
              max: max
            };

            this.average.push(obj);
        }

    }

}
