import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {AuthCallbackComponent} from "./auth-callback.component";

const authCallbackRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'auth-callback',
        component: AuthCallbackComponent,
    }
]);

@NgModule({
    declarations: [
        AuthCallbackComponent,
    ],
    imports: [
        SharedModule,
        authCallbackRouting,
    ],
})

export class AuthCallbackModule {}
