import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {ConsentPatientComponent} from "./consent-patient.component";
import {AuthGuard, AuthLoggedInGuard, AuthRegistrationGuard} from '../../shared/guards';

const updateDevicesRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'consent-patient',
        component: ConsentPatientComponent,
        canActivate: [AuthLoggedInGuard]
    }
]);

@NgModule({
    declarations: [
        ConsentPatientComponent,
    ],
    imports: [
        SharedModule,
        updateDevicesRouting,
    ],
})

export class ConsentPatientModule {}
