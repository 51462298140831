import {Injectable, OnInit} from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad, Route,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import 'rxjs/add/operator/take';
import {User} from "../models";
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { Location, PlatformLocation } from "@angular/common";
import {ApiService, StorageService, UserService} from "../services";



@Injectable({
    providedIn: 'root',
})
export class AuthSupervisorGuard implements CanActivate{

  user: User = new User(null);

    constructor(
        private _userService: UserService,
        private _apiService: ApiService,
        private _router: Router,
        private _storageService: StorageService
    ) {}


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<boolean> {
        return this._storageService.getToken().then(token => {

          if (token) {

            return this._userService.getUser().then( user => {

              if (token && user.isSupervisor()) {
                return true;
              } else if(token && user.isPatient()) {
                this._router.navigateByUrl('/dashboard');
                return false;
              } else {
                return this.notAuthed();
              }

            });

          } else {
            return this.notAuthed();
          }


        });
    }


  private notAuthed(): boolean {
    this._router.navigateByUrl('/');
    return false;
  }

}
