declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    showOn: string[]; // enum[]: all, patient, doctor, manual-patient
}

export const ROUTES: RouteInfo[] = [

    { path: '/dashboard', title: 'My data',  icon: 'ti-panel', class: '', showOn: ['patient'] },
    { path: '/dashboard-supervisor', title: 'Supervision',  icon: 'ti-dashboard', class: '', showOn: ['doctor'] },
    { path: '/detailed-chart', title: 'Detailed Chart',  icon: 'fas fa-chart-area', class: '', showOn: ['doctor', 'patient'] },
    { path: '/user-profile', title: 'Settings',  icon: 'fas fa-chart-area', class: '', showOn: ['none']/*['patient', 'doctor', 'manual-patient']*/ },
    { path: '/table-list', title: 'Table List',  icon: 'fas fa-chart-area', class: '', showOn: ['none']/*['patient']*/ },
    { path: '/typography', title: 'Typography',  icon: 'fas fa-chart-area', class: '', showOn: ['none']/*['patient', 'doctor']*/ },
    { path: '/icons', title: 'Icons',  icon: 'fas fa-chart-area', class: '', showOn: ['none']/*['patient']*/ },
    { path: '/maps', title: 'Maps',  icon: 'fas fa-chart-area', class: '', showOn: ['none']/*['patient']*/ },
    { path: '/notifications', title: 'Notifications',  icon: 'fas fa-chart-area', class: '', showOn: ['none']/*['doctor']'*/ },
    { path: '/handle-users', title: 'Handle patients',  icon: 'ti-magnet', class: '', showOn: ['superuser'] },
    { path: '/data-extract', title: 'Extract data',  icon: 'ti-download', class: '', showOn: ['researcher'] },
    { path: '/add-manual-data', title: 'Add Manual Data',  icon: 'fas fa-chart-area', class: '', showOn: ['none']/*['patient', 'manual-patient']*/ },
    { path: '/manual-data', title: 'Manual Data',  icon: 'ti-pencil', class: '', showOn: ['patient', 'manual-patient'] },
    { path: '/registration', title: 'Registration',  icon: 'ti-comments-smiley', class: '', showOn: ['none'] },
    { path: '/detailed-chart-day', title: 'Detailed Chart per day',  icon: 'ti-comments-smiley', class: '', showOn: ['none'] },
    { path: '/schedule', title: 'Schedule',  icon: 'ti-layout-list-thumb', class: '', showOn: ['patient'] },
    { path: '/handle-schedule', title: 'Handle schedule',  icon: 'ti-layout-list-thumb-alt', class: '', showOn: ['doctor'] },
    { path: '/consent-patient', title: 'Consent',  icon: 'ti-flag-alt-2', class: '', showOn: ['none'] },
    { path: '/support', title: 'Support',  icon: 'ti-comments-smiley', class: '', showOn: ['patient', 'no-roll'] },
    { path: '/info', title: 'Info',  icon: 'ti-info', class: '', showOn: ['unassigned'] },
    { path: '/chatbot', title: 'Chatbot',  icon: 'ti-comments', class: '', showOn: ['doctor', 'patient'] }


];
