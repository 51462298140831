import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {ManualDataComponent} from "./manual-data.component";
import {SharedModule} from "../../shared";
import {AuthGuard} from "../../shared/guards";

const manualDataRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'manual-data',
        component: ManualDataComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
        ManualDataComponent,
    ],
    imports: [
        SharedModule,
        manualDataRouting,
    ],
})

export class ManualDataModule {}
