import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {DashboardSupervisorComponent} from "./dashboard-supervisor.component";
import {AuthSupervisorGuard} from "../../shared/guards";

const dashboardRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'dashboard-supervisor',
        component: DashboardSupervisorComponent,
        canActivate: [AuthSupervisorGuard]
    }
]);

@NgModule({
    declarations: [
        DashboardSupervisorComponent,
    ],
    imports: [
        SharedModule,
        dashboardRouting,
    ],
})

export class DashboardSupervisorModule {}
