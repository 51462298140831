import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { StorageService, UserService } from '../services';
import { AccessToken } from '../models';

// TODO: Remove when MSAL library supports Angular 6, and use MsalInterceptor instead

@Injectable()
export class HttpInterceptorTmp implements HttpInterceptor {

    constructor(private _storageService: StorageService,
        private _userService: UserService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const scopes = this.auth.getScopesForEndpoint(environment.api_url);

        // this.auth.verbose('Url: ' + req.url + ' maps to scopes: ' + scopes);

        // console.log("req.url", req.url);
        // console.log("scopes", scopes);

        /*
        if (scopes === null) {
            return next.handle(req);
        }
        */

        // console.log("req", req);

        return from(this._storageService.getToken())
            .pipe(
                switchMap(tokenRaw => {

                    // console.log('token intercepted (tokenRaw)', tokenRaw);

                    const token: AccessToken = tokenRaw;

                    if (token) {
                        const headers = request.headers
                            .set('Authorization', 'Bearer ' + token.access_token);
                        // .append('Content-Type', 'application/json');
                        const requestClone = request.clone({
                            headers
                        });

                        return next.handle(requestClone).pipe(
                            catchError(error => {
                                if (error.status === 401 || error.status === 403 /*|| error.status === 500*/) {
                                    //this._userService.logOut();
                                    this._userService.destroySessionUser();
                                }
                                return throwError(error);
                            })
                        );

                    } else {
                        return next.handle(request).map(event => {

                            // if (event instanceof HttpResponse || event instanceof HttpErrorResponse) {
                            // console.log('ressponse2', event);
                            // }

                            return event;
                        });
                    }

                })
            );



        /*
        .switchMap(token => {
            const headers = req.headers
                .set('Authorization', 'Bearer ' + token.accessToken);
                //.append('Content-Type', 'application/json');
            const reqClone = req.clone({
                headers
            });
            return next.handle(reqClone);
        });
        */

        /*
        const tokenStored = this._storageService.getToken();
        //console.log("tokenStored", tokenStored);


        return tokenStored.then(tokenObj => {

            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${tokenObj.accessToken}`,
                }
            });

            //console.log("req1", req);

            return next.handle(req);

        },error => {

            console.log("errorInterceptor", error);

            return next.handle(req);
        });

        /*
        if(req.headers.get("customAuthorization")) {

            req = req.clone({
                setHeaders: {
                    Authorization: req.headers.get("customAuthorization"),
                }
            });

            //console.log("req1", req);

            return next.handle(req);

        } else if (tokenStored && tokenStored.token) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${tokenStored.token}`,
                }
            });

            return next.handle(req).do(event => { }, err => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    const scopesl = this.auth.getScopesForEndpoint(req.url);
                    const tokenStoredl = this.auth.getCachedTokenInternal(scopesl);
                    if (tokenStoredl && tokenStoredl.token) {
                        this.auth.clearCacheForScope(tokenStoredl.token);
                    }
                    this.broadcastService.broadcast('msal:notAuthorized', { err, scopesl });
                }
            });
        } else {
            return from(this.auth.acquireTokenSilent(/*scopes*environment.static_scopes).then(token => {
                const JWT = `Bearer ${token}`;

                //console.log("token", token);

                return req.clone({
                    setHeaders: {
                        Authorization: JWT,
                    },
                });

            })).mergeMap(reql => next.handle(reql).do(event => { }, err => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    const scopesl = this.auth.getScopesForEndpoint(reql.url);
                    const tokenStoredl = this.auth.getCachedTokenInternal(scopesl);
                    if (tokenStoredl && tokenStoredl.token) {
                        this.auth.clearCacheForScope(tokenStoredl.token);
                    }
                    this.broadcastService.broadcast('msal:notAuthorized', { err, scopesl });
                }
            })); // calling next.handle means we are passing control to next interceptor in chain
        }
        */
    }
}
