import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  DateAdapter, MAT_DATE_FORMATS,
  MatButtonModule, MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule, MatNativeDateModule,
  MatRippleModule,
  MatSelectModule,
  MatTooltipModule,
  MatRadioModule,
  MatStepperModule, MatCheckboxModule, MatTableModule
} from '@angular/material';

import {
  ApiService,
  ChartDataService,
  ChildrenService,
  HelperService,
  NotificationService,
  OauthService,
  ScheduleService,
  StorageService,
  UserService,
  ChatbotService
} from './services';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from "ngx-loading";
import { ModalComponent, ModalService } from "./modal";
import { AuthGuard, AuthLoggedInGuard, AuthRegistrationGuard, AuthSupervisorGuard, NotShowWhenAuthedGuard } from "./guards";
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HeartAppSharedPipesModule } from 'heart-app-shared-pipes';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {PlotlyModule} from 'angular-plotly.js';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatSelectModule,
    MatRadioModule,
    HeartAppSharedPipesModule,
    ModalComponent,
    NgxLoadingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatCheckboxModule,
    MatTableModule,
    ScrollingModule,
    PlotlyModule
  ],
  providers: [
    ApiService,
    StorageService,
    OauthService,
    AuthGuard,
    AuthRegistrationGuard,
    AuthSupervisorGuard,
    NotShowWhenAuthedGuard,
    AuthLoggedInGuard,
    UserService,
    HelperService,
    ModalService,
    ChartDataService,
    ChildrenService,
    DatePipe,
    MatDatepickerModule,
    ScheduleService,
    NotificationService,
    ChatbotService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ],
  declarations: [
    ModalComponent
  ]
})

export class SharedModule { }
