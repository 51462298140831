import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {ChatbotSingleComponent} from "./chatbot-single.component";
import {AuthGuard} from "../../shared/guards";
import { PlotlyModule } from 'angular-plotly.js';

const chatbotSingleRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'chatbot-single',
        component: ChatbotSingleComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
      ChatbotSingleComponent,
    ],
    imports: [
      SharedModule,
      chatbotSingleRouting
    ],
})

export class ChatbotSingleModule {}
