import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  nowDate: Date = new Date();

  constructor() { }

  ngOnInit() {
  }

  getPathName() {
    const title = window.location.pathname;
    return title.substring(1);
  }

}
