import {User} from './user.model';

export class ChatbotSummary {
    chatId: string;
    firstTimeOfRegistration: string;
    firstTimeOfRegistrationAsDate: Date;
    chatAnswerCount: number;
    latestQuestion: string;
    latestAnswer: string;
    latestResult: string;
    user: User; //patient user id
    isValid: boolean = true; // is a user have no chats yet, then it is a placeholder

  constructor(chatbotObj: any = null, user: User = null) {
    this.chatId = (chatbotObj && chatbotObj['chatId']) ? chatbotObj.chatId : '';
    this.firstTimeOfRegistration = (chatbotObj && chatbotObj['firstTimeOfRegistration']) ? chatbotObj.firstTimeOfRegistration : '';
    this.firstTimeOfRegistrationAsDate = (chatbotObj && chatbotObj['firstTimeOfRegistration']) ? new Date(chatbotObj.firstTimeOfRegistration) : new Date();
    this.chatAnswerCount = (chatbotObj && chatbotObj['chatAnswerCount']) ? +chatbotObj.chatAnswerCount : +0;
    this.latestQuestion = (chatbotObj && chatbotObj['latestQuestion']) ? chatbotObj.latestQuestion : '';
    this.latestAnswer = (chatbotObj && chatbotObj['latestAnswer']) ? chatbotObj.latestAnswer : '';
    this.latestResult = (chatbotObj && chatbotObj['latestResult']) ? chatbotObj.latestResult : '';
    this.user = user;
  }


  setHaveNoChat() {
    this.isValid = false;
    return this;
  }


}
