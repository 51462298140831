export class DetailedChartDataValue {
  time: string;
  trueDate: Date;
  value: number;
  valueMax: number;
  valueAvg: number;
  valueMin: number;
  offsetSeconds: number;

  constructor(chartObj: any = null, originDate: Date) {
    this.time = (chartObj && chartObj['T']) ? chartObj.T : '';
    this.value = (chartObj && chartObj['V']) ? +chartObj.V : null;
    this.valueMax = (chartObj && chartObj['MAX']) ? +chartObj.MAX : null;
    this.valueMin = (chartObj && chartObj['MIN']) ? +chartObj.MIN : null;
    this.valueAvg = (chartObj && chartObj['AVG']) ? +chartObj.AVG : null;
    this.offsetSeconds = (chartObj && chartObj['S']) ? +chartObj.S : +0;
    this.trueDate = this.setTrueDate(originDate, this.offsetSeconds);

  }

  setTrueDate(originDate: Date, offsetInSeconds: number): Date {
    let tempDateSeconds = Math.floor(((originDate.getTime() / 1000) + offsetInSeconds));

    const trueTDate = new Date(1970, 0, 1);
    trueTDate.setSeconds(tempDateSeconds);
    return trueTDate;
  }

}
