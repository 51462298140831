import { Injectable, OnInit } from '@angular/core';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { ApiService } from './api.service';
import { HealthSchedule, User } from '../models';
import { environment } from '../../../environments/environment';
import { HelperService } from './helper.service';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { OauthService } from './oauth.service';

@Injectable({
    providedIn: 'root'
})
export class UserService implements OnInit {

    roleToDisplay: string = 'default';

    private userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    private user: Observable<User> = this.userSubject.asObservable();

    constructor(
        private _apiService: ApiService,
        private _router: Router,
        private _helperService: HelperService,
        private _storageService: StorageService,
        private _oauthService: OauthService
    ) { }

    ngOnInit(): void {

    }


    listenUser(): Observable<User> {
        return this.user;
    }


    getUser(isUpdateUser = false): Promise<User> {
        return new Promise((resolve) => {

            if (this.userSubject.getValue() && !isUpdateUser) {
                resolve(this.userSubject.getValue());
            } else {
                this.tryGetUser().then(user => resolve(user), error => resolve(new User()));
            }

        });
    }

    private tryGetUser(): Promise<any> {

        this._helperService.showFullScreenLoding();

        return new Promise((resolve, reject) => {

            this._apiService.get('/me').subscribe(
                res => {
                    // res['fullName'] = aadUser['name'];
                    const user = new User(res);
                    this._helperService.hideFullScreenLoading();
                    resolve(this.setUser(user));
                },
                error => {
                    // this.logOut();
                    this._helperService.hideFullScreenLoading();
                    reject(error);
                }
            );
        });
    }

    private setUser(user: User): User {

        if (this.roleToDisplay === 'patient') {
            user.makePatient();
        } else if (this.roleToDisplay === 'doctor') {
            user.makeDoctor();
        } else if (this.roleToDisplay === 'superuser') {
            user.makeSuperuser();
        } else if (this.roleToDisplay === 'researcher') {
            user.makeResearcher();
        } else if (this.roleToDisplay === 'unassigned') {
            user.makeUnassigned();
        }

        this.userSubject.next(user);

        return user;
    }

    logOut(): void {
        this.setUser(null);
        this._storageService.getToken().then(token => {

            if (token) {
                this._storageService.deleteToken();
                this._oauthService.logout();
            } else {
                this._oauthService.logout();
                // this._router.navigateByUrl('/'); //denna är troligen onödig
            }

        }, err => {
            this._oauthService.logout();
        });
    }

    destroySessionUser(){
      this.setUser(null);
      this._storageService.getToken().then(token => {

        if (token) {
          this._storageService.deleteToken();
          //this._oauthService.logout();
        }

      }, err => {

      });
    }

}
