import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import 'rxjs/add/operator/take';
import {User} from '../models';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import {StorageService} from '../services';



@Injectable({
    providedIn: 'root',
})
export class AuthLoggedInGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _storageService: StorageService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this._storageService.getToken().then(token => {

            if (token) {
                return true;
            } else {
                this._router.navigateByUrl('/');
                return false;
            }

        });

    }

}
