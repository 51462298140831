import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {NotificationsSingleComponent} from "./notifications-single.component";
import {AuthGuard} from "../../shared/guards";

const notificationsSingleRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'notifications-single',
        component: NotificationsSingleComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
        NotificationsSingleComponent,
    ],
    imports: [
        SharedModule,
        notificationsSingleRouting,
    ],
})

export class NotificationsSingleModule {}
