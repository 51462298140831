import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared";
import {TableListComponent} from "./table-list.component";
import {AuthGuard} from "../../shared/guards";

const tableListRouting: ModuleWithProviders = RouterModule.forChild([
    {
        path: 'table-list',
        component: TableListComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
    declarations: [
        TableListComponent,
    ],
    imports: [
        SharedModule,
        tableListRouting,
    ],
})

export class TableListModule {}
